import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import _ from "lodash";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Form,
  InputGroup,
  Nav,
  OverlayTrigger,
  Tab,
  Tabs,
  Tooltip,
} from "react-bootstrap";
import ReactDOMServer from "react-dom/server";

import { DateRangePicker } from 'rsuite';
import 'rsuite/DateRangePicker/styles/index.css';

import { AiOutlineFile } from "react-icons/ai";
import { BiSolidPrinter } from "react-icons/bi";
import { BsBagCheckFill, BsCameraVideoFill, BsGoogle } from "react-icons/bs";
import { FaCloudUploadAlt } from "react-icons/fa";
import { ImDownload2 } from "react-icons/im";
import {
  RiBuilding2Fill,
  RiDownload2Fill,
  RiFileListFill,
} from "react-icons/ri";
import { VscSend } from "react-icons/vsc";
import Loader from "react-js-loader";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { useParams } from "react-router-dom";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { solarizedlight } from "react-syntax-highlighter/dist/esm/styles/prism";
import {
  API_URL,
  BANDWIDTH_ISSUE_CANDIDATE_END,
  BANDWIDTH_ISSUE_INTERVIEWER_END,
  HARDWARE_ISSUE_CANDIDATE_END,
  HARDWARE_ISSUE_INTERVIEWER_END,
  INTERVIEW_FINAL_STATUS_REJECTED,
  INTERVIEW_FINAL_STATUS_SELECTED,
  NO_SHOW_BY_CANDIDATE,
  NO_SHOW_BY_INTERVIWER,
  PROXY,
  commonDateFormat,
  dayDifference,
  getAuthUserFromSessionStorage,
  isDateGreaterThanToday,
} from "../../common/constant";
import HeaderScreen from "../HeaderScreen/HeaderScreen";
import "./LiveInterviewerScreen.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const StarRating = ({ totalStars, filledStars, onRatingChange }) => {
  const handleClick = (starValue) => {
    // setRating(starValue);
    // onRatingChange(starValue);
    onRatingChange(starValue);
  };

  return (
    <div>
      {[...Array(totalStars)].map((_, index) => {
        const starValue = index + 1;
        return (
          <span
            className="cursor-pointer"
            key={index}
            style={{
              color: starValue <= filledStars ? "gold" : "grey",
              fontSize: "2em",
            }}
            onClick={() => handleClick(starValue)}
          >
            ★
          </span>
        );
      })}
    </div>
  );
};

const LiveInterviewerScreen = () => {
  const params = useParams();
  const [userDetails, setUserDetails] = useState(
    getAuthUserFromSessionStorage()
  );
  const [interviewStatus, setInterviewStatus] = useState("All");
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [jobDetails, setJobDetails] = useState({});
  const [interviewState, setInterviewState] = useState(null);
  const [interviewDetail, setInterviewDetail] = useState({});
  const fileInputRef = useRef(null);
  const [codeContent, setcodeContent] = useState("");
  const [questionContent, setQuestionContent] = useState("");
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    if (!_.isUndefined(params.id) && !_.isNull(params.id) && params.id !== "") {
      getInterviewDetails(params.id);
    }
  }, [params.id]);

  // useEffect(() => {
  //     if (!_.isUndefined(interviewDetail) && !_.isNull(interviewDetail)) {
  //         updateCandidateProfile("", false)
  //     }
  // }, [interviewDetail])

  const getInterviewDetails = (id) => {
    setIsLoading(true);
    fetch(`${API_URL}api/getInterview/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userDetails.token}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (
          !_.isUndefined(response) &&
          !_.isNull(response) &&
          !_.isUndefined(response.data) &&
          !_.isNull(response.data) &&
          !_.isEmpty(response.data)
        ) {
          setInterviewState(response.data);
          var primarySkillFeedback = response.data.primary_skill_feedback ?? [];
          for (var k = 0; k < primarySkillFeedback.length; k++) {
            if (primarySkillFeedback[k] === null) {
              primarySkillFeedback[k] = { rating: 0, review: "" };
            }
          }

          var secondarySkillFeedback =
            response.data.secondary_skill_feedback ?? [];
          for (var k = 0; k < secondarySkillFeedback.length; k++) {
            if (secondarySkillFeedback[k] === null) {
              secondarySkillFeedback[k] = { rating: 0, review: "" };
            }
          }

          setInterviewDetail({
            incomplete_interview: response.data.incomplete_interview,
            overall_rating: response.data.overall_rating,
            final_status: response.data.final_status,
            feedback_sheet: response.data.feedback_sheet,
            coding_questions: response.data.coding_questions,
            code_of_screenshot: response.data.code_of_screenshot ?? [],
            is_feedback_given: response.data.is_feedback_given,
            google_meet_id: response.data.google_meet_id,
            primary_skill_feedback: primarySkillFeedback,
            secondary_skill_feedback: secondarySkillFeedback,
            good_to_have_skill_feedback:
              response.data.good_to_have_skill_feedback ?? [],
            coding_skill_feedback: response.data.coding_skill_feedback ?? [],
            final_recommendation: response.data.final_recommendation ?? "",
            good_to_have_skill_feedback_rating:
              response.data.good_to_have_skill_feedback_rating ?? "",
            coding_skill_feedback_rating:
              response.data.coding_skill_feedback_rating ?? 0,
            professional_skill: response.data.professional_skill,
            interview_time_range: (response.data.interview_time_range && response.data.interview_time_range.length > 0 && response.data.interview_time_range[0] !== null) ? (
              [
                new Date(response.data.interview_time_range[0]),
                new Date(response.data.interview_time_range[1])
              ]
            ) : [null, null]
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err.message)
        setInterviewState({});
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const id = params.id;
      fetch(`${API_URL}api/getInterview/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userDetails.token}`,
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (
            !_.isUndefined(response) &&
            !_.isNull(response) &&
            !_.isUndefined(response.data) &&
            !_.isNull(response.data) &&
            !_.isEmpty(response.data)
          ) {
            if (interviewState) {
              if (
                interviewState.code_by_candidate === "" ||
                interviewState.code_by_candidate !==
                response.data.code_by_candidate
              ) {
                setInterviewState({
                  ...interviewState,
                  code_by_candidate: response.data.code_by_candidate,
                });
              }
            }
          }
        })
        .catch((err) => { });
    }, 5000);

    return () => clearInterval(interval);
  }, [interviewState]);

  // save on change

  const handleDownload = (fileUrl) => {
    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = new URL(fileUrl).pathname
          .split("/")
          .pop()
          .replaceAll("%20", " ");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.error("Error downloading PDF:", error));
  };

  const printPDF = (fileUrl) => {
    const newWindow = window.open("", "_blank");
    const pdfContent = ReactDOMServer.renderToString(
      <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            renderAnnotationLayer={false}
          />
        ))}
      </Document>
    );
    newWindow.document.write(
      `<html><head><title>Print</title></head><body>${pdfContent}</body></html>`
    );
    newWindow.document.close();
    newWindow.print();
  };

  const validateForm = () => { };

  const updateCandidateProfile = (message = "", isShowToast = true) => {
    if (isShowToast) {
      setIsLoading(true);
    }
    fetch(`${API_URL}api/updateCandidateProfileData/${params.id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${userDetails.token}`,
      },
      body: JSON.stringify(interviewDetail),
    })
      .then((response) => response.json())
      .then((response) => {
        if (isShowToast) {
          if (message == "") {
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: response.message,
              life: 3000,
            });
          } else {
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: message,
              life: 3000,
            });
          }
          getInterviewDetails(params.id);
        }
      })
      .catch((error) => {
        if (isShowToast) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          });
        }
      })
      .finally((e) => {
        if (isShowToast) {
          setIsLoading(false);
        }
      });
  };

  const handleFileChange = (event, name) => {
    const selectedFile = event.target.files[0];
    uploadFile(selectedFile, name);
  };

  const uploadFile = (selectedFile, name) => {
    const data = new FormData();
    data.append("file", selectedFile);
    fetch(`${API_URL}api/uploadFiles`, {
      method: "POST",
      body: data,
    })
      .then((response) => response.json())
      .then((response) => {
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        if (name === "code_of_screenshot") {
          const codeOfScreenshot = interviewDetail.code_of_screenshot ?? [];
          codeOfScreenshot.push(response.filepath);
          setInterviewDetail({
            ...interviewDetail,
            code_of_screenshot: codeOfScreenshot,
          });
        } else {
          setInterviewDetail({
            ...interviewDetail,
            [name]: response.filepath,
          });
        }
      })
      .catch((error) => {
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      });
  };

  const checkFeedbackGiven = () => {
    if (interviewDetail.incomplete_interview !== "") {
      return true;
    } else if (interviewDetail.feedback_sheet !== "") {
      if (isOverallRatingGiven() && finalStatusGiven()) {
        return true;
      }
      return false;
    } else {
      return isFormValuesValid();
    }
  };

  const [activeTabKey, setActiveTabKey] = useState("primarySkillFeedback");

  const finalStatusGiven = () => {
    if (
      interviewDetail &&
      (interviewDetail.final_status === "" ||
        interviewDetail.final_status === null)
    ) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail:
          "Please provide your final feedback to proceed with the submission.",
        life: 3000,
      });
      return false;
    }
    return true;
  }

  const isOverallRatingGiven = () => {
    if (interviewDetail && (interviewDetail.overall_rating === undefined || interviewDetail.overall_rating === null || interviewDetail.overall_rating === 0)) {
      let ratingErrorMessage = "Please provide a {{SKILL}} rating before proceeding";
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: ratingErrorMessage.replace("{{SKILL}}", "Overall Feedback skills"),
        life: 3000,
      });
      return false;
    }
    return true;
  }

  const isFormValuesValid = () => {
    const minLength = interviewDetail.final_status === INTERVIEW_FINAL_STATUS_SELECTED ? 250 : 100;
    let errorMessage = "{{SKILL}} feedback must be at least " + minLength + " characters long.";
    let ratingErrorMessage = "Please provide a {{SKILL}} rating before proceeding";

    for (
      let index = 0;
      index < interviewState.job.primary_skill.length;
      index++
    ) {
      if (index < interviewDetail.primary_skill_feedback.length) {
        if (
          interviewDetail.primary_skill_feedback[index] == undefined ||
          interviewDetail.primary_skill_feedback[index].review.replace(
            /<[^>]*>/g,
            ""
          ).length <= minLength
        ) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: errorMessage.replace("{{SKILL}}", "Primary skills"),
            life: 3000,
          });
          return false;
        }
      }
    }

    if (
      interviewState.job.secondary_skill.length !==
      interviewDetail.secondary_skill_feedback.length
    ) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessage.replace("{{SKILL}}", "Secondary skills"),
        life: 3000,
      });
      return false;
    }

    for (
      let index = 0;
      index < interviewState.job.secondary_skill.length;
      index++
    ) {
      if (index < interviewDetail.secondary_skill_feedback.length) {
        if (
          interviewDetail.secondary_skill_feedback[index] === undefined ||
          interviewDetail.secondary_skill_feedback[index].review.replace(
            /<[^>]*>/g,
            ""
          ).length <= minLength
        ) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: errorMessage.replace("{{SKILL}}", "Secondary skills"),
            life: 3000,
          });
          return false;
        }
      }
    }

    if (
      interviewDetail &&
      interviewDetail.good_to_have_skill_feedback.length <= minLength
    ) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessage.replace("{{SKILL}}", "Good to have skills"),
        life: 3000,
      });
      return false;
    }

    if (
      interviewDetail &&
      (interviewDetail.final_status === "" ||
        interviewDetail.final_status === null)
    ) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail:
          "Please provide your final feedback to proceed with the submission.",
        life: 3000,
      });
      return false;
    }

    if (
      interviewDetail &&
      (interviewDetail.final_recommendation === "" ||
        interviewDetail.final_recommendation === null)
    ) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail:
          "Please provide your final feedback to proceed with the submission.",
        life: 3000,
      });
      return false;
    }

    for (let index = 0; index < interviewState.job.primary_skill.length; index++) {
      if (index < interviewDetail.primary_skill_feedback.length) {
        if (interviewDetail.primary_skill_feedback[index] === undefined || interviewDetail.primary_skill_feedback[index].rating === undefined || interviewDetail.primary_skill_feedback[index].rating === null || interviewDetail.primary_skill_feedback[index].rating === 0) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: ratingErrorMessage.replace("{{SKILL}}", "Primary skills"),
            life: 3000,
          });
          return false;
        }
      }
    }

    for (let index = 0; index < interviewState.job.secondary_skill.length; index++) {
      if (index < interviewDetail.secondary_skill_feedback.length) {
        if (interviewDetail.secondary_skill_feedback[index] === undefined || interviewDetail.secondary_skill_feedback[index].rating === undefined || interviewDetail.secondary_skill_feedback[index].rating === null || interviewDetail.secondary_skill_feedback[index].rating === 0) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: ratingErrorMessage.replace("{{SKILL}}", "Secondary skills"),
            life: 3000,
          });
          return false;
        }
      }
    }

    if (interviewDetail && (interviewDetail.good_to_have_skill_feedback_rating === undefined || interviewDetail.good_to_have_skill_feedback_rating === null || interviewDetail.good_to_have_skill_feedback_rating === 0)) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: ratingErrorMessage.replace("{{SKILL}}", "Good to have skills"),
        life: 3000,
      });
      return false;
    }

    if (interviewDetail && (interviewDetail.coding_skill_feedback_rating === undefined || interviewDetail.coding_skill_feedback_rating === null || interviewDetail.coding_skill_feedback_rating === 0)) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: ratingErrorMessage.replace("{{SKILL}}", "Coding skills"),
        life: 3000,
      });
      return false;
    }

    if (interviewDetail && (interviewDetail.overall_rating === undefined || interviewDetail.overall_rating === null || interviewDetail.overall_rating === 0)) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: ratingErrorMessage.replace("{{SKILL}}", "Overall Feedback skills"),
        life: 3000,
      });
      return false;
    }

    for (let key in interviewDetail.professional_skill) {
      const item = interviewDetail.professional_skill[key];
      if (item.enable && (item.feedback === "" || item.feedback === null || item.rating === 0)) {
        toast.current.show({ severity: 'error', summary: 'Error', detail: `Please provide rating and feedback for ${item.label} to proceed!`, life: 3000 });
        return false;
      }
    }

    return true;
  };

  const isNotEditable = () => {
    return !isDateGreaterThanToday(interviewState.record_editable_till);
  };

  return (
    <div className="container-fluid live-interviewer-container fixed-padding-2">
      <Toast ref={toast} />
      {isLoading && (
        <div className="spinner-loader">
          <Loader
            type="spinner-circle"
            bgColor="#2F2F74"
            color="#2F2F74"
            size={100}
          />
        </div>
      )}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={(event) => {
          handleFileChange(event);
        }}
      />
      <div className="row">
        <div className="col-12 px-0">
          <HeaderScreen />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12">
          <Tab.Container defaultActiveKey="liveinterview">
            <div className="row live-interviewer-container-tab">
              <div className="col-1 sec-menu">
                <Nav className="flex-column">
                  <Nav.Item>
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip>Live Interview</Tooltip>}
                    >
                      <Nav.Link eventKey="liveinterview">
                        <BsCameraVideoFill size={24} />
                      </Nav.Link>
                    </OverlayTrigger>
                  </Nav.Item>
                  <Nav.Item>
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip>Resume</Tooltip>}
                    >
                      <Nav.Link eventKey="resume">
                        <RiFileListFill size={24} />
                      </Nav.Link>
                    </OverlayTrigger>
                  </Nav.Item>
                  <Nav.Item>
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip>Job Responsibilities</Tooltip>}
                    >
                      <Nav.Link eventKey="jobresponsibilities">
                        <BsBagCheckFill size={24} />
                      </Nav.Link>
                    </OverlayTrigger>
                  </Nav.Item>
                  <Nav.Item>
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip>About Company</Tooltip>}
                    >
                      <Nav.Link eventKey="aboutcompany">
                        <RiBuilding2Fill size={24} />
                      </Nav.Link>
                    </OverlayTrigger>
                  </Nav.Item>
                </Nav>
              </div>
              {interviewState && (
                <div className="col-12 sec-menu-padding">
                  <Card className="live-interviewer-details-card">
                    <Card.Body className="p-3">
                      <div className="row">
                        <div className="col-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3 col-xxxl-3 live-interviewer-details-col">
                          <p className="live-interviewer-details-card-title">
                            Candidate Name
                          </p>
                          <p className="live-interviewer-details-card-title-samll">
                            {interviewState
                              ? interviewState.candidate_name
                              : ""}
                          </p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-2 col-xxl-2 col-xxxl-2 live-interviewer-details-col">
                          <p className="live-interviewer-details-card-title">
                            Job Title
                          </p>
                          <p className="live-interviewer-details-card-title-samll">
                            {interviewState
                              ? interviewState.job
                                ? interviewState.job.job_title
                                : ""
                              : ""}
                          </p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-2 col-xxl-2 col-xxxl-2 live-interviewer-details-col">
                          <p className="live-interviewer-details-card-title">
                            Company Name
                          </p>
                          <p className="live-interviewer-details-card-title-samll">
                            {interviewState ? interviewState.company_name : ""}
                          </p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-3 col-xxl-2 col-xxxl-2 live-interviewer-details-col">
                          <p className="live-interviewer-details-card-title">
                            Scheduled On
                          </p>
                          <p className="live-interviewer-details-card-title-samll">
                            {interviewState
                              ? commonDateFormat(interviewState.scheduled_on)
                              : ""}
                          </p>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12 col-xl-2 col-xxl-3 col-xxxl-3 live-interviewer-details-col">
                          <p className="live-interviewer-details-card-title">
                            Coordinator
                          </p>
                          <p className="live-interviewer-details-card-title-samll">
                            {interviewState && interviewState.coordinator
                              ? interviewState.coordinator.first_name +
                              " " +
                              interviewState.coordinator.last_name
                              : ""}{" "}
                            <br></br>
                            <small>
                              {" "}
                              {interviewState && interviewState.coordinator
                                ? interviewState.coordinator.email
                                : ""}{" "}
                            </small>
                            <br></br>
                            <small>
                              {" "}
                              {interviewState && interviewState.coordinator
                                ? interviewState.coordinator.mobile_number
                                : ""}
                            </small>
                          </p>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-2 live-interviewer-details-col">
                          {interviewState &&
                            interviewState.sample_feedback_sheet ? (
                            <OverlayTrigger
                              placement="bottom"
                              overlay={<Tooltip>Feedback Sheet</Tooltip>}
                            >
                              <Button
                                variant="light"
                                onClick={() =>
                                  window.open(
                                    interviewState.sample_feedback_sheet,
                                    "_blank"
                                  )
                                }
                                className="full-width sample-feedback-button"
                              >
                                <AiOutlineFile size={18} />
                                <span>
                                  {new URL(
                                    interviewState.sample_feedback_sheet
                                  ).pathname
                                    .split("/")
                                    .pop()
                                    .replaceAll("%20", " ")}
                                </span>
                              </Button>
                            </OverlayTrigger>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-3 live-interviewer-details-col my-2 my-md-2 my-lg-0">
                          <span className="google-meet-id-span">
                            {interviewState && interviewState.google_meet_id ? (
                              <p className="google-meet-id-p">
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip>Google Meeting Link</Tooltip>
                                  }
                                >
                                  <Button
                                    className="google-meet-link-button w-100"
                                    variant="light"
                                    size="sm"
                                    onClick={() =>
                                      window.open(
                                        interviewState
                                          ? interviewState.google_meet_id
                                          : "",
                                        "_blank"
                                      )
                                    }
                                  >
                                    <span>{interviewState.google_meet_id}</span>
                                  </Button>
                                </OverlayTrigger>
                              </p>
                            ) : (
                              <InputGroup className="mb-3">
                                <Form.Control
                                  placeholder="Google meet id"
                                  value={interviewDetail.google_meet_id}
                                  onChange={(e) => {
                                    setInterviewDetail({
                                      ...interviewDetail,
                                      google_meet_id: e.target.value,
                                    });
                                  }}
                                />
                                <Button
                                  variant="outline-secondary"
                                  onClick={() => updateCandidateProfile()}
                                >
                                  Add
                                </Button>
                              </InputGroup>
                            )}
                          </span>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-2 live-interviewer-details-col my-2 my-md-2 my-lg-0">
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>Join Conference</Tooltip>}
                          >
                            <Button
                              className="google-meet-link-button w-100"
                              variant="success"
                              size="sm"
                              onClick={() =>
                                window.open(
                                  interviewState
                                    ? interviewState.google_meet_id
                                    : "",
                                  "_blank"
                                )
                              }
                            >
                              <BsGoogle size={14} />
                              <span>Join Conference</span>
                            </Button>
                          </OverlayTrigger>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 live-interviewer-details-col my-2 my-md-2 my-lg-0">
                          <Form.Select
                            className="interviewStatus w-100"
                            aria-label="Choose"
                            id="interviewStatus"
                            name="interviewStatus"
                            placeholder="All"
                            onChange={(e) => {
                              setInterviewDetail({
                                ...interviewDetail,
                                incomplete_interview: e.target.value,
                              });
                            }}
                            value={interviewDetail.incomplete_interview}
                          >
                            <option value="">Select Incomplete Reason</option>
                            <option value={BANDWIDTH_ISSUE_CANDIDATE_END}>
                              Bandwidth issue (Candidate end)
                            </option>
                            <option value={BANDWIDTH_ISSUE_INTERVIEWER_END}>
                              Bandwidth issue (Interviewer end)
                            </option>
                            <option value={HARDWARE_ISSUE_CANDIDATE_END}>
                              Hardware issue (Candidate end)
                            </option>
                            <option value={HARDWARE_ISSUE_INTERVIEWER_END}>
                              Hardware issue (Interviewer end)
                            </option>
                            <option value={PROXY}>Proxy Candidate</option>
                            <option value={NO_SHOW_BY_INTERVIWER}>
                              No Show by Interviewer
                            </option>
                            <option value={NO_SHOW_BY_CANDIDATE}>
                              No Show by Candidate
                            </option>
                          </Form.Select>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 live-interviewer-details-col my-2 my-md-2 my-lg-0">
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>Select interview start and end time</Tooltip>}
                          >
                            <DateRangePicker
                              className="w-100"
                              value={interviewDetail.interview_time_range}
                              format="MM/dd/yyyy hh:mm aa"
                              placeholder="Select interview start and end time"
                              placement="bottomEnd"
                              onChange={(value) => {
                                setInterviewDetail({
                                  ...interviewDetail,
                                  interview_time_range: value
                                })
                              }} />
                          </OverlayTrigger>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-1 live-interviewer-details-col">
                          <Button
                            className="live-interviewer-details-button w-100"
                            size="sm"
                            disabled={isNotEditable()}
                            onClick={() => {
                              if (
                                interviewDetail.incomplete_interview !==
                                null &&
                                interviewDetail.incomplete_interview !== ""
                              ) {
                                const feedbackMessage =
                                  "Feedback submitted successfully";
                                updateCandidateProfile(feedbackMessage);
                              } else {
                                if (
                                  interviewState &&
                                  interviewState.job
                                    .custom_feedback_sheet_file
                                ) {
                                  if (!interviewDetail.feedback_sheet) {
                                    toast.current.show({
                                      severity: "error",
                                      summary: "Error",
                                      detail:
                                        "Please upload candidate's feedback sheet to proceed with the submission.",
                                      life: 3000,
                                    });
                                  } else {
                                    if (checkFeedbackGiven()) {
                                      const feedbackMessage =
                                        interviewDetail.is_feedback_given
                                          ? "Feedback updated successfully"
                                          : "Feedback submitted successfully";
                                      interviewDetail.is_feedback_given = true;
                                      updateCandidateProfile(feedbackMessage);
                                    }
                                  }
                                } else {
                                  if (checkFeedbackGiven()) {
                                    const feedbackMessage =
                                      interviewDetail.is_feedback_given
                                        ? "Feedback updated successfully"
                                        : "Feedback submitted successfully";
                                    interviewDetail.is_feedback_given = true;
                                    updateCandidateProfile(feedbackMessage);
                                  }
                                }
                              }
                            }}
                          >
                            Submit
                            <VscSend size={16} className="mx-2" />
                          </Button>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                  <Tab.Content className="my-3">
                    <Tab.Pane eventKey="liveinterview">
                      <Card className="live-interviewer-sills-card mt-2">
                        <Card.Body>
                          <Tabs defaultActiveKey="primaryskills" justify>
                            <Tab
                              eventKey="primaryskills"
                              title="Primary skills"
                            >
                              <div className="row tabHeight">
                                <div className="col-12 p-5">
                                  {interviewState && interviewState.job
                                    ? _.map(
                                      interviewState.job.primary_skill,
                                      (primary_skill, index) => {
                                        return (
                                          <p key={"primary_skill_" + index}>
                                            {primary_skill}
                                          </p>
                                        );
                                      }
                                    )
                                    : ""}
                                </div>
                              </div>
                            </Tab>
                            <Tab
                              eventKey="secondaryskills"
                              title="Secondary skills"
                            >
                              <div className="row tabHeight">
                                <div className="col-12 p-5">
                                  {interviewState && interviewState.job
                                    ? _.map(
                                      interviewState.job.secondary_skill,
                                      (secondary_skill, index) => {
                                        return (
                                          <p key={"secondary_skill_" + index}>
                                            {secondary_skill}
                                          </p>
                                        );
                                      }
                                    )
                                    : ""}
                                </div>
                              </div>
                            </Tab>
                            <Tab
                              eventKey="additionalskills"
                              title="Additional skills"
                            >
                              <div className="row tabHeight">
                                <div className="col-12 p-5">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: interviewState && interviewState.job
                                        ? interviewState.job
                                          .good_to_have_skills_textarea
                                        : "",
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </Tab>
                            <Tab
                              eventKey="interviewquestions"
                              title="Interview questions"
                            >
                              <div className="row tabHeight">
                                <div className="col-12 p-5">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: interviewState && interviewState.job
                                        ? interviewState.job
                                          .mandatory_interview_questions_textarea
                                        : "",
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </Tab>
                            <Tab
                              eventKey="codingquestions"
                              title="Coding questions"
                            >
                              <div className="row tabHeight">
                                <div className="col-12 p-5">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: interviewState && interviewState.job
                                        ? interviewState.job
                                          .mandatory_coding_questions_textarea
                                        : "",
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </Tab>
                          </Tabs>
                        </Card.Body>
                      </Card>
                      <div className="row live-interviewer-other-details mt-3">
                        <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-5">
                          <Card>
                            <Card.Body>
                              <div className="row">
                                <div className="col-12">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <p className="live-interviewer-other-details-code-test-title">
                                      Coding Test
                                    </p>
                                    <Form.Check
                                      type="switch"
                                      className="coding-test-switch"
                                      checked={
                                        interviewState && interviewState.job
                                          ? interviewState.job
                                            .is_coding_test_mandatory
                                          : false
                                      }
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div className="col-12">
                                  <p className="live-interviewer-other-details-code-test-hint text-danger">
                                    Hint : Please ask the candidate to write
                                    using the link sent to mail
                                  </p>
                                </div>
                                <div className="col-12 tab-adjust_2">
                                  <Tabs
                                    defaultActiveKey="uploadscreenshot"
                                    justify
                                  >
                                    <Tab
                                      eventKey="codebycandidate"
                                      title="Code by candidate"
                                    >
                                      <div className="row">
                                        <div className="col-12 p-5">
                                          {interviewState &&
                                            interviewState.code_by_candidate && (
                                              <SyntaxHighlighter
                                                language="java"
                                                style={solarizedlight}
                                              >
                                                {interviewState
                                                  ? interviewState.code_by_candidate
                                                  : ""}
                                              </SyntaxHighlighter>
                                            )}
                                        </div>
                                      </div>
                                    </Tab>
                                    <Tab
                                      eventKey="codingquestion"
                                      title="Coding question"
                                    >
                                      <div className="row">
                                        <div className="col-12 p-5">
                                          <CKEditor
                                            disabled={isNotEditable()}
                                            config={{
                                              placeholder:
                                                "Provide coding questions to candidate*",
                                            }}
                                            editor={ClassicEditor}
                                            data={
                                              interviewState
                                                ? interviewState.coding_questions
                                                : ""
                                            }
                                            onChange={(event, editor) => {
                                              const data = editor.getData();
                                              interviewDetail.coding_questions =
                                                data;
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </Tab>
                                    <Tab
                                      eventKey="uploadscreenshot"
                                      title="Upload Screenshot"
                                    >
                                      <div className="row">
                                        {interviewDetail &&
                                          interviewDetail.code_of_screenshot && (
                                            <div className="col-12 px-4 pt-5">
                                              <ul key="code_of_screenshot">
                                                {interviewDetail.code_of_screenshot.map(
                                                  (item, docIndex) => {
                                                    return (
                                                      <li
                                                        key={
                                                          "code_of_screenshot_" +
                                                          docIndex
                                                        }
                                                      >
                                                        <div>
                                                          <a
                                                            target="_blank"
                                                            href={item}
                                                          >
                                                            {new URL(
                                                              item
                                                            ).pathname
                                                              .split("/")
                                                              .pop()
                                                              .replaceAll(
                                                                "%20",
                                                                " "
                                                              )}
                                                          </a>
                                                          &nbsp;&nbsp;
                                                          {
                                                            !isNotEditable() && (
                                                              <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                  <Tooltip>
                                                                    Remove
                                                                    Screenshots
                                                                  </Tooltip>
                                                                }
                                                              >
                                                                <i
                                                                  onClick={() => {
                                                                    const codeOfScreenshot =
                                                                      interviewDetail.code_of_screenshot.filter(
                                                                        (
                                                                          item,
                                                                          index
                                                                        ) =>
                                                                          index !==
                                                                          docIndex
                                                                      );
                                                                    interviewDetail.code_of_screenshot =
                                                                      codeOfScreenshot;
                                                                    updateCandidateProfile(
                                                                      "Screenshot removed successfully",
                                                                      true
                                                                    );
                                                                  }}
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                    fontSize:
                                                                      "1rem",
                                                                  }}
                                                                  className="pi pi-times"
                                                                ></i>
                                                              </OverlayTrigger>
                                                            )
                                                          }

                                                        </div>
                                                      </li>
                                                    );
                                                  }
                                                )}
                                              </ul>
                                            </div>
                                          )}
                                        <div className="col-12 px-5 py-3">
                                          <div className="row live-interviewer-other-details-code-test-upload-screenshot">
                                            <div className="col-12 p-5 text-center">
                                              <Form.Control
                                                disabled={isNotEditable()}
                                                onChange={(e) => {
                                                  const file = e.target.files;
                                                  if (
                                                    e.target.files &&
                                                    e.target.files.length > 0 &&
                                                    file
                                                  ) {
                                                    if (
                                                      !file[0].type.startsWith(
                                                        "image/"
                                                      )
                                                    ) {
                                                      toast.current.show({
                                                        severity: "error",
                                                        summary: "Error",
                                                        detail:
                                                          "Please upload only images",
                                                        life: 3000,
                                                      });
                                                    } else {
                                                      handleFileChange(
                                                        e,
                                                        "code_of_screenshot"
                                                      );
                                                      const codeOfScreenshotFileInput =
                                                        document.getElementById(
                                                          "codeOfScreenshotFileInput"
                                                        );
                                                      if (
                                                        codeOfScreenshotFileInput
                                                      ) {
                                                        codeOfScreenshotFileInput.value =
                                                          null;
                                                      }
                                                    }
                                                  }
                                                }}
                                                id="codeOfScreenshotFileInput"
                                                type="file"
                                                accept="image/*"
                                                className="w-50 m-auto"
                                              />
                                              <p className="live-interviewer-other-details-code-test-upload-screenshot-hint">
                                                Upload candidate screenshot and coding test screenshot
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Tab>
                                  </Tabs>
                                </div>
                              </div>
                              <div>
                                <Button
                                  disabled={isNotEditable()}
                                  className="live-interviewer-details-button"
                                  onClick={() => {
                                    updateCandidateProfile();
                                  }}
                                >
                                  Save
                                  <VscSend size={16} className="mx-2" />
                                </Button>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-7">
                          <Card>
                            <Card.Body>
                              <div className="row">
                                <div className="col-12 tab-adjust">
                                  <Tabs
                                    variant="underline"
                                    defaultActiveKey={
                                      interviewState && interviewState.job &&
                                        interviewState.job
                                          .custom_feedback_sheet_file
                                        ? "overallFeedback"
                                        : "primarySkillFeedback"
                                    }
                                    justify
                                    onSelect={(value) => {
                                      setActiveTabKey(value);
                                    }}
                                  >
                                    {interviewState && interviewState.job &&
                                      !interviewState.job
                                        .custom_feedback_sheet_file && (
                                        <Tab
                                          eventKey="primarySkillFeedback"
                                          title="Primary Skill"
                                        >
                                          <div className="row">
                                            {!_.isUndefined(interviewState) &&
                                              !_.isNull(interviewState) &&
                                              interviewState.job
                                                .primary_skill &&
                                              _.map(
                                                interviewState.job
                                                  .primary_skill,
                                                (skill, index) => {
                                                  return (
                                                    <>
                                                      <div className="col-5 col-md-6 col-lg-6 my-3">
                                                        <Button variant="outline-dark">
                                                          {skill}
                                                        </Button>
                                                      </div>
                                                      <div
                                                        className="col-7 col-md-6 col-lg-6 my-3 text-end text-md-end text-lg-end"

                                                      >
                                                        <StarRating
                                                          totalStars={5}
                                                          filledStars={
                                                            index >= 0 &&
                                                              index <
                                                              interviewDetail
                                                                .primary_skill_feedback
                                                                .length &&
                                                              interviewDetail
                                                                .primary_skill_feedback[
                                                              index
                                                              ] != null
                                                              ? interviewDetail
                                                                .primary_skill_feedback[
                                                                index
                                                              ].rating
                                                              : 0
                                                          }
                                                          onRatingChange={(
                                                            newRating
                                                          ) => {
                                                            if (
                                                              index >= 0 &&
                                                              index <
                                                              interviewDetail
                                                                .primary_skill_feedback
                                                                .length
                                                            ) {
                                                              interviewDetail.primary_skill_feedback[
                                                                index
                                                              ].rating =
                                                                newRating;
                                                            } else {
                                                              const newItem = {
                                                                review: "",
                                                                rating:
                                                                  newRating,
                                                              };
                                                              interviewDetail.primary_skill_feedback[
                                                                index
                                                              ] = newItem;
                                                            }
                                                            setInterviewDetail({
                                                              ...interviewDetail,
                                                            });
                                                            updateCandidateProfile(
                                                              "",
                                                              false
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                      <div className="col-12">
                                                        <CKEditor
                                                          disabled={isNotEditable()}
                                                          config={{
                                                            placeholder:
                                                              "Provide Skils review",
                                                          }}
                                                          editor={ClassicEditor}
                                                          data={
                                                            index <
                                                              interviewState
                                                                .primary_skill_feedback
                                                                .length &&
                                                              interviewState
                                                                .primary_skill_feedback[
                                                              index
                                                              ] != null
                                                              ? interviewState
                                                                .primary_skill_feedback[
                                                                index
                                                              ].review
                                                              : ""
                                                          }
                                                          onChange={(
                                                            event,
                                                            editor
                                                          ) => {
                                                            const data =
                                                              editor.getData();
                                                            if (
                                                              index >= 0 &&
                                                              index <
                                                              interviewDetail
                                                                .primary_skill_feedback
                                                                .length
                                                            ) {
                                                              interviewDetail.primary_skill_feedback[
                                                                index
                                                              ].review = data;
                                                            } else {
                                                              const newItem = {
                                                                review: data,
                                                                rating: 0,
                                                              };
                                                              interviewDetail.primary_skill_feedback[
                                                                index
                                                              ] = newItem;
                                                            }
                                                            updateCandidateProfile(
                                                              "",
                                                              false
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                    </>
                                                  );
                                                }
                                              )}
                                          </div>
                                        </Tab>
                                      )}
                                    {interviewState && interviewState.job &&
                                      !interviewState.job
                                        .custom_feedback_sheet_file && (
                                        <Tab
                                          eventKey="secondarySkillFeedback"
                                          title="Secondary Skill"
                                        >
                                          <div className="row">
                                            {!_.isUndefined(interviewState) &&
                                              !_.isNull(interviewState) &&
                                              interviewState.job
                                                .secondary_skill &&
                                              _.map(
                                                interviewState.job
                                                  .secondary_skill,
                                                (skill, index) => {
                                                  return (
                                                    <>
                                                      <div className="col-5 col-md-6 col-lg-6 my-3">
                                                        <Button variant="outline-dark">
                                                          {skill}
                                                        </Button>
                                                      </div>
                                                      <div
                                                        className="col-7 col-md-6 col-lg-6 my-3 text-end text-md-end text-lg-end"

                                                      >
                                                        <StarRating
                                                          totalStars={5}
                                                          filledStars={
                                                            index >= 0 &&
                                                              index <
                                                              interviewDetail
                                                                .secondary_skill_feedback
                                                                .length
                                                              ? interviewDetail
                                                                .secondary_skill_feedback[
                                                                index
                                                              ].rating
                                                              : 0
                                                          }
                                                          onRatingChange={(
                                                            newRating
                                                          ) => {
                                                            if (
                                                              index >= 0 &&
                                                              index <
                                                              interviewDetail
                                                                .secondary_skill_feedback
                                                                .length
                                                            ) {
                                                              interviewDetail.secondary_skill_feedback[
                                                                index
                                                              ].rating =
                                                                newRating;
                                                            } else {
                                                              const newItem = {
                                                                review: "",
                                                                rating:
                                                                  newRating,
                                                              };
                                                              interviewDetail.secondary_skill_feedback[
                                                                index
                                                              ] = newItem;
                                                            }
                                                            setInterviewDetail({
                                                              ...interviewDetail,
                                                            });
                                                            updateCandidateProfile(
                                                              "",
                                                              false
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                      <div className="col-12">
                                                        <CKEditor
                                                          disabled={isNotEditable()}
                                                          config={{
                                                            placeholder:
                                                              "Provide Skils review",
                                                          }}
                                                          editor={ClassicEditor}
                                                          data={
                                                            index <
                                                              interviewState
                                                                .secondary_skill_feedback
                                                                .length
                                                              ? interviewState
                                                                .secondary_skill_feedback[
                                                                index
                                                              ].review
                                                              : ""
                                                          }
                                                          onChange={(
                                                            event,
                                                            editor
                                                          ) => {
                                                            const data =
                                                              editor.getData();
                                                            if (
                                                              index >= 0 &&
                                                              index <
                                                              interviewDetail
                                                                .secondary_skill_feedback
                                                                .length
                                                            ) {
                                                              interviewDetail.secondary_skill_feedback[
                                                                index
                                                              ].review = data;
                                                            } else {
                                                              const newItem = {
                                                                review: data,
                                                                rating: 0,
                                                              };
                                                              interviewDetail.secondary_skill_feedback[
                                                                index
                                                              ] = newItem;
                                                            }
                                                            updateCandidateProfile(
                                                              "",
                                                              false
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                    </>
                                                  );
                                                }
                                              )}
                                          </div>
                                        </Tab>
                                      )}
                                    {interviewState && interviewState.job &&
                                      !interviewState.job
                                        .custom_feedback_sheet_file && (
                                        <Tab
                                          eventKey="goodToHaveSkillFeedback"
                                          title="Good to have skills"
                                        >
                                          <div className="row">
                                            <div className="col-6 my-3">
                                              {/* <Button variant="primary">
                                                                                                        {"Rating"}
                                                                                                    </Button> */}
                                            </div>
                                            <div
                                              className="col-6"
                                              style={{ textAlign: "right" }}
                                            >
                                              <StarRating
                                                totalStars={5}
                                                filledStars={
                                                  interviewDetail &&
                                                    interviewDetail.good_to_have_skill_feedback_rating
                                                    ? interviewDetail.good_to_have_skill_feedback_rating
                                                    : 0
                                                }
                                                onRatingChange={(newRating) => {
                                                  interviewDetail.good_to_have_skill_feedback_rating =
                                                    newRating;
                                                  setInterviewDetail({
                                                    ...interviewDetail,
                                                  });
                                                  updateCandidateProfile(
                                                    "",
                                                    false
                                                  );
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-12 my-3">
                                              <CKEditor
                                                disabled={isNotEditable()}
                                                config={{
                                                  placeholder:
                                                    "Provide Skils review",
                                                }}
                                                editor={ClassicEditor}
                                                data={
                                                  interviewState
                                                    ? interviewState.good_to_have_skill_feedback
                                                    : ""
                                                }
                                                onChange={(event, editor) => {
                                                  const data = editor.getData();
                                                  interviewDetail.good_to_have_skill_feedback =
                                                    data;
                                                  updateCandidateProfile(
                                                    "",
                                                    false
                                                  );
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </Tab>
                                      )}
                                    {interviewState && interviewState.job &&
                                      !interviewState.job
                                        .custom_feedback_sheet_file && (
                                        <Tab
                                          eventKey="codingSkillFeedback"
                                          title="Coding skills"
                                        >
                                          <div className="row">
                                            <div className="col-6 my-3">
                                            </div>
                                            <div
                                              className="col-6 my-3"
                                              style={{
                                                textAlign: "right",
                                              }}
                                            >
                                              <StarRating
                                                totalStars={5}
                                                filledStars={
                                                  interviewDetail &&
                                                    interviewDetail.coding_skill_feedback_rating
                                                    ? interviewDetail.coding_skill_feedback_rating
                                                    : 0
                                                }
                                                onRatingChange={(newRating) => {
                                                  interviewDetail.coding_skill_feedback_rating =
                                                    parseInt(newRating);
                                                  setInterviewDetail({
                                                    ...interviewDetail,
                                                  });
                                                  updateCandidateProfile(
                                                    "",
                                                    false
                                                  );
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-12 my-3">
                                              <CKEditor
                                                disabled={isNotEditable()}
                                                config={{
                                                  placeholder:
                                                    "Provide Skils review",
                                                }}
                                                editor={ClassicEditor}
                                                data={
                                                  interviewState
                                                    ? interviewState.coding_skill_feedback
                                                    : ""
                                                }
                                                onChange={(event, editor) => {
                                                  const data = editor.getData();
                                                  interviewDetail.coding_skill_feedback =
                                                    data;
                                                  updateCandidateProfile(
                                                    "",
                                                    false
                                                  );
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </Tab>
                                      )}

                                    {interviewState && interviewState.job &&
                                      !interviewState.job
                                        .custom_feedback_sheet_file && (
                                        < Tab
                                          eventKey="ProfessionalSkillFeedback"
                                          title="Professional skills"
                                        >
                                          {interviewState && !interviewState.job.custom_feedback_sheet_file && Object.entries(interviewState.professional_skill).map(([key, skillItem], index) =>
                                            <>
                                              <div className="row">
                                                <div className="col-12 my-2">
                                                  <Form.Check
                                                    inline
                                                    label={skillItem.label}
                                                    checked={interviewDetail.professional_skill[key].enable}
                                                    onChange={(e) => {
                                                      interviewDetail.professional_skill[key].feedback = "";
                                                      interviewDetail.professional_skill[key].rating = 0;
                                                      interviewDetail.professional_skill[key].enable = e.target.checked;
                                                      setInterviewDetail({
                                                        ...interviewDetail,
                                                      });
                                                      updateCandidateProfile(
                                                        "",
                                                        false
                                                      );
                                                    }}
                                                    type="checkbox"
                                                  />
                                                </div>
                                              </div>
                                              {
                                                skillItem.enable && (
                                                  <>
                                                    <div className="row">
                                                      <div className="col-6 my-3">
                                                        <Button variant="primary">{skillItem.label}</Button>
                                                      </div>
                                                      <div
                                                        className="col-6 my-3"
                                                        style={{
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        <StarRating
                                                          totalStars={5}
                                                          filledStars={
                                                            interviewDetail &&
                                                              interviewDetail.professional_skill[key].rating
                                                              ? interviewDetail.professional_skill[key].rating
                                                              : 0
                                                          }
                                                          onRatingChange={(newRating) => {
                                                            interviewDetail.professional_skill[key].rating =
                                                              parseInt(newRating);
                                                            setInterviewDetail({
                                                              ...interviewDetail,
                                                            });
                                                            updateCandidateProfile(
                                                              "",
                                                              false
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="row">
                                                      <div className="col-12 my-3">
                                                        <CKEditor
                                                          disabled={isNotEditable()}
                                                          config={{
                                                            placeholder:
                                                              "Provide Skils review",
                                                          }}
                                                          editor={ClassicEditor}
                                                          data={
                                                            interviewState
                                                              ? interviewDetail.professional_skill[key].feedback
                                                              : ""
                                                          }
                                                          onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            interviewDetail.professional_skill[key].feedback =
                                                              data;
                                                            updateCandidateProfile(
                                                              "",
                                                              false
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  </>
                                                )
                                              }
                                            </>
                                          )}
                                        </Tab>
                                      )
                                    }
                                    <Tab
                                      eventKey="overallFeedback"
                                      title="Overall Feedback"
                                    >
                                      <div className="row mx-3 my-3">
                                        <div className="col-12 col-md-4 col-lg-3 text-center">
                                          <StarRating
                                            totalStars={5}
                                            filledStars={
                                              interviewDetail.overall_rating
                                            }
                                            onRatingChange={(newRating) => {
                                              setInterviewDetail({
                                                ...interviewDetail,
                                                overall_rating: newRating,
                                              });
                                              updateCandidateProfile("", false);
                                            }}
                                          />
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-3 text-center my-2">
                                          <Form.Select
                                            disabled={isNotEditable()}
                                            aria-label="Rating"
                                            onChange={(e) => {
                                              setInterviewDetail({
                                                ...interviewDetail,
                                                final_status: e.target.value,
                                              });
                                              updateCandidateProfile("", false);
                                            }}
                                            value={interviewDetail.final_status}
                                          >
                                            <option>Final Status</option>
                                            <option
                                              value={
                                                INTERVIEW_FINAL_STATUS_SELECTED
                                              }
                                            >
                                              {"Selected"}
                                            </option>
                                            <option
                                              value={
                                                INTERVIEW_FINAL_STATUS_REJECTED
                                              }
                                            >
                                              {"Rejected"}
                                            </option>
                                          </Form.Select>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-6 text-center my-2">
                                          {interviewState && interviewState.job &&
                                            interviewState.job
                                              .custom_feedback_sheet ? (
                                            <Button
                                              onClick={() =>
                                                handleDownload(
                                                  interviewState
                                                    ? interviewState.job
                                                      .custom_feedback_sheet_file
                                                    : ""
                                                )
                                              }
                                            >
                                              Download custom feedback sheet{" "}
                                              <RiDownload2Fill size={20} />
                                            </Button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                      {interviewState && interviewState.job &&
                                        interviewState.job
                                          .custom_feedback_sheet_file && (
                                          <div className="row">
                                            {interviewState &&
                                              interviewState.feedback_sheet && (
                                                <div className="col-12 px-3">
                                                  <ul>
                                                    <li>
                                                      <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                          <Tooltip>
                                                            Feedback Sheet
                                                          </Tooltip>
                                                        }
                                                      >
                                                        <a
                                                          target="_blank"
                                                          href={
                                                            interviewState.feedback_sheet
                                                          }
                                                        >
                                                          {new URL(
                                                            interviewState.feedback_sheet
                                                          ).pathname
                                                            .split("/")
                                                            .pop()
                                                            .replaceAll(
                                                              "%20",
                                                              " "
                                                            )}
                                                        </a>
                                                      </OverlayTrigger>
                                                    </li>
                                                  </ul>
                                                </div>
                                              )}
                                            <div className="col-12 px-5 py-3">
                                              <div className="row live-interviewer-other-details-code-test-upload-screenshot">
                                                <div className="col-12 p-5 text-center">
                                                  <Form.Control
                                                    onChange={(e) => {
                                                      handleFileChange(
                                                        e,
                                                        "feedback_sheet"
                                                      );
                                                    }}
                                                    type="file"
                                                    className="w-50 m-auto"
                                                  />
                                                  <p className="live-interviewer-other-details-code-test-upload-screenshot-hint">
                                                    Upload candidate’s feedback
                                                    sheet here
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}

                                      {interviewState && interviewState.job &&
                                        !interviewState.job
                                          .custom_feedback_sheet_file && (
                                          <div className="row">
                                            <div className="col-12 p-5">
                                              <CKEditor
                                                disabled={isNotEditable()}
                                                config={{
                                                  placeholder:
                                                    "Provide final recommendation*",
                                                }}
                                                editor={ClassicEditor}
                                                data={
                                                  interviewState
                                                    ? interviewState.final_recommendation &&
                                                      interviewState.final_recommendation
                                                      ? interviewState.final_recommendation
                                                      : ""
                                                    : ""
                                                }
                                                onChange={(event, editor) => {
                                                  const data = editor.getData();
                                                  interviewDetail.final_recommendation =
                                                    data;
                                                  updateCandidateProfile(
                                                    "",
                                                    false
                                                  );
                                                }}
                                              />
                                            </div>
                                          </div>
                                        )}
                                    </Tab>
                                  </Tabs>
                                </div>
                              </div>
                              {/* <div>
                                                                    <Button className="live-interviewer-details-button"
                                                                        onClick={() => {
                                                                            updateCandidateProfile();
                                                                        }}
                                                                    >Save<VscSend size={22} className="mx-2" /></Button>
                                                                </div> */}
                            </Card.Body>
                          </Card>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="resume">
                      <Card>
                        <Card.Body>
                          <div className="row">
                            <div className="col-12 text-center">
                              <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip>Download Resume</Tooltip>}
                              >
                                <a
                                  href="javascript:void(0)"
                                  className="live-interviewer-resume-download"
                                  onClick={() => {
                                    handleDownload(
                                      interviewState
                                        ? interviewState.resume
                                        : ""
                                    );
                                  }}
                                >
                                  <ImDownload2 size={24} />
                                  <span>Download</span>
                                </a>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip>Print Resume</Tooltip>}
                              >
                                <a
                                  href="javascript:void(0)"
                                  onClick={() => {
                                    printPDF(
                                      interviewState
                                        ? interviewState.resume
                                        : ""
                                    );
                                  }}
                                  className="live-interviewer-resume-download mx-5"
                                >
                                  <BiSolidPrinter size={24} />
                                  <span>Print</span>
                                </a>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip>Save as a copy Resume</Tooltip>
                                }
                              >
                                <a
                                  onClick={() => {
                                    handleDownload(
                                      interviewState
                                        ? interviewState.resume
                                        : ""
                                    );
                                  }}
                                  href="javascript:void(0)"
                                  className="live-interviewer-resume-download"
                                >
                                  <FaCloudUploadAlt size={24} />
                                  <span>Save as a copy</span>
                                </a>
                              </OverlayTrigger>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 text-center">
                              {interviewState && interviewState.resume ? (
                                interviewState.resume.includes(".doc") ? (
                                  <iframe
                                    key="iframe-doc"
                                    style={{
                                      width: "100%",
                                      height: "80vh",
                                    }}
                                    src={`https://docs.google.com/gview?url=${interviewState.resume}&embedded=true`}
                                  ></iframe>
                                ) : (
                                  <Document
                                    file={
                                      interviewState
                                        ? interviewState.resume
                                        : ""
                                    }
                                    onLoadSuccess={onDocumentLoadSuccess}
                                  >
                                    {Array.from(
                                      new Array(numPages),
                                      (el, index) => (
                                        <Page
                                          key={`page_${index + 1}`}
                                          pageNumber={index + 1}
                                          renderAnnotationLayer={false}
                                          scale={2}
                                        />
                                      )
                                    )}
                                  </Document>
                                )
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Tab.Pane>
                    <Tab.Pane eventKey="jobresponsibilities">
                      <Card>
                        <Card.Body className="p-5">
                          <div className="row">
                            <div className="col-12">
                              <b>Job Responsibilities :</b>
                              <div
                                className="mt-3"
                                dangerouslySetInnerHTML={{
                                  __html: interviewState && interviewState.job
                                    ? interviewState.job.job_title
                                    : "",
                                }}
                              ></div>
                            </div>
                            <div className="col-12">
                              <b>Description :</b>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: interviewState && interviewState.job
                                    ? interviewState.job.job_discription
                                    : "",
                                }}
                              ></div>
                            </div>
                            <div className="col-12">
                              <br></br>
                              {interviewState && interviewState.job ? (
                                interviewState.job.upload_jd.includes(
                                  ".doc"
                                ) ? (
                                  <iframe
                                    key="iframe-doc"
                                    style={{
                                      width: "100%",
                                      height: "80vh",
                                    }}
                                    src={`https://docs.google.com/gview?url=${interviewState.job.upload_jd}&embedded=true`}
                                  ></iframe>
                                ) : (
                                  <object
                                    key="object-other"
                                    data={interviewState.job.upload_jd}
                                    style={{
                                      width: "100%",
                                      height: "80vh",
                                    }}
                                  ></object>
                                )
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Tab.Pane>
                    <Tab.Pane eventKey="aboutcompany">
                      <Card>
                        <Card.Body className="p-5">
                          <div className="row">
                            <div className="col-6">
                              <img
                                src={
                                  interviewState && interviewState.company
                                    ? interviewState.company.profile_photo
                                    : ""
                                }
                                className="w-50"
                              />
                            </div>
                            {/* <div className="col-6 text-end">
                                                                URL: <a href={jobDetails.company_url} target="_blank">{jobDetails.company_url}</a>
                                                            </div> */}
                            <div className="col-12 mt-4">
                              <b>About Company :</b>
                              <p
                                className="mt-3"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    interviewState && interviewState.company
                                      ? interviewState.company
                                        .company_description
                                      : "",
                                }}
                              ></p>
                            </div>
                            <div className="col-12 mt-4">
                              <div className="row">
                                {/* <div id="jdoodle-container" data-pym-src='https://www.jdoodle.com/plugin' data-language="php" data-version-index="4">
                                                                    </div> */}
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              )}
            </div>
          </Tab.Container>
        </div>
      </div >
    </div >
  );
};
export default LiveInterviewerScreen;
