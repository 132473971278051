import _ from 'lodash';
import moment from 'moment';
import { Dialog } from "primereact/dialog";
import { Toast } from 'primereact/toast';
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, DropdownButton, Form, InputGroup, Modal, Nav, OverlayTrigger, Row, Tab, Table, Tooltip } from 'react-bootstrap';
import { BsFillSendFill } from "react-icons/bs";
import { CiFilter } from "react-icons/ci";
import { BsCash } from "react-icons/bs";
import { FaDownload, FaEye, FaFileAlt, FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import Loader from "react-js-loader";
import { useNavigate } from "react-router-dom";
import { API_URL, ITEMS_PER_PAGE, QC_STATUS_PASS, USER_STATUS_APPROVE, commonDateFormat, fileName, getAuthUserFromSessionStorage, getInterviewFinalStatus, getQCStatuslabel } from "../../common/constant";
import HeaderScreen from "../HeaderScreen/HeaderScreen";
import "./PaymentScreen.css";
import { EMPLOYER_SUPER_ADMIN, INTERVIEWER } from '../../common/roles';
import CustomPaginationComponent from '../../common/CustomPaginationComponent/CustomPaginationComponent';
const PaymentScreen = () => {
    const navigate = useNavigate();
    const [userDetails, setUserType] = useState(getAuthUserFromSessionStorage());
    const [isLoading, setIsLoading] = useState(false);

    const [users, setUsers] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(ITEMS_PER_PAGE);
    const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'desc' });
    const paingationRef = useRef();
    const [selectedAccountType, setSelectedAccountType] = useState(INTERVIEWER);

    // Assume you have a state variable to manage the modal's visibility
    const [showInterviewReportModal, setShowInterviewReportModal] = useState(false);
    const [showCompanyReportModal, setShowCompanyReportModal] = useState(false);
    const [csvContent, setCsvContent] = useState('');

    const toast = useRef(null);

    const [selectedFields, setSelectedFields] = useState({
        primary_skill: [],
        secondary_skill: [],
        designations: [],
        months: [],
        years: []
    });

    const months = [
        { id: 1, name: 'January' },
        { id: 2, name: 'February' },
        { id: 3, name: 'March' },
        { id: 4, name: 'April' },
        { id: 5, name: 'May' },
        { id: 6, name: 'June' },
        { id: 7, name: 'July' },
        { id: 8, name: 'August' },
        { id: 9, name: 'September' },
        { id: 10, name: 'October' },
        { id: 11, name: 'November' },
        { id: 12, name: 'December' }
    ];

    const [yearsList, setYearsList] = useState([]);
    const [commonMonth, setCommonMonth] = useState(new Date().getMonth() + 1)
    const [commonYear, setCommonYear] = useState(new Date().getFullYear())

    const [skillsOptions, setSkillsOptions] = useState([]);
    const [designationOptions, setDesignationOptions] = useState([]);

    const [selectedItem, setSelectedItem] = useState(null);
    const [isShowResumeDialog, setIsShowResumeDialog] = useState(false)
    const getSkills = () => {
        fetch(`${API_URL}api/getSkills`)
            .then((response) => response.json())
            .then((response) => {
                setSkillsOptions(response.data)
            })
            .catch((error) => {
                setSkillsOptions([])
            });
    }

    const getPositions = () => {
        fetch(`${API_URL}api/getPositions`)
            .then((response) => response.json())
            .then((response) => {
                setDesignationOptions(response.data);
            })
            .catch((error) => {
                setDesignationOptions([])
            });
    }

    const handleCheckboxChange = (fieldType, value) => {
        setSelectedFields((prevSelectedFields) => {
            const isSelected = prevSelectedFields[fieldType].includes(value);
            let updatedFields = {
                ...prevSelectedFields,
                [fieldType]: isSelected
                    ? prevSelectedFields[fieldType].filter((item) => item !== value)
                    : [...prevSelectedFields[fieldType], value],
            };
            return updatedFields;
        });
    };

    useEffect(() => {
        if (!_.isUndefined(skillsOptions) && !_.isNull(skillsOptions) && (skillsOptions.length == 0)) {
            getSkills()
        }
        if (!_.isUndefined(designationOptions) && !_.isNull(designationOptions) && (designationOptions.length == 0)) {
            getPositions()
        }
        // Get the current year
        const currentYear = new Date().getFullYear();
        const startYear = 2024;
        const years = Array.from({ length: currentYear - startYear + 1 }, (_, index) => currentYear - index);
        setYearsList(years);
    }, []);

    useEffect(() => {
        getUsers();
    }, [pageNumber, pageSize, searchText, selectedFields, selectedAccountType, sortConfig])

    const getUsers = () => {
        setIsLoading(true)
        const apiUrl = `${API_URL}api/getUsers?page=${pageNumber}&limit=${pageSize}&sortBy=${sortConfig.key}&sortOrder=${sortConfig.direction}`;
        const requestBody = {
            ...selectedFields,
            account_type: selectedAccountType,
            search: searchText,
            company_id: ""
        }
        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify(requestBody)
        })
            .then((response) => response.json())
            .then((response) => {
                setIsLoading(false)
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data)) {
                    const users = response.data.map((item) => {
                        return { ...item, month: (new Date().getMonth() + 1), year: new Date().getFullYear() }
                    })
                    setUsers(users);
                    setTotalCount(response.totalCount)
                    setCommonMonth(new Date().getMonth() + 1)
                    setCommonYear(new Date().getFullYear())
                }
            })
            .catch((err) => {
                setIsLoading(false)
                setUsers([]);
            });
    }

    const resetPagination = () => {
        setPageNumber(1)
        if (paingationRef.current) {
            paingationRef.current.setCurrentPage(1)
        }
    }

    const updatePagination = (pageNumber, pageSize) => {
        setPageNumber(pageNumber)
        setPageSize(pageSize)
    }

    const convertToCsv = (data) => {
        const header = "Candidate name, Candidate E-mail, Title, Company name, Schedule date, Scheduled By, Rating, Verdit, Duration, QC status, QC Feedback, Location, Amount, QC deduction, Final Amount";
        const rowData = [];
        data.map((item) => {
            const datum = {
                "candidate_name": item.candidate_name,
                "candidate_email": item.candidate_email,
                "title": item.job_title,
                "company_name": item.company_name,
                "schedule_date": commonDateFormat(item.scheduled_on),
                "schedule_by": item.coordinator ? item.coordinator.first_name : "",
                "rating": item.overall_rating,
                "verdit": getInterviewFinalStatus(item.final_status),
                "duration": item.interview_duration,
                "qc_status": getQCStatuslabel(item.qc_status),
                "qc_feedback": item.qc_remark,
                "location": item.job_location,
                "amount": item.interview_charge,
                "qc_deduction": 0,
                "final_amount": item.interview_charge
            };
            rowData.push(datum);
        });
        const rows = rowData.map((row) => Object.values(row).join(','));
        return `${header}\n${rows.join('\n')}`;
    };

    const compantConvertToCsv = (data) => {
        const header = "Candidate Name, Candidate Number, Candidate E-mail, Title, Company Name, Scheduled Date, Schedule By, Rating, Verdict, Location, Amount, Final amount, Interviewer Source";
        const rowData = [];
        data.map((item) => {
            const datum = {
                "candidate_name": item.candidate_name,
                "candidate_mobile_number": item.candidate_mobile_number,
                "interview_email": item.interview_email,
                "title": item.job_title,
                "company_name": item.company_name,
                "schedule_date": commonDateFormat(item.scheduled_on),
                "schedule_by": item.coordinator ? item.coordinator.first_name : "",
                "rating": item.overall_rating,
                "verdit": getInterviewFinalStatus(item.final_status),
                "location": item.job_location,
                "amount": item.interview_charge,
                "final_amount": item.interview_charge,
                "interviewer_source": "Rala Infotech"
            };
            rowData.push(datum);
        });
        const rows = rowData.map((row) => Object.values(row).join(','));
        return `${header}\n${rows.join('\n')}`;
    };

    const downloadReport = (userId, userName, type = 'download') => {
        var currentYear = "";
        var currentMonth = "";
        const filterUser = users.filter((item) => item.id === userId)
        if (filterUser && filterUser.length > 0) {
            currentYear = filterUser[0].year;
            currentMonth = filterUser[0].month;
            setIsLoading(true)
            let api = `${API_URL}api/getCompanyReport/${userId}?currentMonth=${currentMonth}&currentYear=${currentYear}&qcStatus=${QC_STATUS_PASS}`;
            if (selectedAccountType === INTERVIEWER) {
                api = `${API_URL}api/interviews/${userId}?currentMonth=${currentMonth}&currentYear=${currentYear}&qcStatus=${QC_STATUS_PASS}`;
            }
            fetch(api, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${userDetails.token}`,
                }
            })
                .then((response) => response.json())
                .then((response) => {
                    if (type === 'download') {
                        const reportData = response.data;
                        const csvContent = selectedAccountType === INTERVIEWER ? convertToCsv(reportData) : compantConvertToCsv(reportData);
                        const blob = new Blob([csvContent], { type: 'text/csv' });
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.download = `${userName}_${fileName()}.csv`;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        toast.current.show({ severity: 'success', summary: 'Success', detail: "Payment report downloaded successfully", life: 3000 });
                    } else if (type === 'view') {
                        const reportData = response.data;
                        setCsvContent(reportData);
                        if (selectedAccountType === INTERVIEWER) {
                            setShowInterviewReportModal(true);
                        } else {
                            setShowCompanyReportModal(true);
                        }
                    }
                    setIsLoading(false)
                })
                .catch((err) => {
                    setIsLoading(false)
                });
        }

    }

    const sendReport = (userId) => {
        let currentYear = "";
        let currentMonth = "";
        users.map((item) => {
            if (item.id === userId) {
                currentYear = item.year;
                currentMonth = item.month;
            }
        });
        setIsLoading(true)
        let apiUrl = `${API_URL}api/sendCompanyReport/${userId}?currentMonth=${currentMonth}&currentYear=${currentYear}&qcStatus=${QC_STATUS_PASS}`;
        if (selectedAccountType === INTERVIEWER) {
            apiUrl = `${API_URL}api/sendInterviewReport/${userId}?currentMonth=${currentMonth}&currentYear=${currentYear}&qcStatus=${QC_STATUS_PASS}`
        }
        fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userDetails.token}`,
            }
        })
            .then((response) => response.json())
            .then((response) => {
                setIsLoading(false)
                toast.current.show({ severity: 'success', summary: 'Success', detail: "Payment report sent successfully", life: 3000 });
            })
            .catch((err) => {
                setIsLoading(false)
            });
    }

    const sendBulkReport = async (userId) => {
        try {

            let apiUrl = `${API_URL}api/sendCompanyReport/${userId}`;
            if (selectedAccountType === INTERVIEWER) {
                apiUrl = `${API_URL}api/sendInterviewReport/${userId}`
            }

            await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${userDetails.token}`,
                }
            });
        } catch (error) {
        }
    };

    const sendAllReport = async () => {
        setIsLoading(true);
        const promises = users.map(async (item) => {
            if (selectedAccountType === INTERVIEWER) {
                if (item.user_status === USER_STATUS_APPROVE) {
                    await sendBulkReport(item.id);
                }
            } else {
                await sendBulkReport(item.id);
            }
        });
        await Promise.all(promises);
        setIsLoading(false);
        toast.current.show({ severity: 'success', summary: 'Success', detail: "Payment report sent successfully", life: 3000 });
    }

    const onSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = (key) => {
        if (sortConfig.key !== key) {
            return <FaSort className="cursor-pointer" />;
        }
        if (sortConfig.direction === 'desc') {
            return <FaSortUp className="cursor-pointer" />;
        }
        return <FaSortDown className="cursor-pointer" />;
    };

    const clearFilter = () => {
        setSelectedFields({
            primary_skill: [],
            secondary_skill: [],
            designations: [],
            months: [],
            years: []
        })
    }

    const setReportFilter = (name, value, userId) => {
        const updatedUsers = users.map((item) =>
            item.id === userId
                ? { ...item, [name]: value }
                : item
        );
        setUsers(updatedUsers)
    }

    const clearPaymentFilter = (userId) => {
        const updateUsers = users.map((item) => {
            return item.id === userId ? { ...item, month: (new Date().getMonth() + 1), year: new Date().getFullYear() } : { ...item }
        })
        setUsers(updateUsers);
    }

    const commonOnChange = (period, value) => {
        if (period === "month") {
            setCommonMonth(value);
        } else {
            setCommonYear(value);
        }
        const transformUserList = users.map((item) => {
            return { ...item, [period]: value };
        });
        setUsers(transformUserList)
    }

    const setAccountType = (account_type) => {
        resetPagination()
        setSelectedAccountType(account_type)
    }

    return (
        <Fragment>
            <Toast ref={toast} />
            {
                isLoading &&
                <div className="spinner-loader">
                    <Loader type="spinner-circle" bgColor="#2F2F74" color="#2F2F74" size={100} />
                </div>
            }
            <div className="container-fluid employer-dashboard-container fixed-padding payment_mob">
                <div className="row">
                    <div className="col-12 px-0">
                        <HeaderScreen />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 employer-dashboard-title ">
                        <div className="row mb-3 mb-md-0 justify-content-md-between align-items-center pageTitle_fixed">
                            <div className="col-12 col-md-12 col-lg-3 col-xl-3">
                                <p className="employer-dashboard-jobs-posted mb-0 p-0" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span className='inner_title d-flex align-items-center gap-2'><BsCash size={18} />PAYMENT</span>
                                </p>
                            </div>
                            <div className='col-12 col-md-12 col-lg-6 col-xl-5'>
                                <div className='row align-items-center'>
                                    <div className='col-12 col-md-6 col-lg-6 text-end mb-2 mb-md-2 mb-lg-0'>
                                        <button className='btn btn-primary w-100' onClick={() => sendAllReport()}>
                                            Send Payment Report
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip>Send All</Tooltip>}
                                            >
                                                <BsFillSendFill className='ms-2' />
                                            </OverlayTrigger>
                                        </button>
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-6 mb-2 mb-md-2 mb-lg-0 d-flex'>
                                        <input type="text" className='form-control' value={searchText} placeholder="Search" style={{ paddingLeft: '12px' }} onChange={(e) => {
                                            setSearchText(e.target.value.trim());
                                        }} />
                                        &nbsp;
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip>Common filters</Tooltip>}
                                        >
                                            <DropdownButton id="common-filter" title={<CiFilter style={{ width: '25px', height: '25px' }} />} style={{ backgroundImage: 'none', fontSize: '0px' }} >

                                                <div className="px-2 pb-2 pt-3" style={{ width: '250px' }}>
                                                    <Form.Select value={commonYear} aria-label="Default select example" onChange={(e) => {
                                                        const value = e.target.value;
                                                        commonOnChange("year", value);
                                                    }}>
                                                        {yearsList.map((item, index) => (
                                                            <option value={item} className="filter-item">{item}</option>
                                                        ))}
                                                    </Form.Select>
                                                </div>

                                                <div className="px-2 pb-2 pt-3" style={{ width: '250px' }}>
                                                    <Form.Select value={commonMonth} aria-label="Default select example" onChange={(e) => {
                                                        const value = e.target.value;
                                                        commonOnChange("month", value);
                                                    }}>
                                                        {months.map((item, index) => (
                                                            <option value={item.id} className="filter-item">{item.name}</option>
                                                        ))}
                                                    </Form.Select>
                                                </div>

                                                <div className="mt-2 mb-3" style={{ textAlign: 'center' }}>
                                                    <Button onClick={() => clearPaymentFilter()} style={{
                                                        marginTop: '0px',
                                                        background: '#FFFBFE',
                                                        color: 'black',
                                                        border: 'none'
                                                    }} className="employer-dashboard-interview-join">
                                                        Reset
                                                    </Button>
                                                </div>

                                            </DropdownButton>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Tab.Container defaultActiveKey="interviewer" onSelect={() => {
                            setSearchText("")
                        }}>
                            <div className="row mb-2">
                                <div className="col-12">
                                    <Nav fill variant="tabs">
                                        <Nav.Item>
                                            <Nav.Link eventKey="interviewer" onClick={() => {
                                                clearFilter();
                                                setAccountType(INTERVIEWER)
                                            }}>Interviewer</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="company" onClick={() => {
                                                clearFilter();
                                                setAccountType(EMPLOYER_SUPER_ADMIN);
                                            }}>Company</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <Tab.Content>
                                        <Card>
                                            <Card.Body>
                                                <div className="row">
                                                    {
                                                        selectedAccountType === INTERVIEWER && (
                                                            <div className="col-1 report-filter-section" style={{ width: '50px' }}>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={<Tooltip>Filters</Tooltip>}
                                                                >
                                                                    <DropdownButton id="report-filter" title={<CiFilter style={{ width: '25px', height: '25px' }} />} style={{ backgroundImage: 'none' }} >

                                                                        <DropdownButton className="nested-button" title={
                                                                            "Primary Skills" + (selectedFields.primary_skill.length > 0 ? ` (${selectedFields.primary_skill.length})` : "")
                                                                        }>
                                                                            {skillsOptions &&
                                                                                skillsOptions.map((item, index) => (
                                                                                    <InputGroup className="flex px-2" style={{ justifyContent: 'space-between' }} key={item._id}>
                                                                                        <span title={item.name} className="filter-item">{item.name}</span>
                                                                                        <InputGroup.Checkbox
                                                                                            key={"primary_skill_" + index}
                                                                                            aria-label="Checkbox for following text input"
                                                                                            onChange={() => handleCheckboxChange('primary_skill', item.name)}
                                                                                            checked={selectedFields.primary_skill.includes(item.name)}
                                                                                        />
                                                                                    </InputGroup>
                                                                                ))}
                                                                        </DropdownButton>

                                                                        <DropdownButton className="nested-button" title={
                                                                            "Secondary Skills" + (selectedFields.secondary_skill.length > 0 ? ` (${selectedFields.secondary_skill.length})` : "")
                                                                        }>
                                                                            {skillsOptions &&
                                                                                skillsOptions.map((item, index) => (
                                                                                    <InputGroup className="flex px-2" style={{ justifyContent: 'space-between' }} key={item._id}>
                                                                                        <span title={item.name} className="filter-item">{item.name}</span>
                                                                                        <InputGroup.Checkbox
                                                                                            key={"secondary_skill_" + index}
                                                                                            aria-label="Checkbox for following text input"
                                                                                            onChange={() => handleCheckboxChange('secondary_skill', item.name)}
                                                                                            checked={selectedFields.secondary_skill.includes(item.name)}
                                                                                        />
                                                                                    </InputGroup>
                                                                                ))}
                                                                        </DropdownButton>

                                                                        <DropdownButton className="nested-button" title={
                                                                            "Designation" + (selectedFields.designations.length > 0 ? ` (${selectedFields.designations.length})` : "")
                                                                        }>
                                                                            {designationOptions &&
                                                                                designationOptions.map((item, index) => (
                                                                                    <InputGroup className="flex px-2" style={{ justifyContent: 'space-between' }} key={item._id}>
                                                                                        <span title={item.name} className="filter-item">{item.name}</span>
                                                                                        <InputGroup.Checkbox
                                                                                            key={"designation_" + index}
                                                                                            aria-label="Checkbox for following text input"
                                                                                            onChange={() => handleCheckboxChange('designations', item.name)}
                                                                                            checked={selectedFields.designations.includes(item.name)}
                                                                                        />
                                                                                    </InputGroup>
                                                                                ))}
                                                                        </DropdownButton>

                                                                        <DropdownButton className="nested-button" title={
                                                                            "Months" + (selectedFields.months.length > 0 ? ` (${selectedFields.months.length})` : "")
                                                                        }>
                                                                            {months && months.map((item, index) => (
                                                                                <InputGroup className="flex px-2" style={{ justifyContent: 'space-between' }} key={item.id}>
                                                                                    <span title={item.name} className="filter-item">{item.name}</span>
                                                                                    <InputGroup.Checkbox
                                                                                        key={"month_" + index}
                                                                                        aria-label="Checkbox for following text input"
                                                                                        onChange={() => handleCheckboxChange('months', item.id)}
                                                                                        checked={selectedFields.months.includes(item.id)}
                                                                                    />
                                                                                </InputGroup>
                                                                            ))}
                                                                        </DropdownButton>

                                                                        <DropdownButton className="nested-button" title={
                                                                            "Years" + (selectedFields.years.length > 0 ? ` (${selectedFields.years.length})` : "")
                                                                        }>
                                                                            {yearsList && yearsList.map((item, index) => (
                                                                                <InputGroup className="flex px-2" style={{ justifyContent: 'space-between' }} key={item}>
                                                                                    <span title={item} className="filter-item">{item}</span>
                                                                                    <InputGroup.Checkbox
                                                                                        key={"year_" + index}
                                                                                        aria-label="Checkbox for following text input"
                                                                                        onChange={() => handleCheckboxChange('years', item)}
                                                                                        checked={selectedFields.years.includes(item)}
                                                                                    />
                                                                                </InputGroup>
                                                                            ))}
                                                                        </DropdownButton>

                                                                        <div className="my-2" style={{ textAlign: 'center' }}>
                                                                            <Button onClick={clearFilter} style={{
                                                                                marginTop: '0px',
                                                                                background: '#FFFBFE',
                                                                                color: 'black',
                                                                                border: 'none'
                                                                            }} className="employer-dashboard-interview-join">
                                                                                Reset
                                                                            </Button>
                                                                        </div>
                                                                    </DropdownButton>
                                                                </OverlayTrigger>
                                                            </div>
                                                        )
                                                    }
                                                    <div className={selectedAccountType === INTERVIEWER ? 'col-11 payment_rep' : 'col-12 payment_rep'} style={{ flex: 1 }}>
                                                        <Table bordered striped hover size="lg" responsive className="request-interviewer-users-feednback-completed">
                                                            <thead className='sticky-top'>
                                                                <tr>
                                                                    <th className='text-start' onClick={() => onSort('id')}>Registration ID {getSortIcon('id')}</th>
                                                                    <th className='text-start' onClick={() => onSort('first_name')}>Name {getSortIcon('name')}</th>
                                                                    {
                                                                        selectedAccountType === INTERVIEWER && (
                                                                            <th className='text-start' onClick={() => onSort('resume')}>Resume {getSortIcon('resume')}</th>
                                                                        )
                                                                    }
                                                                    <th className='text-start' onClick={() => onSort('email')}>Email ID {getSortIcon('email')}</th>
                                                                    <th className='text-start' onClick={() => onSort('mobile_number')}>Mobile Number {getSortIcon('mobile_number')}</th>
                                                                    {
                                                                        selectedAccountType === INTERVIEWER && (
                                                                            <>
                                                                                <th className='text-start' style={{ minWidth: '450px' }} onClick={() => onSort('primary_skill')}>Primary Skills {getSortIcon('primary_skill')}</th>
                                                                                <th className='text-start' style={{ minWidth: '450px' }} onClick={() => onSort('secondary_skill')}>Secondary Skills {getSortIcon('secondary_skill')}</th>
                                                                                <th className='text-start' onClick={() => onSort('designation')}>Designation {getSortIcon('designation')}</th>
                                                                            </>
                                                                        )
                                                                    }
                                                                    <th className='text-start' onClick={() => onSort('createdAt')}>Registered Date {getSortIcon('createdAt')}</th>
                                                                    <th className='text-start'>Payment</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    !_.isUndefined(users) && !_.isNull(users) &&
                                                                    _.map(users, (user, index) => {
                                                                        return <tr key={"feednback-completed-" + index}>
                                                                            <td>{user.id}</td>
                                                                            <td className='fw-bold'><span>
                                                                                {
                                                                                    selectedAccountType === EMPLOYER_SUPER_ADMIN ? user.company_name : `${user.first_name} ${user.last_name}`
                                                                                }
                                                                            </span></td>
                                                                            {
                                                                                selectedAccountType === INTERVIEWER && (
                                                                                    <td style={{ textAlign: 'center' }}>
                                                                                        <OverlayTrigger
                                                                                            placement="top"
                                                                                            overlay={<Tooltip>Interviewer Resume</Tooltip>}
                                                                                        >
                                                                                            <a style={{ color: 'black' }} className="cursor-pointer" onClick={() => { setSelectedItem(user); setIsShowResumeDialog(true); }}>
                                                                                                <FaFileAlt />
                                                                                            </a>
                                                                                        </OverlayTrigger>
                                                                                    </td>
                                                                                )
                                                                            }
                                                                            <td>{user.email}</td>
                                                                            <td>{user.mobile_number}</td>
                                                                            {
                                                                                selectedAccountType === INTERVIEWER && (
                                                                                    <>
                                                                                        <td>{user.primary_skill.join(", ")}</td>
                                                                                        <td>{user.secondary_skill.join(", ")}</td>
                                                                                        <td>{user.designation}</td>
                                                                                    </>
                                                                                )
                                                                            }
                                                                            <td>{commonDateFormat(user.createdAt)}</td>
                                                                            <td style={{ display: 'flex' }}>
                                                                                <div className='input_grp_buttons' style={{ width: '380px' }}>
                                                                                    <Container>
                                                                                        <Row>
                                                                                            <Col className='btn btn-outline-primary' onClick={() => downloadReport(user.id, `${user.first_name} ${user.last_name}`, 'view')}  >
                                                                                                <OverlayTrigger
                                                                                                    placement="top"
                                                                                                    overlay={<Tooltip>See</Tooltip>}
                                                                                                >
                                                                                                    <div ><FaEye className="cursor-pointer" /></div>
                                                                                                </OverlayTrigger>
                                                                                            </Col>
                                                                                            <Col className='btn btn-outline-primary' onClick={() => downloadReport(user.id, `${user.first_name} ${user.last_name}`, 'download')}  >
                                                                                                <OverlayTrigger
                                                                                                    placement="top"
                                                                                                    overlay={<Tooltip>Download</Tooltip>}
                                                                                                >
                                                                                                    <div ><FaDownload className="cursor-pointer" /></div>
                                                                                                </OverlayTrigger>
                                                                                            </Col>
                                                                                            <Col className='btn btn-outline-primary' onClick={() => sendReport(user.id)}  >
                                                                                                <OverlayTrigger
                                                                                                    placement="top"
                                                                                                    overlay={<Tooltip>Send</Tooltip>}
                                                                                                >
                                                                                                    <div ><BsFillSendFill className="cursor-pointer" /></div>
                                                                                                </OverlayTrigger>
                                                                                            </Col>
                                                                                            <Col className='btn btn-outline-primary' >
                                                                                                <div style={{ textWrap: 'nowrap' }} >Payment Report</div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Container>
                                                                                </div>
                                                                                &nbsp;&nbsp;
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={<Tooltip>Filters</Tooltip>}
                                                                                >
                                                                                    <DropdownButton id="report-filter" title={<CiFilter style={{ width: '25px', height: '25px' }} />} style={{ backgroundImage: 'none' }} >
                                                                                        <div className="px-2 pb-2 pt-3" style={{ width: '250px' }}>
                                                                                            <Form.Select value={user.year} aria-label="Default select example" onChange={(e) => {
                                                                                                const value = e.target.value;
                                                                                                setReportFilter("year", value, user.id)
                                                                                            }}>
                                                                                                {yearsList.map((item, index) => (
                                                                                                    <option value={item} className="filter-item">{item}</option>
                                                                                                ))}
                                                                                            </Form.Select>
                                                                                        </div>

                                                                                        <div className="px-2 pb-2 pt-3" style={{ width: '250px' }}>
                                                                                            <Form.Select value={user.month} aria-label="Default select example" onChange={(e) => {
                                                                                                const value = e.target.value;
                                                                                                setReportFilter("month", value, user.id)
                                                                                            }}>
                                                                                                {months.map((item, index) => (
                                                                                                    <option value={item.id} className="filter-item">
                                                                                                        {item.name}
                                                                                                    </option>
                                                                                                ))}
                                                                                            </Form.Select>
                                                                                        </div>

                                                                                        <div className="mt-2 mb-3" style={{ textAlign: 'center' }}>
                                                                                            <Button onClick={() => clearPaymentFilter(user.id)} style={{
                                                                                                marginTop: '0px',
                                                                                                background: '#FFFBFE',
                                                                                                color: 'black',
                                                                                                border: 'none'
                                                                                            }} className="employer-dashboard-interview-join">
                                                                                                Reset
                                                                                            </Button>
                                                                                        </div>

                                                                                    </DropdownButton>
                                                                                </OverlayTrigger>

                                                                            </td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </div>
                                                {
                                                    totalCount > 0 && (
                                                        <CustomPaginationComponent ref={paingationRef} total={totalCount} updatePagination={updatePagination} />
                                                    )
                                                }
                                            </Card.Body>
                                        </Card>
                                    </Tab.Content>
                                </div>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
            </div>

            <Modal show={showInterviewReportModal} onHide={() => setShowInterviewReportModal(false)} dialogClassName="modal-90w">
                <Modal.Header closeButton>
                    <Modal.Title>Payment Reports</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table bordered striped hover size="lg" responsive className="request-interviewer-users-feednback-completed">
                        <thead className='sticky-top'>
                            <tr>
                                <th>Candidate Name</th>
                                <th>Candidate E-mail</th>
                                <th>Title</th>
                                <th>Company Name</th>
                                <th>Schedule Date</th>
                                <th>Scheduled By</th>
                                <th>Rating</th>
                                <th>Verdit</th>
                                <th>Duration</th>
                                <th>QC Status</th>
                                <th>QC Feedback</th>
                                <th>Location</th>
                                <th>Amount</th>
                                <th>QC Deduction</th>
                                <th>Final Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                csvContent && csvContent.length > 0 && (
                                    csvContent.map((item) => {
                                        return (<tr key={'payment_' + item._id}>
                                            <td className='fw-bold'>{item.candidate_name}</td>
                                            <td>{item.candidate_email}</td>
                                            <td>{item.job_title}</td>
                                            <td>{item.company_name}</td>
                                            <td>{commonDateFormat(item.scheduled_on)}</td>
                                            <td>{item.coordinator ? item.coordinator.first_name : ""}</td>
                                            <td>{item.overall_rating}</td>
                                            <td>{getInterviewFinalStatus(item.final_status)}</td>
                                            <td>{item.interview_duration}</td>
                                            <td>{getQCStatuslabel(item.qc_status)}</td>
                                            <td>{item.qc_remark}</td>
                                            <td>{item.job_location}</td>
                                            <td>{item.interview_charge}</td>
                                            <td>0</td>
                                            <td>{item.interview_charge}</td>
                                        </tr>);
                                    })
                                )
                            }
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShowInterviewReportModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showCompanyReportModal} onHide={() => setShowCompanyReportModal(false)} dialogClassName="modal-90w">
                <Modal.Header closeButton>
                    <Modal.Title>Payment Reports</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table bordered striped hover size="lg" responsive className="request-interviewer-users-feednback-completed">
                        <thead className='sticky-top'>
                            <tr>
                                <th>Candidate Name</th>
                                <th>Candidate Number</th>
                                <th>Candidate E-mail</th>
                                <th>Title</th>
                                <th>Company Name</th>
                                <th>Schedule Date</th>
                                <th>Scheduled By</th>
                                <th>Rating</th>
                                <th>Verdit</th>
                                <th>Location</th>
                                <th>Amount</th>
                                <th>Final Amount</th>
                                <th>Interviewer Source</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                csvContent && csvContent.length > 0 && (
                                    csvContent.map((item) => {
                                        return (<tr key={'candidate_' + item._id}>
                                            <td className='fw-bold'>{item.candidate_name}</td>
                                            <td>{item.candidate_mobile_number}</td>
                                            <td>{item.candidate_email}</td>
                                            <td>{item.job_title}</td>
                                            <td>{item.company_name}</td>
                                            <td>{commonDateFormat(item.scheduled_on)}</td>
                                            <td>{item.coordinator ? item.coordinator.first_name : ""}</td>
                                            <td>{item.overall_rating}</td>
                                            <td>{getInterviewFinalStatus(item.final_status)}</td>
                                            <td>{item.job_location}</td>
                                            <td>{item.interview_charge}</td>
                                            <td>0</td>
                                            <td>Rala Infotech</td>
                                        </tr>);
                                    })
                                )
                            }
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShowCompanyReportModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>

            <Dialog header="Candidate Resume" visible={isShowResumeDialog} style={{ width: '50vw', height: '80vh' }} onHide={() => setIsShowResumeDialog(false)} dismissableMask="true">
                {
                    selectedItem && selectedItem.resume &&
                    (
                        selectedItem.resume.includes('.doc') ?
                            <iframe title="resume" key="iframe-doc" style={{
                                width: '100%',
                                height: '100%'
                            }} src={`https://docs.google.com/gview?url=${selectedItem.resume}&embedded=true`}></iframe> :
                            <object title="resume" key="object-other" data={selectedItem.resume} style={{
                                width: '100%',
                                height: '100%'
                            }}></object>
                    )
                }
            </Dialog>
        </Fragment>
    )
}
export default PaymentScreen;