import html2pdf from 'html2pdf.js';
import JSZip from 'jszip';
import _ from 'lodash';
import moment from 'moment';
import { Toast } from 'primereact/toast';
import React, { Fragment, forwardRef, useEffect, useRef, useState } from "react";
import { Alert, Badge, Button, Card, Col, Container, DropdownButton, FloatingLabel, Form, InputGroup, ListGroup, Modal, Nav, OverlayTrigger, Row, Tab, Table, Tooltip } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BiMessageRounded, BiSolidBookAdd, BiSolidVideos } from "react-icons/bi";
import { CiFilter } from "react-icons/ci";
import { FaCheck, FaFileExcel } from "react-icons/fa";
import { BiSolidReport } from "react-icons/bi";
import { FaDownload, FaFileAlt, FaHistory, FaMailBulk, FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import Loader from "react-js-loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { API_URL, INTERVIEW_FINAL_STATUS_REJECTED, INTERVIEW_FINAL_STATUS_SELECTED, ITEMS_PER_PAGE, PROXY, QC_STATUS_FAIL, QC_STATUS_PASS, commonDateFormat, dayDifference, fileName, generateContent, getAuthUserFromSessionStorage, getInterviewFinalStatus, getQCStatuslabel, getincompleteInterview } from "../../common/constant";
import { EMPLOYER_SUB_ADMIN, EMPLOYER_SUPER_ADMIN, SUB_ADMIN, SUPER_ADMIN } from "../../common/roles";
import HeaderScreen from "../HeaderScreen/HeaderScreen";
import "./ReportScreen.css";
import CustomPaginationComponent from '../../common/CustomPaginationComponent/CustomPaginationComponent';

const ReportScreen = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [userDetails, setUserType] = useState(getAuthUserFromSessionStorage());
    const [candidates, setCandidates] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [navType, setNavType] = useState("Pending");
    const { id: jobId } = useParams();
    const [breadJobTitle, setBreadJobTitle] = useState("");
    const [selectedItems, setSelectedItems] = useState([]);

    const paingationRef = useRef();

    const [isShowQCRemark, setIsShowQCRemark] = useState(false)
    const [updatedInterviewId, setUpdatedInterviewId] = useState("");
    const [interviewerBody, setInterviewerBody] = useState({
        qc_status: "",
        qc_remark: "",
        record_editable_till: ""
    });

    const [interviewIdForQcRemark, setInterviewIdForQcRemark] = useState("");
    const [qcRemark, setQcRemark] = useState("");

    const [isShowHistoryModel, setIsShowHistoryModel] = useState(false)
    const [candidateData, setCandidateData] = useState(null)
    const [addedAttempts, setAddedAttempts] = useState([]);
    const toast = useRef(null);


    const [buCodes, setBuCodes] = useState([]);
    const [jobIds, setJobIds] = useState([]);
    const [jobTitles, setJobTitles] = useState([]);
    const [recruiter, setRecruiter] = useState([]);
    const [inititalRecruiter, setInititalRecruiter] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [selectedFields, setSelectedFields] = useState({
        companyIds: [],
        subAdminIds: [],
        jobTitles: [],
        jobIds: [],
        ratings: [],
        statuses: [],
        buCodes: [],
    });

    const [editedId, setEditedId] = useState("");

    const [reportPageNumber, setReportPageNumber] = useState(1);
    const [reportPageSize, setReportPageSize] = useState(ITEMS_PER_PAGE);

    const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'desc' });

    const updateReportPagination = (pageNumber, pageSize = ITEMS_PER_PAGE) => {
        setReportPageNumber(pageNumber)
        setReportPageSize(pageSize);
    }

    useEffect(() => {
        if (!_.isUndefined(candidates) && !_.isNull(candidates) && (candidates.length === 0)) {
            getCandidatesByJob();
        }
        if (!_.isUndefined(companyList) && !_.isNull(companyList) && (companyList.length === 0)) {
            getCompanyList();
        }
        if (!_.isUndefined(recruiter) && !_.isNull(recruiter) && (recruiter.length === 0)) {
            getRalaAdminList();
        }
    }, []);

    useEffect(() => {
        getCandidatesByJob();
    }, [reportPageNumber, reportPageSize, selectedFields, searchText, navType, sortConfig])

    useEffect(() => {
        if (updatedInterviewId !== "") {
            updateCandidateProfile(updatedInterviewId, interviewerBody);
        }
    }, [updatedInterviewId])

    const getRalaAdminList = () => {
        const requestBody = {
            account_type: SUB_ADMIN
        }
        const apiUrl = `${API_URL}api/getUsers`;
        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify(requestBody)
        })
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && (response.data.length > 0)) {
                    setRecruiter(response.data);
                    setInititalRecruiter(response.data);
                }
            })
            .catch((err) => {
                setRecruiter([]);
            });
    }

    const getCandidatesByJob = () => {
        let endDate = toDate;
        if (endDate) {
            endDate = new Date(endDate);
            endDate.setHours(23, 59, 59, 999);
        }
        let requestBody = {
            ...selectedFields,
            startDate: fromDate,
            endDate: endDate,
            search: searchText
        }
        if (jobId !== "" && jobId !== undefined && jobId !== null) {
            requestBody = {
                ...requestBody,
                is_sent_to_employeer: navType !== 'Pending',
                jobIds: [jobId]
            }
        }
        setIsLoading(true)
        const apiUrl = `${API_URL}api/getCandidatesByJob?page=${reportPageNumber}&limit=${reportPageSize}&sortBy=${sortConfig.key}&sortOrder=${sortConfig.direction}`;
        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify(requestBody)
        }
        )
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data)) {
                    setBuCodes(response.jobBuCodes);
                    setJobIds(response.jobIds)
                    setJobTitles(response.jobTitles)
                    setCandidates(response.data);
                    setTotalCount(response.totalCount);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                setCandidates([]);
                setIsLoading(false)
            });


    }

    const getCompanyList = () => {
        let requestBody = {
            account_type: EMPLOYER_SUPER_ADMIN
        }
        if (userDetails && userDetails.account_type === SUB_ADMIN) {
            requestBody = {
                ...requestBody,
                company_id: userDetails.id
            }
        }
        const apiUrl = `${API_URL}api/getUsers`;
        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify(requestBody)
        })
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && (response.data.length > 0)) {
                    setCompanyList(response.data);
                }
            })
            .catch((err) => {
                setCompanyList([]);
            });
    }

    const downloadCSV = () => {
        if (candidates.length > 0) {
            const csvContent = convertToCsv(candidates);
            const blob = new Blob([csvContent], { type: 'text/csv' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName() + '.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            toast.current.show({ severity: 'success', summary: 'Success', detail: "Feedback downloaded successfully", life: 3000 });
        } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Currently, there are no records available for download.', life: 3000 });
        }
    };

    async function imageToBase64(url) {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            return new Promise((resolve, reject) => {
                reader.onloadend = () => {
                    resolve(reader.result);
                };
                reader.onerror = reject;
            });
        } catch (error) {
            console.error('Error converting image to base64:', error);
            return null;
        }
    }

    const getInterviewDetails = async (id) => {
        try {
            const response = await fetch(`${API_URL}api/getInterview/${id}`, {
                headers: {
                    'Authorization': `Bearer ${userDetails.token}`,
                }
            });

            const responseData = await response.json();
            const interviewData = responseData.data;
            var averagePrimarySkillsRating = 0;
            interviewData.primary_skill_feedback.map((item) => {
                averagePrimarySkillsRating += Number(item.rating);
            });
            interviewData.averagePrimarySkillsRating = Math.floor(averagePrimarySkillsRating / interviewData.primary_skill_feedback.length);
            var averageSecondarySkillsRating = 0;
            interviewData && interviewData.secondary_skill_feedback.map((item) => {
                averageSecondarySkillsRating += Number(item.rating);
            });
            interviewData.averageSecondarySkillsRating = Math.floor(averageSecondarySkillsRating / interviewData.primary_skill_feedback.length);

            const uploadedScreenshots = [];
            for (var j = 0; j < interviewData.code_of_screenshot.length; j++) {
                const base64 = await imageToBase64(interviewData.code_of_screenshot[j]);
                uploadedScreenshots.push(base64);
            }

            interviewData.uploadedScreenshots = uploadedScreenshots;

            return interviewData;
        } catch (error) {
            console.log("error", error)
            return null;
        }
    }

    const downloadZIP = async () => {
        setIsLoading(true);
        const contents = [];
        for (const item of candidates) {
            if (item.is_feedback_given) {
                if (item.feedback_sheet === "") {
                    const interviewDetail = await getInterviewDetails(item._id);
                    if (interviewDetail) {
                        const htmlContent = generateContent(interviewDetail);
                        contents.push({ candidateId: item._id, type: 'html', content: htmlContent });
                    }
                } else {
                    contents.push({ candidateId: item._id, type: 'file', content: item.feedback_sheet, fileName: `file_${item._id}` });
                }
            }
        }

        if (contents.length > 0) {
            const zip = new JSZip();
            await Promise.all(contents.map(async (item, index) => {
                if (item.type === 'html') {
                    const element = document.createElement('div');
                    element.innerHTML = item.content;
                    // Improved PDF generation with higher quality and pagebreak handling
                    const opt = {
                        margin: [1, 0.5, 1, 0.5], // Top, Right, Bottom, Left margins
                        filename: `file_${index + 1}.pdf`,
                        image: { type: 'jpeg', quality: 0.98 },
                        html2canvas: { scale: 2 },
                        jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
                        pagebreak: { mode: ['css', 'legacy'] }
                    };
                    const pdfBlob = await html2pdf().set(opt).from(element).toPdf().output('blob');
                    zip.file(`pdf_${item.candidateId}.pdf`, pdfBlob);
                } else {
                    const response = await fetch(item.content); // Assuming item.content is the URL of the file
                    const fileBlob = await response.blob();
                    const fileExtension = item.content.split('.').pop(); // Get file extension from URL
                    zip.file(`${item.fileName}.${fileExtension}`, fileBlob);
                }
            }));

            zip.generateAsync({ type: 'blob' }).then((content) => {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(content);
                link.download = 'files.zip';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });

            setIsLoading(false);
            toast.current.show({ severity: 'success', summary: 'Success', detail: "Feedback downloaded successfully", life: 3000 });
        } else {
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Currently, there are no records available for download.', life: 3000 });
        }
    }

    const reportData = async () => {
        selectedItems.length = 0;
        if (candidates && candidates.length > 0) {
            setIsLoading(true)
            const csvContent = convertToCsv(candidates);
            const blob = new Blob([csvContent], { type: 'text/csv' });
            const formData = new FormData();
            formData.append('file', blob, fileName() + '.csv');
            const response = await fetch(`${API_URL}api/uploadFiles`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: formData
            });
            const responseData = await response.json();
            setIsLoading(false)
        } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Currently, there are no records to send report.', life: 3000 });
        }
    }

    const convertToCsv = (data) => {
        const header = "Job ID,Candidate ID,Candidate Name,Email ID,Mobile Number,Job Title,Status,Incomplete Interview,Rating,Scheduler Name,Scheduler Number,Interviewer Name,Interviewer Number,BU Code,Profile Uploaded,Scheduled on,Video,QC Status,QC Remark,Follow Up Attempts,History"
        const rows = data.map((row) => {
            const followUpData = row.follow_up_attempts.map((item) => {
                return `${commonDateFormat(item.follow_up_date)} ${item.follow_up_reason.replace(",", "")} ${item.upload_by.replace(",", "")}`;
            }).join('        ');
            return [
                row.job_id,
                row._id,
                row.candidate_name,
                row.candidate_email,
                row.candidate_mobile_number,
                row.job_title,
                getInterviewFinalStatus(row.final_status),
                getincompleteInterview(row.incomplete_interview),
                row.overall_rating,
                row.schedulerUserInfo ? `${row.schedulerUserInfo.first_name} ${row.schedulerUserInfo.last_name}` : "",
                row.schedulerUserInfo ? row.schedulerUserInfo.mobile_number : "",
                row.interviewerInfo ? row.interviewerInfo.first_name : "",
                row.interviewerInfo ? row.interviewerInfo.mobile_number : "",
                row.bu_code,
                commonDateFormat(row.createdAt),
                row.scheduled_on ? commonDateFormat(row.scheduled_on) : "",
                row.video,
                getQCStatuslabel(row.qc_status),
                row.qc_remark.replace(",", ""),
                row.follow_up_attempts.length,
                followUpData
            ];
        });
        const csvContent = `${header}\n${rows.map(row => row.join(',')).join('\n')}`;

        return csvContent;
    };

    const qcStatus = (rowData) => {
        return ((userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN) && editedId === rowData._id) ? (
            <Form.Select style={{ width: '100px' }}
                onChange={(e) => {
                    const value = e.target.value;
                    const a = candidates.map((interviewer) =>
                        interviewer._id === rowData._id
                            ? { ...interviewer, qc_status: value ? QC_STATUS_PASS : QC_STATUS_FAIL }
                            : interviewer
                    );
                    setCandidates(a)
                    setInterviewerBody({
                        ...interviewerBody,
                        qc_status: value,
                        qc_remark: rowData.qc_remark
                    })
                    if (value === QC_STATUS_FAIL) {
                        setInterviewIdForQcRemark(rowData._id);
                        setIsShowQCRemark(true);
                    } else {
                        setUpdatedInterviewId(rowData._id);
                    }
                }}
            >
                <option value="">Actions</option>
                <option style={{ color: 'green' }} selected={rowData.qc_status === QC_STATUS_PASS} value={QC_STATUS_PASS}>Pass</option>
                <option style={{ color: 'red' }} selected={rowData.qc_status === QC_STATUS_FAIL} value={QC_STATUS_FAIL}>Fail</option>
            </Form.Select>
        ) : (
            <div style={{ color: rowData.qc_status === QC_STATUS_PASS ? 'green' : (rowData.qc_status === QC_STATUS_FAIL ? 'red' : '') }}>{getQCStatuslabel(rowData.qc_status)}</div>
        )
    }

    const calRef = React.useRef();
    const [originalRecordEditableTill, setOriginalRecordEditableTill] = useState(null);

    const canEditStatus = (rowData) => {
        // if (rowData.record_editable_till) {
        if (editedId === rowData._id) {
            return <>
                <DatePicker
                    ref={calRef}
                    className="form-select w-100 record_editable_till_datepicker"
                    selected={rowData.record_editable_till ? new Date(rowData.record_editable_till) : new Date()}
                    onChange={(e) => {
                        const value = new Date(e).toISOString();
                        const a = candidates.map((interviewer) =>
                            interviewer._id === rowData._id
                                ? { ...interviewer, record_editable_till: value }
                                : interviewer
                        );
                        setCandidates(a)
                        setInterviewerBody({
                            ...interviewerBody,
                            record_editable_till: value
                        })
                    }}
                    showTimeSelect
                    timeFormat="h:mm aa"
                    timeIntervals={60}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    timeCaption="Time"
                    // minDate={new Date(rowData.record_editable_till)}
                    showDisabledMonthNavigation
                    placeholderText="Edit allowed until..."
                    excludeTimes={[0, 1, 2, 3, 4]}
                    shouldCloseOnSelect={false}
                >
                    <div>
                        <Button onClick={() => {
                            calRef.current.setOpen(false);
                            setUpdatedInterviewId(rowData._id)
                        }}>Apply</Button> &nbsp;
                        <Button onClick={() => {
                            const value = new Date(originalRecordEditableTill).toISOString();
                            const a = candidates.map((interviewer) =>
                                interviewer._id === rowData._id
                                    ? { ...interviewer, record_editable_till: value }
                                    : interviewer
                            );
                            setCandidates(a)
                            setInterviewerBody({
                                ...interviewerBody,
                                record_editable_till: value
                            })
                            setOriginalRecordEditableTill(null);
                            setEditedId("");
                            calRef.current.setOpen(false);
                        }}>Cancel</Button>
                    </div>
                </DatePicker>
            </>
        } else {
            return rowData.record_editable_till ? commonDateFormat(rowData.record_editable_till) : '';
        }
    }

    const updateCandidateProfile = (candidateId, body, isCallApi = true, message = "") => {
        fetch(`${API_URL}api/updateCandidateProfileData/${candidateId}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify(body)
        })
            .then((response) => response.json())
            .then((response) => {
                if (isCallApi) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: message ? message : "Report updated successfully", life: 3000 });
                    getCandidatesByJob()
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            })
            .finally(() => {
                setUpdatedInterviewId("");
                setEditedId("");
            });
    }

    const sentInterviewtoEmployeer = async (candidateId, body) => {
        try {
            const response = await fetch(`${API_URL}api/updateCandidateProfileData/${candidateId}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(body)
            });
            const responseData = await response.json();
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
        }
    };

    const location = useLocation();
    const navigate = useNavigate();

    const sentToEmployeer = async () => {
        if (selectedItems.length > 0) {
            setIsLoading(true)
            for (const item of selectedItems) {
                await sentInterviewtoEmployeer(item, { is_sent_to_employeer: true }, false);
            }
            setIsLoading(false)
            toast.current.show({ severity: 'success', summary: 'Success', detail: "Feedback sent successfully", life: 3000 });
            navigate(location.pathname, { replace: true });
            getCandidatesByJob();
            setSelectedItems([]);
        } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Currently, there are no records to send report.', life: 3000 });
        }
    }

    const handleCheckboxChange = (fieldType, value) => {
        setSelectedFields((prevSelectedFields) => {
            const isSelected = prevSelectedFields[fieldType].includes(value);
            let updatedFields = {
                ...prevSelectedFields,
                [fieldType]: isSelected
                    ? prevSelectedFields[fieldType].filter((item) => item !== value)
                    : [...prevSelectedFields[fieldType], value],
            };
            if (fieldType === "companyIds") {
                updatedFields['subAdminIds'] = [];
                if (updatedFields.companyIds.length === 0) {
                    setRecruiter(inititalRecruiter)
                } else {
                    const recruiterIds = (companyList.filter((item) => updatedFields.companyIds.includes(item.id))).map((item) => item.assigned_rala_sub_admin)
                    const filteredRecruiters = inititalRecruiter.filter((item) => recruiterIds.includes(item.id))
                    setRecruiter(filteredRecruiters)
                }
            }
            resetPagination();
            return updatedFields;
        });
    };

    const resetPagination = () => {
        setReportPageNumber(1)
        if (paingationRef.current) {
            paingationRef.current.setCurrentPage(1)
        }
    }

    const toggleAll = (checked) => {
        if (checked) {
            var items = [];
            candidates.map((item) => {
                if (item.is_feedback_given && !item.is_sent_to_employeer) {
                    items.push(item._id);
                }
            })
            setSelectedItems(items)
        } else {
            setSelectedItems([])
        }
    }

    const [isShowVideoModal, setIsShowVideoModal] = useState(false);
    const [recordings, setRecordings] = useState([]);

    const handleClear = (name) => {
        if (name == 'toDate') {
            setToDate(null);
        } else {
            setFromDate(null);
        }
    };

    const clearSearch = () => {
        setSearchText("")
    }

    const CustomInput = forwardRef(({ value, onClick, onClear }, ref) => (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <input
                value={value}
                onClick={onClick}
                ref={ref}
                readOnly
                style={{ width: '100%' }}
                placeholder="dd/mm/yyyy"
                className="form-control" // Assuming you're using Bootstrap for styling
            />
            {value && (
                <button
                    type="button"
                    onClick={onClear}
                    style={{
                        position: 'absolute',
                        right: '5px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                        background: 'none',
                        border: 'none',
                        fontSize: '16px',
                        color: '#d9534f' // Bootstrap danger color
                    }}
                >
                    &#x2715; {/* Unicode character for an "X" mark */}
                </button>
            )}
        </div>
    ));

    const onSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = (key) => {
        if (sortConfig.key !== key) {
            return <FaSort />;
        }
        if (sortConfig.direction === 'asc') {
            return <FaSortUp />;
        }
        return <FaSortDown />;
    };

    const clearFilter = () => {
        setRecruiter(inititalRecruiter)
        setSelectedFields({
            companyIds: [],
            subAdminIds: [],
            jobTitles: [],
            jobIds: [],
            ratings: [],
            statuses: [],
            buCodes: [],
        })
    }

    return (
        <Fragment>
            <Toast ref={toast} />
            {
                isLoading &&
                <div className="spinner-loader">
                    <Loader type="spinner-circle" bgColor="#2F2F74" color="#2F2F74" size={100} />
                </div>
            }
            <div className="container-fluid history-container fixed-padding-3">
                <div className="row">
                    <div className="col-12 px-0">
                        <HeaderScreen />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 pageTitle_fixed">
                        <p className="employer-dashboard-jobs-posted mb-0">
                            <div className='d-flex align-items-center gap-2'>
                                <BiSolidReport size={18} />
                                {
                                    (jobId != undefined && jobId != null && jobId != "") ? (
                                        breadJobTitle + " / " + jobId.substring(0, 5) + "..."
                                    ) : "REPORT"
                                }
                            </div>
                        </p>
                    </div>
                </div>
                <div className="row mb-3 date_filter">
                    <div className='col-12 col-md-12 col-lg-4 mb-2'>
                        <div className='d-flex gap-2'>
                            <Button variant="outline-primary" onClick={downloadCSV}>
                                <FaFileExcel /> {" "} CVC
                            </Button>
                            <Button variant="outline-primary" onClick={reportData}>
                                <FaMailBulk /> {" "} Report
                            </Button>
                            <Button variant="outline-primary" onClick={downloadZIP}>
                                <FaDownload /> {" "} Feedback
                            </Button>
                        </div>
                    </div>
                    <div className='col-12 col-md-12 col-lg-5 mb-2'>
                        <div className='row'>
                            <div className='col-12 col-md-4 col-lg-4'>
                                <DatePicker className="form-select w-100 " maxDate={toDate ? new Date(toDate) : null} onChange={(date) => {
                                    setFromDate(date);
                                    handleClear('toDate');
                                }} selected={fromDate} placeholderText="dd/mm/yyyy" dateFormat="dd/MM/yyyy" customInput={<CustomInput onClear={() => handleClear('fromDate')} />} />
                                <small className="last-one-month text-danger mt-2" style={{ float: 'left' }}>Available for last one month</small>
                            </div>
                            <div className='col-12 col-md-4 col-lg-4 mb-2 mb-md-0'>
                                <DatePicker className="form-select w-100 " minDate={fromDate ? new Date(fromDate) : null} maxDate={fromDate ? new Date(moment(fromDate).add(30, 'days')) : null} onChange={(date) => setToDate(date)} selected={toDate} placeholderText="dd/mm/yyyy" dateFormat="dd/MM/yyyy" customInput={<CustomInput onClear={() => handleClear('toDate')} />} />
                            </div>
                            <div className='col-12 col-md-4 col-lg-4'>
                                <Button className="history-search-button w-100" onClick={() => {
                                    resetPagination();
                                    getCandidatesByJob();
                                }} style={{ width: '120px' }} >Search</Button>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-12 col-lg-3 mb-2'>
                        <InputGroup>
                            <Form.Control
                                type="text"
                                id="Search"
                                onChange={(e) => {
                                    setSearchText(e.target.value.trim());
                                }}
                                name="Search"
                                value={searchText}
                                placeholder="Search"
                                maxLength={150}
                                onFocus={() => {

                                }}
                                onBlur={() => {

                                }}
                                autoComplete="off"
                            />
                            {
                                searchText && (
                                    <InputGroup.Text onClick={() => clearSearch()} className="search-clear cursor-pointer">&#x2715;</InputGroup.Text>
                                )
                            }
                        </InputGroup>
                    </div>

                </div>
                <div className="row history-feednback-row">
                    <div className="col-12">
                        {
                            ((userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN || userDetails.account_type === SUB_ADMIN) && jobId !== undefined && jobId != "" && jobId != null) && (
                                <Tab.Container defaultActiveKey="first">
                                    <Nav fill variant="tabs">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first" onClick={() => {
                                                setNavType('Pending');
                                                resetPagination();
                                            }} >Pending</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second" onClick={() => {
                                                setNavType('Sent');
                                                resetPagination();
                                            }}>Sent</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Tab.Container>
                            )
                        }
                        <Card>
                            <Card.Body>
                                <div className="row">
                                    {
                                        (userDetails && (userDetails.account_type === SUPER_ADMIN || userDetails.account_type === EMPLOYER_SUPER_ADMIN || userDetails.account_type === EMPLOYER_SUB_ADMIN || userDetails.account_type === SUB_ADMIN)) && (
                                            <div className="col-1 report-filter-section" style={{ width: '50px' }}>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip>Filters</Tooltip>}
                                                >
                                                    <DropdownButton id="report-filter" title={<CiFilter style={{ width: '25px', height: '25px' }} />} style={{ backgroundImage: 'none' }} >

                                                        {
                                                            !jobId && (
                                                                <>
                                                                    {
                                                                        userDetails && (userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN) && (
                                                                            <DropdownButton className="nested-button" title={"Company" + (selectedFields.companyIds.length > 0 ? ` (${selectedFields.companyIds.length})` : "")}>
                                                                                {companyList &&
                                                                                    companyList.map((item, index) => (
                                                                                        <InputGroup className="flex" style={{ justifyContent: 'space-between', padding: '8px' }} key={item.id}>
                                                                                            <span title={item.company_name} className="filter-item">{item.company_name}</span>
                                                                                            <InputGroup.Checkbox
                                                                                                key={"company" + index}
                                                                                                aria-label="Checkbox for following text input"
                                                                                                onChange={() => handleCheckboxChange('companyIds', item.id)}
                                                                                                checked={selectedFields.companyIds.includes(item.id)}
                                                                                            />
                                                                                        </InputGroup>
                                                                                    ))}
                                                                            </DropdownButton>
                                                                        )
                                                                    }
                                                                    {
                                                                        userDetails && userDetails.account_type === SUPER_ADMIN && (
                                                                            <DropdownButton className="nested-button" title={"Recruiter" + (selectedFields.subAdminIds.length > 0 ? ` (${selectedFields.subAdminIds.length})` : "")}>
                                                                                {recruiter &&
                                                                                    recruiter.map((item, index) => (
                                                                                        <InputGroup className="flex" style={{ justifyContent: 'space-between', padding: '8px' }} key={item.id}>
                                                                                            <span title={item.first_name + " " + (item.last_name ?? "")} className="filter-item">{item.first_name + " " + (item.last_name ?? "")}</span>
                                                                                            <InputGroup.Checkbox
                                                                                                key={"recruiter" + index}
                                                                                                aria-label="Checkbox for following text input"
                                                                                                onChange={() => handleCheckboxChange('subAdminIds', item.id)}
                                                                                                checked={selectedFields.subAdminIds.includes(item.id)}
                                                                                            />
                                                                                        </InputGroup>
                                                                                    ))}
                                                                            </DropdownButton>
                                                                        )
                                                                    }
                                                                    <DropdownButton className="nested-button" title={"Job Title" + (selectedFields.jobTitles.length > 0 ? ` (${selectedFields.jobTitles.length})` : "")}>
                                                                        {
                                                                            jobTitles.map((item, index) => {
                                                                                return <InputGroup className="flex" style={{ justifyContent: 'space-between', padding: '8px' }}>
                                                                                    <span title={item} className="filter-item">{item}</span>
                                                                                    <InputGroup.Checkbox
                                                                                        key={"job_title" + index}
                                                                                        aria-label="Checkbox for following text input"
                                                                                        onChange={() => handleCheckboxChange('jobTitles', item)}
                                                                                        checked={selectedFields.jobTitles.includes(item)}
                                                                                    />
                                                                                </InputGroup>;
                                                                            })
                                                                        }
                                                                    </DropdownButton>
                                                                    <DropdownButton className="nested-button" title={"Job ID" + (selectedFields.jobIds.length > 0 ? ` (${selectedFields.jobIds.length})` : "")}>
                                                                        {
                                                                            jobIds.map((item, index) => {
                                                                                return <InputGroup className="flex" style={{ justifyContent: 'space-between', padding: '8px' }}>
                                                                                    <span title={item} className="filter-item">{item}</span>
                                                                                    <InputGroup.Checkbox
                                                                                        key={"job_id" + index}
                                                                                        aria-label="Checkbox for following text input"
                                                                                        onChange={() => handleCheckboxChange('jobIds', item)}
                                                                                        checked={selectedFields.jobIds.includes(item)}
                                                                                    />
                                                                                </InputGroup>;
                                                                            })
                                                                        }
                                                                    </DropdownButton>
                                                                </>
                                                            )
                                                        }
                                                        <DropdownButton className="nested-button" title={"Ratings" + (selectedFields.ratings.length > 0 ? ` (${selectedFields.ratings.length})` : "")}>
                                                            {
                                                                [1, 2, 3, 4, 5].map((item, index) => {
                                                                    return <InputGroup className="flex" style={{ justifyContent: 'space-between', padding: '8px' }}>
                                                                        <span title={item} className="filter-item">{item}</span>
                                                                        <InputGroup.Checkbox
                                                                            key={"job_id" + index}
                                                                            aria-label="Checkbox for following text input"
                                                                            onChange={() => handleCheckboxChange('ratings', item)}
                                                                            checked={selectedFields.ratings.includes(item)}
                                                                        />
                                                                    </InputGroup>;
                                                                })
                                                            }
                                                        </DropdownButton>
                                                        <DropdownButton className="nested-button" title={"Status" + (selectedFields.statuses.length > 0 ? ` (${selectedFields.statuses.length})` : "")}>
                                                            <InputGroup className="flex" style={{ justifyContent: 'space-between', padding: '8px' }}>
                                                                {'Selcted'}
                                                                <InputGroup.Checkbox
                                                                    key={"statuses" + 0}
                                                                    aria-label="Checkbox for following text input"
                                                                    onChange={() => handleCheckboxChange('statuses', INTERVIEW_FINAL_STATUS_SELECTED)}
                                                                    checked={selectedFields.statuses.includes(INTERVIEW_FINAL_STATUS_SELECTED)}
                                                                />
                                                            </InputGroup>
                                                            <InputGroup className="flex" style={{ justifyContent: 'space-between', padding: '8px' }}>
                                                                {'Rejected'}
                                                                <InputGroup.Checkbox
                                                                    key={"statuses" + 1}
                                                                    aria-label="Checkbox for following text input"
                                                                    onChange={() => handleCheckboxChange('statuses', INTERVIEW_FINAL_STATUS_REJECTED)}
                                                                    checked={selectedFields.statuses.includes(INTERVIEW_FINAL_STATUS_REJECTED)}
                                                                />
                                                            </InputGroup>
                                                            <InputGroup className="flex" style={{ justifyContent: 'space-between', padding: '8px' }}>
                                                                {'No Show'}
                                                                <InputGroup.Checkbox
                                                                    key={"statuses" + 2}
                                                                    aria-label="Checkbox for following text input"
                                                                    onChange={() => handleCheckboxChange('statuses', 'NO_SHOW')}
                                                                    checked={selectedFields.statuses.includes('NO_SHOW')}
                                                                />
                                                            </InputGroup>
                                                            <InputGroup className="flex" style={{ justifyContent: 'space-between', padding: '8px' }}>
                                                                {'Proxy'}
                                                                <InputGroup.Checkbox
                                                                    key={"statuses" + 3}
                                                                    aria-label="Checkbox for following text input"
                                                                    onChange={() => handleCheckboxChange('statuses', PROXY)}
                                                                    checked={selectedFields.statuses.includes(PROXY)}
                                                                />
                                                            </InputGroup>
                                                        </DropdownButton>
                                                        {
                                                            !jobId && (
                                                                <DropdownButton className="nested-button" title={"BU Code" + (selectedFields.buCodes.length > 0 ? ` (${selectedFields.buCodes.length})` : "")}>
                                                                    {
                                                                        buCodes.map((item, index) => {
                                                                            return <InputGroup className="flex" style={{ justifyContent: 'space-between', padding: '8px' }}>
                                                                                <span title={item} className="filter-item">{item}</span>
                                                                                <InputGroup.Checkbox
                                                                                    key={"job_id" + index}
                                                                                    aria-label="Checkbox for following text input"
                                                                                    onChange={() => handleCheckboxChange('buCodes', item)}
                                                                                    checked={selectedFields.buCodes.includes(item)}
                                                                                />
                                                                            </InputGroup>;
                                                                        })
                                                                    }
                                                                </DropdownButton>
                                                            )
                                                        }
                                                        <div className="my-2" style={{ textAlign: 'center' }}>
                                                            <Button onClick={clearFilter} style={{
                                                                marginTop: '0px',
                                                                background: '#FFFBFE',
                                                                color: 'black',
                                                                border: 'none'
                                                            }} className="employer-dashboard-interview-join">
                                                                Reset
                                                            </Button>
                                                        </div>
                                                    </DropdownButton>
                                                </OverlayTrigger>
                                            </div>
                                        )
                                    }
                                    <div className="col-11 reporTable" style={{ flex: 1 }}>
                                        <Table bordered striped hover size="lg" responsive className="history-feednback-completed">
                                            <thead className='sticky-top'>
                                                <tr>
                                                    {
                                                        ((userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN) && jobId && navType === 'Pending') && (
                                                            <th>
                                                                <input className="cursor-pointer" onChange={(e) => toggleAll(e.target.checked)} type="checkbox" />
                                                            </th>
                                                        )
                                                    }
                                                    <th className='text-start' onClick={() => onSort('job_id')}>Job ID {getSortIcon('job_id')}</th>
                                                    <th className='text-start' onClick={() => onSort('_id')}>Candidate ID {getSortIcon('_id')}</th>
                                                    <th className='text-start' onClick={() => onSort('candidate_name')}>Candidate Name {getSortIcon('candidate_name')}</th>
                                                    <th className='text-start' onClick={() => onSort('candidate_email')}>Email ID {getSortIcon('candidate_email')}</th>
                                                    <th className='text-start' onClick={() => onSort('candidate_mobile_number')}>Mobile Number {getSortIcon('candidate_mobile_number')}</th>
                                                    <th className='text-start' onClick={() => onSort('job_title')}>Job Title {getSortIcon('job_title')}</th>
                                                    <th className='text-start' onClick={() => onSort('final_status')}>Status {getSortIcon('final_status')}</th>
                                                    <th className='text-start' onClick={() => onSort('incomplete_interview')}>Incomplete Interview {getSortIcon('incomplete_interview')}</th>
                                                    <th className='text-start' onClick={() => onSort('overall_rating')}>Rating {getSortIcon('overall_rating')}</th>
                                                    <th className='text-start' onClick={() => onSort('scheduler_name')}>Scheduler Name {getSortIcon('scheduler_name')}</th>
                                                    <th className='text-start' onClick={() => onSort('scheduler_mobile_number')}>Scheduler Number {getSortIcon('scheduler_mobile_number')}</th>
                                                    <th className='text-start' onClick={() => onSort('interviewer_name')}>Interviewer Name {getSortIcon('interviewer_name')}</th>
                                                    <th className='text-start' onClick={() => onSort('interview_mobile_number')}>Interviewer Number {getSortIcon('interview_mobile_number')}</th>
                                                    <th className='text-start' onClick={() => onSort('bu_code')}>BU Code {getSortIcon('bu_code')}</th>
                                                    <th className='text-start' onClick={() => onSort('createdAt')}>Profile Uploaded {getSortIcon('createdAt')}</th>
                                                    <th className='text-start' onClick={() => onSort('scheduled_on')}>Scheduled on {getSortIcon('scheduled_on')}</th>
                                                    <th className='video-cell'>Recording</th>
                                                    <th>Report</th>
                                                    {
                                                        userDetails && userDetails.account_type !== EMPLOYER_SUPER_ADMIN && userDetails.account_type !== EMPLOYER_SUB_ADMIN && (
                                                            <>
                                                                <th onClick={() => onSort('qc_status')}>QC Status {getSortIcon('qc_status')}</th>
                                                                <th>QC Remark</th>
                                                            </>
                                                        )
                                                    }
                                                    <th onClick={() => onSort('follow_up_attempts')}>Follow Up Attempts {getSortIcon('follow_up_attempts')}</th>
                                                    <th>History</th>
                                                    {
                                                        userDetails && userDetails.account_type === SUPER_ADMIN && (
                                                            <th>Can Edit</th>
                                                        )
                                                    }
                                                    {
                                                        // userDetails && userDetails.account_type !== EMPLOYER_SUPER_ADMIN && userDetails.account_type !== EMPLOYER_SUB_ADMIN 
                                                        userDetails
                                                        && (
                                                            <th>Edit</th>
                                                        )
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    !_.isUndefined(candidates) && !_.isNull(candidates) &&
                                                    _.map(candidates, (candidate, index) => {
                                                        return <tr key={"feednback-completed-" + index}>
                                                            {
                                                                ((userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN) && jobId && navType === 'Pending') && (
                                                                    <td>
                                                                        {
                                                                            candidate.is_feedback_given ? (
                                                                                <input className="cursor-pointer" checked={selectedItems.includes(candidate._id)} onChange={(e) => {
                                                                                    const isChecked = e.target.checked;
                                                                                    let updatedItems;
                                                                                    if (isChecked) {
                                                                                        updatedItems = [...selectedItems, candidate._id];
                                                                                    } else {
                                                                                        updatedItems = selectedItems.filter((itemId) => itemId !== candidate._id);
                                                                                    }
                                                                                    setSelectedItems(updatedItems);
                                                                                }} type="checkbox" />
                                                                            ) : (
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={<Tooltip>Feedback pending</Tooltip>}
                                                                                >
                                                                                    <input className="cursor-pointer" type="checkbox" disabled />
                                                                                </OverlayTrigger>

                                                                            )
                                                                        }

                                                                    </td>
                                                                )
                                                            }
                                                            <td className="text-start">{candidate.job_id}</td>
                                                            <td className="text-start">{candidate._id}</td>
                                                            <td className="text-start fw-bold">{candidate.candidate_name}</td>
                                                            <td className="text-start">{candidate.candidate_email}</td>
                                                            <td className="text-start">{candidate.candidate_mobile_number}</td>
                                                            <td className="text-start">{candidate.job_title}</td>
                                                            <td className="text-start interviewSatus">
                                                                <span className={candidate.final_status === INTERVIEW_FINAL_STATUS_REJECTED ? 'rejected' : (candidate.final_status === INTERVIEW_FINAL_STATUS_SELECTED ? 'selected' : '')} style={{
                                                                    color: candidate.final_status === INTERVIEW_FINAL_STATUS_REJECTED ? '#840202' : (
                                                                        candidate.final_status === INTERVIEW_FINAL_STATUS_SELECTED ? '#0d5c01' : ''
                                                                    )
                                                                }}>{getInterviewFinalStatus(candidate.final_status)}</span>
                                                            </td>
                                                            <td className="text-center">{getincompleteInterview(candidate.incomplete_interview)}</td>
                                                            <td className="text-center">{candidate.overall_rating}</td>
                                                            <td className="text-start">{candidate.schedulerUserInfo ? `${candidate.schedulerUserInfo.first_name} ${candidate.schedulerUserInfo.last_name}` : ""}</td>
                                                            <td className="text-start">{candidate.schedulerUserInfo ? candidate.schedulerUserInfo.mobile_number : ""}</td>
                                                            <td className="text-start">{candidate.interviewerInfo ? `${candidate.interviewerInfo.first_name} ${candidate.interviewerInfo.last_name}` : ""}</td>
                                                            <td className="text-start">{candidate.interviewerInfo ? candidate.interviewerInfo.mobile_number : ""}</td>
                                                            <td className="text-start">{candidate.bu_code}</td>
                                                            <td className="text-start">{commonDateFormat(candidate.createdAt)}</td>
                                                            <td className="text-start">{candidate.scheduled_on ? commonDateFormat(candidate.scheduled_on) : ""}</td>
                                                            <td className="text-center" >
                                                                {
                                                                    candidate.video.length > 0 && (
                                                                        <BiSolidVideos data-title="View recordings" size={20} onClick={() => {
                                                                            setRecordings(candidate.video);
                                                                            setIsShowVideoModal(true);
                                                                        }} />
                                                                    )
                                                                }
                                                            </td>
                                                            <td className="text-center">
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={candidate.is_feedback_given ? <Tooltip>Interview Report</Tooltip> : <></>}
                                                                >
                                                                    <button className="btn">
                                                                        {candidate.job_data.custom_feedback_sheet}
                                                                        {
                                                                            (
                                                                                (
                                                                                    (userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN) && candidate.is_feedback_given
                                                                                ) ||
                                                                                (
                                                                                    (userDetails.account_type === EMPLOYER_SUPER_ADMIN || userDetails.account_type === EMPLOYER_SUB_ADMIN) && candidate.is_sent_to_employeer
                                                                                )
                                                                            ) && (
                                                                                <FaFileAlt onClick={() => {
                                                                                    if (candidate.job_data.custom_feedback_sheet) {
                                                                                        window.open(candidate.feedback_sheet, '_blank');
                                                                                    } else {
                                                                                        window.open(`/finalInterviewReport/${candidate._id}`, '_blank')
                                                                                    }
                                                                                }} />
                                                                            )
                                                                        }
                                                                    </button>
                                                                </OverlayTrigger>
                                                            </td>
                                                            {
                                                                userDetails && userDetails.account_type !== EMPLOYER_SUPER_ADMIN && userDetails.account_type !== EMPLOYER_SUB_ADMIN && (
                                                                    <>
                                                                        <td className="text-center">
                                                                            {qcStatus(candidate)}
                                                                        </td>
                                                                        <td className="text-center">
                                                                            <OverlayTrigger
                                                                                placement="top"
                                                                                overlay={<Tooltip>Add QC Remark</Tooltip>}
                                                                            >
                                                                                <button className="btn">
                                                                                    <BiMessageRounded onClick={() => {
                                                                                        setInterviewIdForQcRemark(candidate._id);
                                                                                        setIsShowQCRemark(true);
                                                                                    }} size={20} />
                                                                                </button>
                                                                            </OverlayTrigger>
                                                                        </td>
                                                                    </>
                                                                )
                                                            }
                                                            <td className="text-center">{candidate.follow_up_attempts.length}</td>
                                                            <td className="text-center">
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={<Tooltip>Follow History</Tooltip>}
                                                                >
                                                                    <button className="btn">
                                                                        <FaHistory onClick={() => {
                                                                            setInterviewIdForQcRemark(candidate._id);
                                                                            setCandidateData(candidate);
                                                                            setIsShowHistoryModel(true);
                                                                        }} size={20} />
                                                                    </button>
                                                                </OverlayTrigger>
                                                            </td>
                                                            {
                                                                userDetails && userDetails.account_type === SUPER_ADMIN && (
                                                                    <td>
                                                                        {canEditStatus(candidate)}
                                                                    </td>
                                                                )
                                                            }
                                                            <td>
                                                                {
                                                                    userDetails && (userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN)

                                                                    && (
                                                                        <button

                                                                            className="btn btn-outline-primary btn-sm"
                                                                            // disabled={candidate.feedback_date != null && dayDifference(candidate.feedback_date) > 1}
                                                                            onClick={() => {
                                                                                if (editedId === candidate._id) {
                                                                                    setEditedId("")
                                                                                } else {
                                                                                    setOriginalRecordEditableTill(candidate.record_editable_till);
                                                                                    setEditedId(candidate._id)
                                                                                }
                                                                            }}>
                                                                            {
                                                                                editedId === candidate._id ? 'Save' : 'Edit'
                                                                            }
                                                                        </button>
                                                                    )
                                                                }
                                                            </td>

                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                        {
                                            ((userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN) && jobId !== "" && jobId !== undefined && jobId != null) && navType === 'Pending' && selectedItems.length > 0 && (
                                                <div className="col-12 my-auto" style={{ textAlign: 'right' }}>
                                                    <Button className="history-search-button" onClick={sentToEmployeer} >Send</Button>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <div className="employer-dashboard-title ">
                    {
                        totalCount > 0 && (
                            <CustomPaginationComponent ref={paingationRef} total={totalCount} updatePagination={updateReportPagination} />
                        )
                    }
                </div>
            </div>
            {
                isShowQCRemark && <Modal show={isShowQCRemark} centered>
                    <Modal.Body>
                        <Form>
                            <Form.Control
                                as="textarea"
                                placeholder="Leave a comment here"
                                style={{ height: '100px' }}
                                onChange={(e) => {
                                    setQcRemark(e.target.value)
                                }}
                            />
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={() => { setIsShowQCRemark(false); }}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={() => {
                            updateCandidateProfile(interviewIdForQcRemark, {
                                ...interviewerBody,
                                qc_remark: qcRemark
                            });
                            setIsShowQCRemark(false)
                        }}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            }

            {
                isShowHistoryModel && <Modal show={isShowHistoryModel} centered>
                    <Modal.Body>
                        <Container>
                            <div style={{ textAlign: 'right' }}>
                                <Button onClick={() => {
                                    setAddedAttempts([
                                        ...addedAttempts,
                                        {
                                            follow_up_date: new Date(),
                                            follow_up_reason: 'follow_up_reason',
                                            upload_by: userDetails.first_name
                                        }
                                    ]);
                                }} >Add</Button>
                            </div>
                            <Row>
                                <Col>Candidate Name : </Col>
                                <Col><b>{candidateData ? candidateData.candidate_name : ""}</b></Col>
                            </Row>
                            <Row>
                                <Col>Email address : </Col>
                                <Col><b>{candidateData ? candidateData.candidate_email : ""}</b></Col>
                            </Row>
                            <Row>
                                <Col>Mobile number : </Col>
                                <Col><b>{candidateData ? candidateData.candidate_mobile_number : ""}</b></Col>
                            </Row>
                            <Row>
                                <p>Follow up attempts</p>
                            </Row>
                            <Row>
                                <Table bordered hover size="lg">
                                    <thead>
                                        <tr>
                                            <th>Follow Up Date</th>
                                            <th>Follow Up Reason</th>
                                            <th>Updated By</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            candidateData && candidateData.follow_up_attempts.map((item) => {
                                                return (<tr>
                                                    <td>{commonDateFormat(item.follow_up_date)}</td>
                                                    <td>{item.follow_up_reason}</td>
                                                    <td>{item.upload_by}</td>
                                                </tr>);
                                            })
                                        }
                                        {
                                            addedAttempts && addedAttempts.map((item, index) => {
                                                return (<tr key={'attempts' + index}>
                                                    <td>
                                                        <DatePicker
                                                            onChange={(date) => {
                                                                setAddedAttempts((prevAttempts) =>
                                                                    prevAttempts.map((prevItem, i) =>
                                                                        i === index
                                                                            ? { ...prevItem, follow_up_date: date }
                                                                            : prevItem
                                                                    )
                                                                );
                                                            }}
                                                            selected={item.follow_up_date}
                                                            maxDate={new Date()}
                                                            className="form-control"
                                                        />
                                                    </td>
                                                    <td>
                                                        <Form.Control onChange={(e) => {
                                                            setAddedAttempts((prevAttempts) =>
                                                                prevAttempts.map((prevItem, i) =>
                                                                    i === index
                                                                        ? { ...prevItem, follow_up_reason: e.target.value }
                                                                        : prevItem
                                                                )
                                                            );
                                                        }} type="text" placeholder="Put Reason" />
                                                    </td>
                                                    <td>{item.upload_by}</td>
                                                </tr>);
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={() => { setIsShowHistoryModel(false); }}>
                            Close
                        </Button>
                        {
                            addedAttempts && addedAttempts.length > 0 && (
                                <Button variant="primary" onClick={() => {
                                    updateCandidateProfile(interviewIdForQcRemark, {
                                        follow_up_attempts: [
                                            ...candidateData.follow_up_attempts,
                                            ...addedAttempts
                                        ]
                                    });
                                    setIsShowHistoryModel(false)
                                    setAddedAttempts([])
                                }}>
                                    Submit
                                </Button>
                            )
                        }

                    </Modal.Footer>
                </Modal>
            }

            {
                isShowVideoModal &&
                <Modal show={isShowVideoModal} centered>
                    <Modal.Header>
                        <Modal.Title>Recordings</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            _.map(recordings, (recording, index) => {
                                return <ListGroup>
                                    <ListGroup.Item>Recording: <a rel="noreferrer" target='_blank' href={recording.url}>{recording.fileName}</a> </ListGroup.Item>
                                    {
                                        recording.transcriptUrl && (
                                            <ListGroup.Item>Transcript: <a rel="noreferrer" target='_blank' href={recording.transcriptUrl}>{recording.fileName}</a> </ListGroup.Item>
                                        )
                                    }
                                </ListGroup>
                            })
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={() => setIsShowVideoModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            }

        </Fragment>
    )
}
export default ReportScreen;