import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import _ from 'lodash';
import { Toast } from "primereact/toast";
import React, { Fragment, forwardRef, useEffect, useRef, useState } from "react";
import { Alert, Button, Card, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "react-js-loader";
import { useNavigate, useParams } from "react-router-dom";
import { ReactTags } from 'react-tag-autocomplete';
import 'react-tag-autocomplete/example/src/styles.css';
import 'react-tagsinput/react-tagsinput.css';
import { API_URL, JOB_STATUS_OPEN, getAuthUserFromSessionStorage } from "../../common/constant";
import HeaderScreen from "../HeaderScreen/HeaderScreen";
import "./CreateJobScreen.css";

const CreateJobScreen = () => {
    const navigate = useNavigate();
    const [isSubmit, setIsSubmit] = useState(false);
    const [jobTitleError, setIsJobTitleError] = useState("");
    const [projectCodeError, setIsProjectCodeError] = useState("");
    const [jobLocationError, setIsJobLocationError] = useState("");
    const [jobTypeError, setIsJobTypeError] = useState("");
    const [experienceMinYearError, setIsexperienceMinYearError] = useState("");
    const [experienceMaxYearError, setIsexperienceMaxYearError] = useState("");
    const [jobDiscriptionError, setIsJobDiscriptionError] = useState("");
    const [uploadJDError, setIsUploadJDError] = useState("");
    const [interviewDurationError, setIsInterviewDurationError] = useState("");
    const [primarySkillError, setIsPrimarySkillError] = useState("");
    const [secondarySkillError, setIsSecondarySkillError] = useState("");
    const [goodToHaveSkillsTextareaError, setIsGoodToHaveSkillsTextareaError] = useState("");
    const [mandatoryInterviewQuestionsTextareaError, setIsMandatoryInterviewQuestionsTextareaError] = useState("");
    const [mandatoryCodingQuestionsTextareaError, setIsMandatoryCodingQuestionsTextareaError] = useState("");
    const [customFeedbackSheetFileError, setIsCustomFeedbackSheetFileError] = useState("");
    const [userDetails, setUserDetails] = useState(getAuthUserFromSessionStorage());
    const { id: jobId } = useParams();

    const [showModal, setShowModal] = useState(false);
    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const [jobDetails, setJobDetails] = useState();
    const toast = useRef(null);
    const changeHandler = (e) => {
        const { name, value } = e.target;
        if (name === 'goodToHaveSkills') {
            setJobDetails({
                ...jobDetails,
                goodToHaveSkills: e.target.checked,
            });
        }
        else if (name === 'mandatoryInterviewQuestions') {
            setJobDetails({
                ...jobDetails,
                mandatoryInterviewQuestions: e.target.checked,
            });
        }
        else if (name === 'mandatoryCodingQuestions') {
            setJobDetails({
                ...jobDetails,
                mandatoryCodingQuestions: e.target.checked,
            });
        }
        else if (name === 'customFeedbackSheet') {
            setJobDetails({
                ...jobDetails,
                customFeedbackSheet: e.target.checked,
            });
        }
        else if (name === "is_coding_test_mandatory") {
            setJobDetails({
                ...jobDetails,
                isCodingTestMandatory: e.target.checked,
            });
        } else if (name === "experienceMinYear" || name === "experienceMaxYear") {
            if (/^\d*$/.test(value)) {
                setJobDetails({
                    ...jobDetails,
                    [name]: value,
                });
            }
        } else {
            setJobDetails({
                ...jobDetails,
                [name]: value,
            });
        }
    };

    const uploadFile = (event, name) => {
        const data = new FormData();
        data.append('file', event.target.files[0]);
        fetch(`${API_URL}api/uploadFiles`, {
            method: 'POST',
            body: data
        })
            .then((response) => response.json())
            .then((response) => {
                setJobDetails({
                    ...jobDetails,
                    [name]: response.filepath
                });
                console.log(jobDetails);
            })
            .catch((err) => {
                console.log("error", err);
                setJobDetails({
                    ...jobDetails,
                    uploadJD: ""
                });
            });
    }

    const validateForm = () => {
        if (_.isUndefined(jobDetails.jobTitle) || _.isNull(jobDetails.jobTitle) || (jobDetails.jobTitle === '') || (jobDetails.jobTitle.trim() === '')) {
            setIsJobTitleError("Job Title is required!");
        }
        else if (!_.isUndefined(jobDetails.jobTitle) && !_.isNull(jobDetails.jobTitle) && (jobDetails.jobTitle !== '') && (jobDetails.jobTitle.trim() !== '') && ((jobDetails.jobTitle.trim().length < 6) || (jobDetails.jobTitle.trim().length > 150))) {
            setIsJobTitleError("Job Title must be between 6 to 150 characters!");
        }
        else {
            setIsJobTitleError("");
        }
        if (_.isUndefined(jobDetails.projectCode) || _.isNull(jobDetails.projectCode) || (jobDetails.projectCode === '') || (jobDetails.projectCode.trim() === '')) {
            setIsProjectCodeError("Project code is required!");
        }
        else {
            setIsProjectCodeError("");
        }
        if (_.isUndefined(jobDetails.jobLocation) || _.isNull(jobDetails.jobLocation) || (jobDetails.jobLocation === '') || (jobDetails.jobLocation.trim() === '')) {
            setIsJobLocationError("Job Location is required!");
        }
        else {
            setIsJobLocationError("");
        }
        if (_.isUndefined(jobDetails.jobType) || _.isNull(jobDetails.jobType) || (jobDetails.jobType === '') || (jobDetails.jobType.trim() === '')) {
            setIsJobTypeError("Job Type is required!");
        }
        else {
            setIsJobTypeError("");
        }
        if (_.isUndefined(jobDetails.experienceMinYear) || _.isNull(jobDetails.experienceMinYear) || (jobDetails.experienceMinYear === '') || (jobDetails.experienceMinYear.toString().trim() === '')) {
            setIsexperienceMinYearError("Experience minimum year is required!");
        }
        else {
            setIsexperienceMinYearError("");
        }
        if (_.isUndefined(jobDetails.experienceMaxYear) || _.isNull(jobDetails.experienceMaxYear) || (jobDetails.experienceMaxYear === '') || (jobDetails.experienceMaxYear.toString().trim() === '')) {
            setIsexperienceMaxYearError("Experience maximum year is required!");
        }
        else {
            setIsexperienceMaxYearError("");
            if (!_.isUndefined(jobDetails.experienceMaxYear) && !_.isNull(jobDetails.experienceMaxYear) && jobDetails.experienceMinYear.toString().trim() !== "") {
                if (parseInt(jobDetails.experienceMaxYear) <= parseInt(jobDetails.experienceMinYear)) {
                    setIsexperienceMaxYearError("Experience maximum year must be grater than Experience minimum year!");
                }
            }
        }
        if (_.isUndefined(jobDetails.jobDiscription) || _.isNull(jobDetails.jobDiscription) || (jobDetails.jobDiscription === '') || (jobDetails.jobDiscription.trim() === '')) {
            setIsJobDiscriptionError("Job Discription is required!");
        }
        else {
            setIsJobDiscriptionError("");
        }
        if (_.isUndefined(jobDetails.uploadJD) || _.isNull(jobDetails.uploadJD) || (jobDetails.uploadJD === '') || (jobDetails.uploadJD.trim() === '')) {
            setIsUploadJDError("Upload JD is required!");
        }
        else {
            setIsUploadJDError("");
        }
        if (_.isUndefined(jobDetails.primarySkill) || _.isNull(jobDetails.primarySkill) || (jobDetails.primarySkill === '') || (jobDetails.primarySkill.length === 0)) {
            setIsPrimarySkillError("Primary Skill is required!");
        }
        else {
            setIsPrimarySkillError("");
        }
        if (_.isUndefined(jobDetails.secondarySkill) || _.isNull(jobDetails.secondarySkill) || (jobDetails.secondarySkill === '') || (jobDetails.secondarySkill.length === 0)) {
            setIsSecondarySkillError("Secondary Skill is required!");
        }
        else {
            setIsSecondarySkillError("");
        }
        if (!_.isUndefined(jobDetails.goodToHaveSkills) && !_.isNull(jobDetails.goodToHaveSkills) && jobDetails.goodToHaveSkills && (_.isUndefined(jobDetails.goodToHaveSkillsTextarea) || _.isNull(jobDetails.goodToHaveSkillsTextarea) || (jobDetails.goodToHaveSkillsTextarea === '') || (jobDetails.goodToHaveSkillsTextarea.trim() === ''))) {
            setIsGoodToHaveSkillsTextareaError("Provide mandatory Good to have skills is required!");
        }
        else {
            setIsGoodToHaveSkillsTextareaError("");
        }
        if (!_.isUndefined(jobDetails.mandatoryInterviewQuestions) && !_.isNull(jobDetails.mandatoryInterviewQuestions) && jobDetails.mandatoryInterviewQuestions && (_.isUndefined(jobDetails.mandatoryInterviewQuestionsTextarea) || _.isNull(jobDetails.mandatoryInterviewQuestionsTextarea) || (jobDetails.mandatoryInterviewQuestionsTextarea === '') || (jobDetails.mandatoryInterviewQuestionsTextarea.trim() === ''))) {
            setIsMandatoryInterviewQuestionsTextareaError("Provide mandatory Mandatory interview questions is required!");
        }
        else {
            setIsMandatoryInterviewQuestionsTextareaError("");
        }
        if (!_.isUndefined(jobDetails.mandatoryCodingQuestions) && !_.isNull(jobDetails.mandatoryCodingQuestions) && jobDetails.mandatoryCodingQuestions && (_.isUndefined(jobDetails.mandatoryCodingQuestionsTextarea) || _.isNull(jobDetails.mandatoryCodingQuestionsTextarea) || (jobDetails.mandatoryCodingQuestionsTextarea === '') || (jobDetails.mandatoryCodingQuestionsTextarea.trim() === ''))) {
            setIsMandatoryCodingQuestionsTextareaError("Provide mandatory Mandatory coding questions is required!");
        }
        else {
            setIsMandatoryCodingQuestionsTextareaError("");
        }
        if (!_.isUndefined(jobDetails.customFeedbackSheet) && !_.isNull(jobDetails.customFeedbackSheet) && jobDetails.customFeedbackSheet && (_.isUndefined(jobDetails.customFeedbackSheetFile) || _.isNull(jobDetails.customFeedbackSheetFile) || (jobDetails.customFeedbackSheetFile === '') || (jobDetails.customFeedbackSheetFile.trim() === ''))) {
            setIsCustomFeedbackSheetFileError("Upload Feedback Sheet is required!");
        }
        else {
            setIsCustomFeedbackSheetFileError("");
        }
    };

    const checkedValidateForm = () => {
        if (_.isUndefined(jobDetails.jobTitle) || _.isNull(jobDetails.jobTitle) || (jobDetails.jobTitle === '') || (jobDetails.jobTitle.trim() === '')) {
            return false;
        }
        else if (!_.isUndefined(jobDetails.jobTitle) && !_.isNull(jobDetails.jobTitle) && (jobDetails.jobTitle !== '') && (jobDetails.jobTitle.trim() !== '') && ((jobDetails.jobTitle.trim().length < 6) || (jobDetails.jobTitle.trim().length > 150))) {
            return false;
        }
        if (_.isUndefined(jobDetails.projectCode) || _.isNull(jobDetails.projectCode) || (jobDetails.projectCode === '') || (jobDetails.projectCode.trim() === '')) {
            return false;
        }
        if (_.isUndefined(jobDetails.jobLocation) || _.isNull(jobDetails.jobLocation) || (jobDetails.jobLocation === '') || (jobDetails.jobLocation.trim() === '')) {
            return false;
        }
        if (_.isUndefined(jobDetails.jobType) || _.isNull(jobDetails.jobType) || (jobDetails.jobType === '') || (jobDetails.jobType.trim() === '')) {
            return false;
        }
        if (_.isUndefined(jobDetails.experienceMinYear) || _.isNull(jobDetails.experienceMinYear) || (jobDetails.experienceMinYear === '') || (jobDetails.experienceMinYear.toString().trim() === '')) {
            return false;
        }
        if (_.isUndefined(jobDetails.experienceMaxYear) || _.isNull(jobDetails.experienceMaxYear) || (jobDetails.experienceMaxYear === '') || (jobDetails.experienceMaxYear.toString().trim() === '')) {
            return false;
        }
        if (!_.isUndefined(jobDetails.experienceMaxYear) && !_.isNull(jobDetails.experienceMaxYear) && !_.isUndefined(jobDetails.experienceMinYear) && !_.isNull(jobDetails.experienceMinYear) && parseInt(jobDetails.experienceMaxYear) <= parseInt(jobDetails.experienceMinYear)) {
            return false;
        }
        if (_.isUndefined(jobDetails.jobDiscription) || _.isNull(jobDetails.jobDiscription) || (jobDetails.jobDiscription === '') || (jobDetails.jobDiscription.trim() === '')) {
            return false;
        }
        if (_.isUndefined(jobDetails.uploadJD) || _.isNull(jobDetails.uploadJD) || (jobDetails.uploadJD === '') || (jobDetails.uploadJD.trim() === '')) {
            return false;
        }
        if (_.isUndefined(jobDetails.primarySkill) || _.isNull(jobDetails.primarySkill) || (jobDetails.primarySkill === '') || (jobDetails.primarySkill.length === 0)) {
            return false;
        }
        if (_.isUndefined(jobDetails.secondarySkill) || _.isNull(jobDetails.secondarySkill) || (jobDetails.secondarySkill === '') || (jobDetails.secondarySkill.length === 0)) {
            return false;
        }
        if (!_.isUndefined(jobDetails.goodToHaveSkills) && !_.isNull(jobDetails.goodToHaveSkills) && jobDetails.goodToHaveSkills && (_.isUndefined(jobDetails.goodToHaveSkillsTextarea) || _.isNull(jobDetails.goodToHaveSkillsTextarea) || (jobDetails.goodToHaveSkillsTextarea === '') || (jobDetails.goodToHaveSkillsTextarea.trim() === ''))) {
            return false;
        }
        if (!_.isUndefined(jobDetails.mandatoryInterviewQuestions) && !_.isNull(jobDetails.mandatoryInterviewQuestions) && jobDetails.mandatoryInterviewQuestions && (_.isUndefined(jobDetails.mandatoryInterviewQuestionsTextarea) || _.isNull(jobDetails.mandatoryInterviewQuestionsTextarea) || (jobDetails.mandatoryInterviewQuestionsTextarea === '') || (jobDetails.mandatoryInterviewQuestionsTextarea.trim() === ''))) {
            return false;
        }
        if (!_.isUndefined(jobDetails.mandatoryCodingQuestions) && !_.isNull(jobDetails.mandatoryCodingQuestions) && jobDetails.mandatoryCodingQuestions && (_.isUndefined(jobDetails.mandatoryCodingQuestionsTextarea) || _.isNull(jobDetails.mandatoryCodingQuestionsTextarea) || (jobDetails.mandatoryCodingQuestionsTextarea === '') || (jobDetails.mandatoryCodingQuestionsTextarea.trim() === ''))) {
            return false;
        }
        if (!_.isUndefined(jobDetails.customFeedbackSheet) && !_.isNull(jobDetails.customFeedbackSheet) && jobDetails.customFeedbackSheet && (_.isUndefined(jobDetails.customFeedbackSheetFile) || _.isNull(jobDetails.customFeedbackSheetFile) || (jobDetails.customFeedbackSheetFile === '') || (jobDetails.customFeedbackSheetFile.trim() === ''))) {
            return false;
        }
        return true;
    };

    const submitHandler = (e) => {
        e.preventDefault();
        validateForm();
        if (checkedValidateForm()) {
            setIsSubmit(true);
            let params = {
                job_title: jobDetails.jobTitle,
                end_date: jobDetails.endDate,
                bu_code: jobDetails.buCode,
                project_code: jobDetails.projectCode,
                job_location: jobDetails.jobLocation,
                job_type: jobDetails.jobType,
                job_pay: jobDetails.jobPay,
                experience_min_year: jobDetails.experienceMinYear,
                experience_max_year: jobDetails.experienceMaxYear,
                job_discription: jobDetails.jobDiscription,
                upload_jd: jobDetails.uploadJD,
                interview_duration: jobDetails.interviewDuration,
                is_coding_test_mandatory: jobDetails.isCodingTestMandatory,
                primary_skill: jobDetails.primarySkill,
                secondary_skill: jobDetails.secondarySkill,
                good_to_have_skills: jobDetails.goodToHaveSkills,
                good_to_have_skills_textarea: jobDetails.goodToHaveSkillsTextarea,
                mandatory_interview_questions: jobDetails.mandatoryInterviewQuestions,
                mandatory_interview_questions_textarea: jobDetails.mandatoryInterviewQuestionsTextarea,
                mandatory_coding_questions: jobDetails.mandatoryCodingQuestions,
                mandatory_coding_questions_textarea: jobDetails.mandatoryCodingQuestionsTextarea,
                custom_feedback_sheet: jobDetails.customFeedbackSheet,
                custom_feedback_sheet_file: jobDetails.customFeedbackSheetFile,
                job_created_by_id: userDetails.id,
                job_created_by_name: userDetails.first_name + " " + userDetails.last_name,
                status: jobDetails.status
            }

            const url = (jobId != undefined && jobId != "") ? ("api/updateJob/" + jobId) : "api/createJob";
            fetch(`${API_URL}${url}`, {
                method: (jobId != undefined && jobId != "") ? 'PUT' : 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((response) => {
                    setIsSubmit(false);
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                        setTimeout(() => {
                            navigate("/dashboard", { replace: true });
                        }, 3000);
                    }
                    else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                    }
                })
                .catch((error) => {
                    if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
                    }
                    setIsSubmit(false);
                });
        }
    };

    useEffect(() => {
        setJobDetails({
            jobTitle: "",
            endDate: null,
            buCode: "",
            projectCode: "",
            jobLocation: "",
            jobPay: "",
            jobType: "Permanent",
            experienceMinYear: "",
            experienceMaxYear: "",
            jobDiscription: "",
            uploadJD: "",
            interviewDuration: "",
            isCodingTestMandatory: false,
            primarySkill: [],
            secondarySkill: [],
            goodToHaveSkills: false,
            goodToHaveSkillsTextarea: "",
            mandatoryInterviewQuestions: false,
            mandatoryInterviewQuestionsTextarea: "",
            mandatoryCodingQuestions: false,
            mandatoryCodingQuestionsTextarea: "",
            customFeedbackSheet: false,
            customFeedbackSheetFile: "",
            status: JOB_STATUS_OPEN
        })
        if (jobId != null && jobId != undefined && jobId != "") {
            const fetchData = async () => {
                try {
                    await getJobDetail();
                } catch (error) {
                    console.error(error);
                }
            };
            fetchData();
        }
        getSkills();
    }, []);

    const [skillsOptions, setSkillsOptions] = useState([]);
    const [primarySkills, setPrimarySkills] = useState([]);
    const [secondarySkills, setSecondarySkills] = useState([]);
    const getSkills = () => {
        fetch(`${API_URL}api/getSkills`)
            .then((response) => response.json())
            .then((response) => {
                var allSkills = [];
                response.data.map((item) => {
                    allSkills.push({
                        name: item.name,
                        value: item.name,
                        label: item.name
                    })
                })
                setSkillsOptions(allSkills)
            })
            .catch((error) => {
                setSkillsOptions([])
            });
    }
    useEffect(() => {
        if (primarySkills != undefined && primarySkills != null && primarySkills != "") {
            setJobDetails({
                ...jobDetails,
                primarySkill: primarySkills.length > 0 ? primarySkills.map(item => item.value) : [],
            });
        }
    }, [primarySkills])

    useEffect(() => {
        if (secondarySkills != undefined && secondarySkills != null && secondarySkills != "") {
            setJobDetails({
                ...jobDetails,
                secondarySkill: secondarySkills.length > 0 ? secondarySkills.map(item => item.value) : [],
            });
        }
    }, [secondarySkills])

    const onRemovePrimarySkills = (selectedList) => {
        setPrimarySkills(primarySkills.filter((_, i) => i !== selectedList))
    }
    const onSelectPrimarySkills = (selectedList) => {
        setPrimarySkills([...primarySkills, selectedList]);
    }

    const onRemoveSecondarySkills = (selectedList) => {
        setSecondarySkills(secondarySkills.filter((_, i) => i !== selectedList))
    }
    const onSelectSecondarSkills = (selectedList) => {
        setSecondarySkills([...secondarySkills, selectedList]);
    }

    const getJobDetail = async () => {
        setIsSubmit(true);
        try {
            const apiResponse = await fetch(`${API_URL}api/jobs/${jobId}`,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${userDetails.token}`,
                    }
                }
            );
            const response = await apiResponse.json();
            setJobDetails(
                {
                    jobTitle: response.data.job_title,
                    endDate: response.data.end_date ? new Date(response.data.end_date) : null,
                    buCode: response.data.bu_code,
                    projectCode: response.data.project_code,
                    jobLocation: response.data.job_location,
                    jobPay: response.data.job_pay,
                    jobType: response.data.job_type,
                    experienceMinYear: response.data.experience_min_year,
                    experienceMaxYear: response.data.experience_max_year,
                    jobDiscription: response.data.job_discription,
                    uploadJD: response.data.upload_jd,
                    interviewDuration: response.data.interview_duration,
                    isCodingTestMandatory: response.data.is_coding_test_mandatory,
                    primarySkill: response.data.primary_skill,
                    secondarySkill: response.data.secondary_skill,
                    goodToHaveSkills: response.data.good_to_have_skills,
                    goodToHaveSkillsTextarea: response.data.good_to_have_skills_textarea,
                    mandatoryInterviewQuestions: response.data.mandatory_interview_questions,
                    mandatoryInterviewQuestionsTextarea: response.data.mandatory_interview_questions_textarea,
                    mandatoryCodingQuestions: response.data.mandatory_coding_questions,
                    mandatoryCodingQuestionsTextarea: response.data.mandatory_coding_questions_textarea,
                    customFeedbackSheet: response.data.custom_feedback_sheet,
                    customFeedbackSheetFile: response.data.custom_feedback_sheet_file,
                    status: response.data.status
                }
            )
            var existedPrimarySkills = [];
            if (response.data.primary_skill.length > 0) {
                response.data.primary_skill.map((item) => {
                    existedPrimarySkills.push({
                        name: item,
                        value: item,
                        label: item
                    })
                })
            }
            setPrimarySkills(existedPrimarySkills)
            var existedSecondarySkills = [];
            if (response.data.secondary_skill.length > 0) {
                response.data.secondary_skill.map((item) => {
                    existedSecondarySkills.push({
                        name: item,
                        value: item,
                        label: item
                    })
                })
            }
            setSecondarySkills(existedSecondarySkills)
            setIsSubmit(false);
        }
        catch (err) {
            setIsSubmit(false);
            console.log(err)
        }
    }

    const uploadCSV = (event, name) => {
        event.preventDefault();
        var file = event.target.files;
        if (file && file.length > 0) {
            const reader = new FileReader();
            reader.onload = async (event) => {
                const arrayBuffer = event.target.result;
                try {
                    const result = await window.mammoth.convertToHtml({ arrayBuffer: arrayBuffer });
                    setJobDetails({
                        ...jobDetails,
                        [name]: result.value
                    });
                } catch (error) {
                    console.error('Error converting to HTML:', error);
                    setJobDetails({
                        ...jobDetails,
                        [name]: ""
                    });
                }
            };
            reader.readAsBinaryString(event.target.files[0]);
        }
    }

    const handleClear = () => {
        setJobDetails({
            ...jobDetails,
            endDate: null
        })
    };

    const CustomInput = forwardRef(({ value, onClick, onClear }, ref) => (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <input
                value={value}
                onClick={onClick}
                ref={ref}
                readOnly
                style={{ width: '100%' }}
                placeholder="dd/mm/yyyy"
                className="form-control" // Assuming you're using Bootstrap for styling
            />
            {value && (
                <button
                    type="button"
                    onClick={onClear}
                    style={{
                        position: 'absolute',
                        right: '5px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                        background: 'none',
                        border: 'none',
                        fontSize: '16px',
                        color: '#d9534f' // Bootstrap danger color
                    }}
                >
                    &#x2715; {/* Unicode character for an "X" mark */}
                </button>
            )}
        </div>
    ));

    return (
        <Fragment>
            <Toast ref={toast} />
            {
                isSubmit &&
                <div className="spinner-loader">
                    <Loader type="spinner-circle" bgColor="#2F2F74" color="#2F2F74" size={100} />
                </div>
            }
            <div className="container-fluid create-job-container fixed-padding">
                <div className="row">
                    <div className="col-12 px-0">
                        <HeaderScreen />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="row mt-2">
                            <div className="col-12 pageTitle_fixed">

                                <p className='employer-dashboard-jobs-posted mb-0'>{jobId ? "Edit Job" : "Create Job"}</p>

                            </div>
                        </div>
                        <Card className="my-2">
                            <Card.Body>
                                <Form className="mt-4" autoComplete="off">
                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <div className="row">
                                                <div className="col-md-6 col-12">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Job title / Designation / Position*</Form.Label>
                                                        <Form.Control value={jobDetails ? jobDetails.jobTitle : ""} type="text" id="jobTitle" name="jobTitle" placeholder="Job title / Designation / Position" onChange={changeHandler} maxLength={150} autoComplete="off" />
                                                        {
                                                            !_.isUndefined(jobTitleError) && !_.isNull(jobTitleError) && (jobTitleError !== '') &&
                                                            <Alert variant="danger" className="mt-2">{jobTitleError}</Alert>
                                                        }
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>End date</Form.Label>
                                                        <DatePicker className="form-control" selected={jobDetails ? jobDetails.endDate : new Date()} minDate={new Date()} dateFormat="dd/MM/yyyy" onChange={(date) => setJobDetails({ ...jobDetails, endDate: date })} customInput={<CustomInput onClear={() => handleClear()} />} />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>BU code</Form.Label>
                                                        <Form.Control value={jobDetails ? jobDetails.buCode : ""} type="text" id="buCode" name="buCode" placeholder="BU code" onChange={changeHandler} maxLength={10} autoComplete="off" />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Project code*</Form.Label>
                                                        <Form.Control value={jobDetails ? jobDetails.projectCode : ""} type="text" id="projectCode" name="projectCode" placeholder="Project code" onChange={changeHandler} maxLength={10} autoComplete="off" />
                                                        {
                                                            !_.isUndefined(projectCodeError) && !_.isNull(projectCodeError) && (projectCodeError !== '') &&
                                                            <Alert variant="danger" className="mt-2">{projectCodeError}</Alert>
                                                        }
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Job location*</Form.Label>
                                                        <Form.Control value={jobDetails ? jobDetails.jobLocation : ""} type="text" id="jobLocation" name="jobLocation" placeholder="Job location" onChange={changeHandler} autoComplete="off" />
                                                        {
                                                            !_.isUndefined(jobLocationError) && !_.isNull(jobLocationError) && (jobLocationError !== '') &&
                                                            <Alert variant="danger" className="mt-2">{jobLocationError}</Alert>
                                                        }
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Pay</Form.Label>
                                                        <Form.Control value={jobDetails ? jobDetails.jobPay : ""} type="text" id="jobPay" name="jobPay" placeholder="Enter Pay / CTC" onChange={changeHandler} maxLength={10} autoComplete="off" />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <Form.Group as={Row} className="mb-3">
                                                        <Form.Label column sm="3">
                                                            Job type
                                                        </Form.Label>
                                                        <Col sm="9" className="my-auto">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <Form.Check
                                                                        type="radio"
                                                                        id="permanent"
                                                                        value="Permanent"
                                                                        label="Permanent"
                                                                        name="jobType"
                                                                        checked={jobDetails && jobDetails.jobType == "Permanent"}
                                                                        onChange={changeHandler}
                                                                    />
                                                                </div>
                                                                <div className="col-6">
                                                                    <Form.Check
                                                                        type="radio"
                                                                        id="contract"
                                                                        value="Contract"
                                                                        label="Contract"
                                                                        name="jobType"
                                                                        checked={jobDetails && jobDetails.jobType == "Contract"}
                                                                        onChange={changeHandler}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {
                                                                !_.isUndefined(jobTypeError) && !_.isNull(jobTypeError) && (jobTypeError !== '') &&
                                                                <Alert variant="danger" className="mt-2">{jobTypeError}</Alert>
                                                            }
                                                        </Col>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <Form.Group as={Row} className="mb-3">
                                                        <Form.Label column sm="5" className="my-auto">
                                                            Experience in Years*
                                                        </Form.Label>
                                                        <Col sm="7 ">
                                                            <div className="row">
                                                                <div className="col-6 pl-2">
                                                                    <Form.Control value={jobDetails ? jobDetails.experienceMinYear : ""} type="text" id="experienceMinYear" name="experienceMinYear" placeholder="Min" onChange={changeHandler} maxLength={2} autoComplete="off" />
                                                                </div>
                                                                <div className="col-6 pl-0">
                                                                    <Form.Control value={jobDetails ? jobDetails.experienceMaxYear : ""} type="text" id="experienceMaxYear" name="experienceMaxYear" placeholder="Max" onChange={changeHandler} maxLength={2} autoComplete="off" />
                                                                </div>
                                                            </div>
                                                            {
                                                                !_.isUndefined(experienceMinYearError) && !_.isNull(experienceMinYearError) && (experienceMinYearError !== '') &&
                                                                <Alert variant="danger" className="mt-2">{experienceMinYearError}</Alert>
                                                            }
                                                            {
                                                                !_.isUndefined(experienceMaxYearError) && !_.isNull(experienceMaxYearError) && (experienceMaxYearError !== '') &&
                                                                <Alert variant="danger" className="mt-2">{experienceMaxYearError}</Alert>
                                                            }
                                                        </Col>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-12">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Job Description*</Form.Label>
                                                        <Form.Control value={jobDetails ? jobDetails.jobDiscription : ""} rows={3} as="textarea" id="jobDiscription" name="jobDiscription" placeholder="Job discription" onChange={changeHandler} />
                                                        {
                                                            !_.isUndefined(jobDiscriptionError) && !_.isNull(jobDiscriptionError) && (jobDiscriptionError !== '') &&
                                                            <Alert variant="danger" className="mt-2">{jobDiscriptionError}</Alert>
                                                        }
                                                    </Form.Group>
                                                </div>
                                                <div className="col-12">
                                                    <div className="col-md-6 col-12">
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Upload JD*</Form.Label>
                                                            <Form.Control type="file" id="uploadJD" name="uploadJD" placeholder="Upload JD" onChange={(e) => uploadFile(e, 'uploadJD')} maxLength={10} autoComplete="off" />
                                                            {
                                                                !_.isUndefined(uploadJDError) && !_.isNull(uploadJDError) && (uploadJDError !== '') &&
                                                                <Alert variant="danger" className="mt-2">{uploadJDError}</Alert>
                                                            }
                                                            {
                                                                jobDetails && jobDetails.uploadJD && (
                                                                    <Alert variant="info" className="d-flex justify-content-between align-items-center mt-2">
                                                                        <a target="_blank" href={jobDetails.uploadJD}>
                                                                            {(new URL(jobDetails.uploadJD).pathname.split('/').pop().replaceAll("%20", " "))}
                                                                        </a>
                                                                    </Alert>
                                                                )

                                                            }
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 my-auto">
                                                    <Form.Group as={Row} className="mb-3">
                                                        <Form.Label column sm="5">
                                                            Interview duration
                                                        </Form.Label>
                                                        <Col sm="7" className="my-auto">
                                                            <div className="row">
                                                                <div className="col-4 pl-2">
                                                                    <Form.Check
                                                                        checked={jobDetails && jobDetails.interviewDuration == 30}
                                                                        type="radio"
                                                                        id="interviewDuration30"
                                                                        value={30}
                                                                        label="30 min"
                                                                        name="interviewDuration"
                                                                        onChange={changeHandler}
                                                                    />
                                                                </div>
                                                                <div className="col-4 px-0">
                                                                    <Form.Check
                                                                        checked={jobDetails && jobDetails.interviewDuration == 45}
                                                                        type="radio"
                                                                        id="interviewDuration45"
                                                                        value={45}
                                                                        label="45 min"
                                                                        name="interviewDuration"
                                                                        onChange={changeHandler}
                                                                    />
                                                                </div>
                                                                <div className="col-4 px-0">
                                                                    <Form.Check
                                                                        checked={jobDetails && jobDetails.interviewDuration == 60}
                                                                        type="radio"
                                                                        id="interviewDuration60"
                                                                        value={60}
                                                                        label="60 min"
                                                                        name="interviewDuration"
                                                                        onChange={changeHandler}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {
                                                                !_.isUndefined(interviewDurationError) && !_.isNull(interviewDurationError) && (interviewDurationError !== '') &&
                                                                <Alert variant="danger" className="mt-2">{interviewDurationError}</Alert>
                                                            }
                                                        </Col>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 col-12 my-auto">
                                                    <>
                                                        <InputGroup className="mb-3">
                                                            <InputGroup.Checkbox checked={jobDetails ? jobDetails.isCodingTestMandatory : false} onChange={changeHandler} aria-label="Checkbox for following text input" name="is_coding_test_mandatory" />
                                                            <Form.Control className="searchBox" value="Coding test mandatory ?" disabled />
                                                        </InputGroup>
                                                    </>
                                                </div>
                                                <div className="col-6">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Primary skill*</Form.Label>
                                                        <ReactTags
                                                            labelText="Select Primary Skills"
                                                            selected={primarySkills}
                                                            suggestions={skillsOptions}
                                                            allowNew={true}
                                                            onAdd={onSelectPrimarySkills}
                                                            onDelete={onRemovePrimarySkills}
                                                            placeholderText="Primary Skill"
                                                            noOptionsText="No Matching Primary Skill"
                                                        />
                                                        {
                                                            !_.isUndefined(primarySkillError) && !_.isNull(primarySkillError) && (primarySkillError !== '') &&
                                                            <Alert variant="danger" className="mt-2">{primarySkillError}</Alert>
                                                        }
                                                    </Form.Group>
                                                </div>
                                                <div className="col-6">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Secondary skill*</Form.Label>
                                                        <ReactTags
                                                            labelText="Select Secondary Skills"
                                                            selected={secondarySkills}
                                                            suggestions={skillsOptions}
                                                            allowNew={true}
                                                            onAdd={onSelectSecondarSkills}
                                                            onDelete={onRemoveSecondarySkills}
                                                            placeholderText="Secondary Skill"
                                                            noOptionsText="No Matching Secondary Skill"
                                                        />
                                                        {
                                                            !_.isUndefined(secondarySkillError) && !_.isNull(secondarySkillError) && (secondarySkillError !== '') &&
                                                            <Alert variant="danger" className="mt-2">{secondarySkillError}</Alert>
                                                        }
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="row">
                                                <div className="col-12">
                                                    <Form.Group className="mb-3">
                                                        <Form.Check
                                                            type="checkbox"
                                                            id="goodToHaveSkills"
                                                            name="goodToHaveSkills"
                                                            label="Good to have skills?"
                                                            onChange={changeHandler}
                                                            checked={jobDetails && jobDetails.goodToHaveSkills}
                                                        />
                                                        {
                                                            jobDetails && jobDetails.goodToHaveSkills &&
                                                            <Fragment>
                                                                <Form.Label>Provide mandatory Good to have skills*</Form.Label>
                                                                <CKEditor
                                                                    config={{ placeholder: "Provide mandatory Good to have skills*" }}
                                                                    editor={ClassicEditor}
                                                                    data={jobDetails.goodToHaveSkillsTextarea}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        setJobDetails({ ...jobDetails, goodToHaveSkillsTextarea: data });
                                                                    }}
                                                                />
                                                                {
                                                                    !_.isUndefined(goodToHaveSkillsTextareaError) && !_.isNull(goodToHaveSkillsTextareaError) && (goodToHaveSkillsTextareaError !== '') &&
                                                                    <Alert variant="danger" className="mt-2">{goodToHaveSkillsTextareaError}</Alert>
                                                                }
                                                                <div className="col-6">
                                                                    <Form.Group className="mb-3">
                                                                        <Form.Label>Upload good to have skills (doc, .docx)</Form.Label>
                                                                        <Form.Control accept=".doc, .docx" onChange={(e) => uploadCSV(e, 'goodToHaveSkillsTextarea')} type="file" id="uploadCodingQuestions" placeholder="Upload coding questions" maxLength={10} autoComplete="off" />
                                                                    </Form.Group>
                                                                </div>
                                                            </Fragment>
                                                        }
                                                    </Form.Group>
                                                </div>
                                                <div className="col-12">
                                                    <Form.Group className="mb-3">
                                                        <Form.Check
                                                            type="checkbox"
                                                            id="mandatoryInterviewQuestions"
                                                            name="mandatoryInterviewQuestions"
                                                            label="Mandatory interview questions?"
                                                            onChange={changeHandler}
                                                            checked={jobDetails && jobDetails.mandatoryInterviewQuestions}
                                                        />
                                                        {
                                                            jobDetails && jobDetails.mandatoryInterviewQuestions &&
                                                            <Fragment>
                                                                <Form.Label>Provide mandatory Mandatory interview questions*</Form.Label>
                                                                <CKEditor
                                                                    config={{ placeholder: "Provide mandatory Mandatory interview questions*" }}
                                                                    editor={ClassicEditor}
                                                                    data={jobDetails.mandatoryInterviewQuestionsTextarea}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        setJobDetails({ ...jobDetails, mandatoryInterviewQuestionsTextarea: data });
                                                                    }}
                                                                />
                                                                {
                                                                    !_.isUndefined(mandatoryInterviewQuestionsTextareaError) && !_.isNull(mandatoryInterviewQuestionsTextareaError) && (mandatoryInterviewQuestionsTextareaError !== '') &&
                                                                    <Alert variant="danger" className="mt-2">{mandatoryInterviewQuestionsTextareaError}</Alert>
                                                                }
                                                                <div className="col-6">
                                                                    <Form.Group className="mb-3">
                                                                        <Form.Label>Upload Interview questions (doc, .docx)</Form.Label>
                                                                        <Form.Control accept=".doc, .docx" onChange={(e) => uploadCSV(e, 'mandatoryInterviewQuestionsTextarea')} type="file" id="mandatoryInterviewQuestionsTextarea" placeholder="Upload Interview questions" maxLength={10} autoComplete="off" />
                                                                    </Form.Group>
                                                                </div>
                                                            </Fragment>
                                                        }
                                                    </Form.Group>
                                                </div>
                                                <div className="col-12">
                                                    <Form.Group className="mb-3">
                                                        <Form.Check
                                                            type="checkbox"
                                                            id="mandatoryCodingQuestions"
                                                            name="mandatoryCodingQuestions"
                                                            label="Mandatory coding questions?"
                                                            onChange={changeHandler}
                                                            checked={jobDetails && jobDetails.mandatoryCodingQuestions}
                                                        />
                                                        {
                                                            jobDetails && jobDetails.mandatoryCodingQuestions &&
                                                            <Fragment>
                                                                <Form.Label>Provide mandatory Mandatory coding questions*</Form.Label>
                                                                <CKEditor
                                                                    config={{ placeholder: "Provide mandatory Mandatory coding questions*" }}
                                                                    editor={ClassicEditor}
                                                                    data={jobDetails.mandatoryCodingQuestionsTextarea}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        setJobDetails({ ...jobDetails, mandatoryCodingQuestionsTextarea: data });
                                                                    }}
                                                                />
                                                                {
                                                                    !_.isUndefined(mandatoryCodingQuestionsTextareaError) && !_.isNull(mandatoryCodingQuestionsTextareaError) && (mandatoryCodingQuestionsTextareaError !== '') &&
                                                                    <Alert variant="danger" className="mt-2">{mandatoryCodingQuestionsTextareaError}</Alert>
                                                                }
                                                                <div className="col-6">
                                                                    <Form.Group className="mb-3">
                                                                        <Form.Label>Upload coding questions (doc, .docx)</Form.Label>
                                                                        <Form.Control accept=".doc, .docx" onChange={(e) => uploadCSV(e, 'mandatoryCodingQuestionsTextarea')} type="file" id="uploadCodingQuestions" placeholder="Upload coding questions" maxLength={10} autoComplete="off" />
                                                                    </Form.Group>
                                                                </div>
                                                            </Fragment>
                                                        }
                                                    </Form.Group>
                                                </div>
                                                <div className="col-12">
                                                    <Form.Group className="mb-3">
                                                        <Form.Check
                                                            type="checkbox"
                                                            id="customFeedbackSheet"
                                                            name="customFeedbackSheet"
                                                            label="Custom feedback sheet?"
                                                            onChange={changeHandler}
                                                            checked={jobDetails && jobDetails.customFeedbackSheet}
                                                        />
                                                        {
                                                            jobDetails && jobDetails.customFeedbackSheet &&
                                                            <Fragment>
                                                                <Form.Label>Upload Feedback Sheet*</Form.Label>
                                                                <Form.Control type="file" id="customFeedbackSheetFile" name="customFeedbackSheetFile" placeholder="Upload Feedback Sheet*" onChange={(e) => uploadFile(e, 'customFeedbackSheetFile')} maxLength={10} autoComplete="off" />
                                                                {
                                                                    !_.isUndefined(customFeedbackSheetFileError) && !_.isNull(customFeedbackSheetFileError) && (customFeedbackSheetFileError !== '') &&
                                                                    <Alert variant="danger" className="mt-2">{customFeedbackSheetFileError}</Alert>
                                                                }
                                                                {
                                                                    jobDetails && jobDetails.customFeedbackSheetFile && (
                                                                        <Alert variant="info" className="d-flex justify-content-between align-items-center mt-2">
                                                                            <a target="_blank" href={jobDetails.customFeedbackSheetFile}>
                                                                                {(new URL(jobDetails.customFeedbackSheetFile).pathname.split('/').pop().replaceAll("%20", " "))}
                                                                            </a>
                                                                        </Alert>
                                                                    )

                                                                }
                                                            </Fragment>
                                                        }
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 text-end">
                                            {/* <Button className="create-job-form-cancel-button" onClick={cancelHandler}>Cancel</Button> */}

                                            <Button variant="outline-primary" className='me-2' onClick={handleShow}>Cancel</Button>
                                            <CustomModal showModal={showModal} handleClose={handleClose} />

                                            <Button variant="primary" type="submit" onClick={submitHandler}>Submit</Button>
                                        </div>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const CustomModal = ({ showModal, handleClose }) => {
    const navigate = useNavigate();
    return (
        <Modal show={showModal} onHide={handleClose} centered className="confirm-model">
            <Modal.Body className="text-center" >
                <h5>Discard all the changes?</h5>
            </Modal.Body>
            <Modal.Footer style={{ border: 'none', justifyContent: 'center' }}>
                <Button variant="primary" onClick={() => navigate("/dashboard", { replace: true })}>
                    Save
                </Button>
                <Button variant="outline-primary" onClick={handleClose}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateJobScreen;