import _ from 'lodash';
import { Toast } from "primereact/toast";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Alert, Button, Card, Form, Modal, Table } from 'react-bootstrap';
import { IoLocationOutline } from "react-icons/io5";
import Loader from "react-js-loader";
import { useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import default_profile_picture from "../../Assets/default_profile_picture.png";
import { API_URL, allowedImageTypes, commonDateFormat, getAuthUserFromSessionStorage, hasNumber, imageFileTypeErrorMessage } from "../../common/constant";
import { SUB_ADMIN, SUPER_ADMIN } from "../../common/roles";
import HeaderScreen from "../HeaderScreen/HeaderScreen";
import "./AdminProfileScreen.css";
import { ReactTags } from 'react-tag-autocomplete';

const AdminProfileScreen = () => {
    const navigate = useNavigate();
    const [userDetails, setUserDetails] = useState(getAuthUserFromSessionStorage());
    const [isLoading, setIsLoading] = useState(false);
    const [formErrors, setFormErrors] = useState("");
    const [formSuccess, setFormSuccess] = useState("");
    const [isEditProfile, setIsEditProfile] = useState(false);
    const [showBankDetails, setShowBankDetails] = useState(false);
    const [showFormErrors, setShowFormErrors] = useState(false);
    const [showFormSuccess, setShowFormSuccess] = useState(false);
    const [showLocationPreferences, setShowLocationPreferences] = useState(false);
    const [locationPreferences, setLocationPreferences] = useState([]);
    const [showWorkExperience, setShowWorkExperience] = useState(false);
    const [workExperience, setWorkExperience] = useState({});
    const [showEducations, setShowEducations] = useState(false);
    const [educations, setEducations] = useState({});
    const [showSkills, setShowSkills] = useState(false);
    const [skills, setSkills] = useState([]);

    const [secondarySkills, setSecondarySkills] = useState([]);
    const [showSecondarySkills, setShowSecondarySkills] = useState(false);

    const [showCertificates, setShowCertificates] = useState(false);
    const [certificates, setCertificates] = useState({});
    const [bankDetails, setBankDetails] = useState({});
    const [showDocuments, setShowDocuments] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [showLanguages, setShowLanguages] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [cities, setCities] = useState([]);
    const [interviewerProfile, setInterviewerProfile] = useState({});
    const [designationOptions, setDesignationOptions] = useState([]);

    const [city, setCity] = useState("");
    const [interviewerState, setState] = useState("");
    const [interviewerCountry, setCountry] = useState("");

    const [companyList, setCompanyList] = useState([]);

    const [skillsOptions, setSkillsOptions] = useState([]);
    const [languageOptions, setLanguageOptions] = useState([
        { id: 1, name: "Abkhaz" },
        { id: 2, name: "Afar" },
        { id: 3, name: "Afrikaans" },
        { id: 4, name: "Akan" },
        { id: 5, name: "Albanian" },
        { id: 6, name: "Amharic" },
        { id: 7, name: "Arabic" },
        { id: 8, name: "Aragonese" },
        { id: 9, name: "Armenian" },
        { id: 10, name: "Assamese" },
        { id: 11, name: "Avaric" },
        { id: 12, name: "Avestan" },
        { id: 13, name: "Aymara" },
        { id: 14, name: "Azerbaijani" },
        { id: 15, name: "Bambara" },
        { id: 16, name: "Bashkir" },
        { id: 17, name: "Basque" },
        { id: 18, name: "Belarusian" },
        { id: 19, name: "Bengali" },
        { id: 20, name: "Bihari" },
        { id: 21, name: "Bislama" },
        { id: 22, name: "Bosnian" },
        { id: 23, name: "Breton" },
        { id: 24, name: "Bulgarian" },
        { id: 25, name: "Burmese" },
        { id: 26, name: "Catalan; Valencian" },
        { id: 27, name: "Chamorro" },
        { id: 28, name: "Chechen" },
        { id: 29, name: "Chichewa; Chewa; Nyanja" },
        { id: 30, name: "Chinese" },
        { id: 31, name: "Chuvash" },
        { id: 32, name: "Cornish" },
        { id: 33, name: "Corsican" },
        { id: 34, name: "Cree" },
        { id: 35, name: "Croatian" },
        { id: 36, name: "Czech" },
        { id: 37, name: "Danish" },
        { id: 38, name: "Divehi; Dhivehi; Maldivian;" },
        { id: 39, name: "Dutch" },
        { id: 40, name: "English" },
        { id: 41, name: "Esperanto" },
        { id: 42, name: "Estonian" },
        { id: 43, name: "Ewe" },
        { id: 44, name: "Faroese" },
        { id: 45, name: "Fijian" },
        { id: 46, name: "Finnish" },
        { id: 47, name: "French" },
        { id: 48, name: "Fula; Fulah; Pulaar; Pular" },
        { id: 49, name: "Galician" },
        { id: 50, name: "Georgian" },
        { id: 51, name: "German" },
        { id: 52, name: "Greek, Modern" },
        { id: 53, name: "Guaraní" },
        { id: 54, name: "Gujarati" },
        { id: 55, name: "Haitian; Haitian Creole" },
        { id: 56, name: "Hausa" },
        { id: 57, name: "Hebrew (modern)" },
        { id: 58, name: "Herero" },
        { id: 59, name: "Hindi" },
        { id: 60, name: "Hiri Motu" },
        { id: 61, name: "Hungarian" },
        { id: 62, name: "Interlingua" },
        { id: 63, name: "Indonesian" },
        { id: 64, name: "Interlingue" },
        { id: 65, name: "Irish" },
        { id: 66, name: "Igbo" },
        { id: 67, name: "Inupiaq" },
        { id: 68, name: "Ido" },
        { id: 69, name: "Icelandic" },
        { id: 70, name: "Italian" },
        { id: 71, name: "Inuktitut" },
        { id: 72, name: "Japanese" },
        { id: 73, name: "Javanese" },
        { id: 74, name: "Kalaallisut, Greenlandic" },
        { id: 75, name: "Kannada" },
        { id: 76, name: "Kanuri" },
        { id: 77, name: "Kashmiri" },
        { id: 78, name: "Kazakh" },
        { id: 79, name: "Khmer" },
        { id: 80, name: "Kikuyu, Gikuyu" },
        { id: 81, name: "Kinyarwanda" },
        { id: 82, name: "Kirghiz, Kyrgyz" },
        { id: 83, name: "Komi" },
        { id: 84, name: "Kongo" },
        { id: 85, name: "Korean" },
        { id: 86, name: "Kurdish" },
        { id: 87, name: "Kwanyama, Kuanyama" },
        { id: 88, name: "Latin" },
        { id: 89, name: "Luxembourgish, Letzeburgesch" },
        { id: 90, name: "Luganda" },
        { id: 91, name: "Limburgish, Limburgan, Limburger" },
        { id: 92, name: "Lingala" },
        { id: 93, name: "Lao" },
        { id: 94, name: "Lithuanian" },
        { id: 95, name: "Luba-Katanga" },
        { id: 96, name: "Latvian" },
        { id: 97, name: "Manx" },
        { id: 98, name: "Macedonian" },
        { id: 99, name: "Malagasy" },
        { id: 100, name: "Malay" },
        { id: 101, name: "Malayalam" },
        { id: 102, name: "Maltese" },
        { id: 103, name: "Māori" },
        { id: 104, name: "Marathi (Marāṭhī)" },
        { id: 105, name: "Marshallese" },
        { id: 106, name: "Mongolian" },
        { id: 107, name: "Nauru" },
        { id: 108, name: "Navajo, Navaho" },
        { id: 109, name: "Norwegian Bokmål" },
        { id: 110, name: "North Ndebele" },
        { id: 111, name: "Nepali" },
        { id: 112, name: "Ndonga" },
        { id: 113, name: "Norwegian Nynorsk" },
        { id: 114, name: "Norwegian" },
        { id: 115, name: "Nuosu" },
        { id: 116, name: "South Ndebele" },
        { id: 117, name: "Occitan" },
        { id: 118, name: "Ojibwe, Ojibwa" },
        { id: 119, name: "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic" },
        { id: 120, name: "Oromo" },
        { id: 121, name: "Oriya" },
        { id: 122, name: "Ossetian, Ossetic" },
        { id: 123, name: "Panjabi, Punjabi" },
        { id: 124, name: "Pāli" },
        { id: 125, name: "Persian" },
        { id: 126, name: "Polish" },
        { id: 127, name: "Pashto, Pushto" },
        { id: 128, name: "Portuguese" },
        { id: 129, name: "Quechua" },
        { id: 130, name: "Romansh" },
        { id: 131, name: "Kirundi" },
        { id: 132, name: "Romanian, Moldavian, Moldovan" },
        { id: 133, name: "Russian" },
        { id: 134, name: "Sanskrit (Saṁskṛta)" },
        { id: 135, name: "Sardinian" },
        { id: 136, name: "Sindhi" },
        { id: 137, name: "Northern Sami" },
        { id: 138, name: "Samoan" },
        { id: 139, name: "Sango" },
        { id: 140, name: "Serbian" },
        { id: 141, name: "Scottish Gaelic; Gaelic" },
        { id: 142, name: "Shona" },
        { id: 143, name: "Sinhala, Sinhalese" },
        { id: 144, name: "Slovak" },
        { id: 145, name: "Slovene" },
        { id: 146, name: "Somali" },
        { id: 147, name: "Southern Sotho" },
        { id: 148, name: "Spanish; Castilian" },
        { id: 149, name: "Sundanese" },
        { id: 150, name: "Swahili" },
        { id: 151, name: "Swati" },
        { id: 152, name: "Swedish" },
        { id: 153, name: "Tamil" },
        { id: 154, name: "Telugu" },
        { id: 155, name: "Tajik" },
        { id: 156, name: "Thai" },
        { id: 157, name: "Tigrinya" },
        { id: 158, name: "Tibetan Standard, Tibetan, Central" },
        { id: 159, name: "Turkmen" },
        { id: 160, name: "Tagalog" },
        { id: 161, name: "Tswana" },
        { id: 162, name: "Tonga (Tonga Islands)" },
        { id: 163, name: "Turkish" },
        { id: 164, name: "Tsonga" },
        { id: 165, name: "Tatar" },
        { id: 166, name: "Twi" },
        { id: 167, name: "Tahitian" },
        { id: 168, name: "Uighur, Uyghur" },
        { id: 169, name: "Ukrainian" },
        { id: 170, name: "Urdu" },
        { id: 171, name: "Uzbek" },
        { id: 172, name: "Venda" },
        { id: 173, name: "Vietnamese" },
        { id: 174, name: "Volapük" },
        { id: 175, name: "Walloon" },
        { id: 176, name: "Welsh" },
        { id: 177, name: "Wolof" },
        { id: 178, name: "Western Frisian" },
        { id: 179, name: "Xhosa" },
        { id: 180, name: "Yiddish" },
        { id: 181, name: "Yoruba" },
        { id: 182, name: "Zhuang, Chuang" }
    ]);

    const [testimonials, setTestimonials] = useState([]);
    const [showAddTestimonialDialog, setShowTestimonialDialog] = useState(false);
    const [deleteTestimonialId, setdeleteTestimonialId] = useState(null)
    const [removeTestimonialConfirmDialogShow, setRemoveTestimonialConfirmDialogShow] = useState(false);

    const [firstNameError, setIsFirstNameError] = useState("");
    const [lastNameError, setIsLastNameError] = useState("");
    const [emailError, setIsEmailError] = useState("");
    const [mobileNumberError, setIsMobileNumberError] = useState("");
    const [designationError, setIsDesignationError] = useState("");

    const handleTestimonialConfimationDialogOpen = (id) => {
        setRemoveTestimonialConfirmDialogShow(true);
        setdeleteTestimonialId(id)
    }

    const handleTestimonialConfimationDialogClose = () => {
        setRemoveTestimonialConfirmDialogShow(false);
        setdeleteTestimonialId(null)
    }

    const [testimonialFormData, setTestimonialFormData] = useState({
        id: null,
        message: '',
        author: '',
        designation: ''
    });

    const [authorNameError, setAuthorNameError] = useState("");
    const [testimonialDesignationError, setTestimonialDesignationError] = useState("");
    const [testimonialMessageError, setTestimonialMessageError] = useState("");

    const handleTestimonialDialogClose = () => {
        setShowTestimonialDialog(false);
        setTestimonialFormData({
            id: null,
            message: '',
            author: '',
            designation: ''
        })
    }

    const handleTestimonialChange = (e) => {
        const { name, value } = e.target;
        setTestimonialFormData({
            ...testimonialFormData,
            [name]: value
        });
    }

    const getTestimonials = () => {
        fetch(`${API_URL}api/getTestimonials`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            }
        })
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                    setTestimonials(response.data)
                }
                else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                }
            })
            .catch((error) => {
                setTestimonials([])
                if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
                }
            });
    }

    const validateTestimonialForm = () => {
        if (_.isUndefined(testimonialFormData.author) || _.isNull(testimonialFormData.author) || (testimonialFormData.author === '') || (testimonialFormData.author.trim() === '')) {
            setAuthorNameError("Author name is required!");
        }
        else if (!_.isUndefined(testimonialFormData.author) && !_.isNull(testimonialFormData.author) && (testimonialFormData.author !== '') && (testimonialFormData.author.trim() !== '') && ((testimonialFormData.author.trim().length < 4) || (testimonialFormData.author.trim().length > 20))) {
            setAuthorNameError("Author name must be between 4 to 20 characters!");
        }
        else {
            setAuthorNameError("");
        }
        if (_.isUndefined(testimonialFormData.designation) || _.isNull(testimonialFormData.designation) || (testimonialFormData.designation === '') || (testimonialFormData.designation.trim() === '')) {
            setTestimonialDesignationError("Designation is required!");
        }
        else if (!_.isUndefined(testimonialFormData.designation) && !_.isNull(testimonialFormData.designation) && (testimonialFormData.designation !== '') && (testimonialFormData.designation.trim() !== '') && ((testimonialFormData.designation.trim().length < 4) || (testimonialFormData.author.trim().length > 20))) {
            setTestimonialDesignationError("Designation must be between 4 to 20 characters!");
        }
        else {
            setTestimonialDesignationError("");
        }
        if (_.isUndefined(testimonialFormData.message) || _.isNull(testimonialFormData.message) || (testimonialFormData.message === '') || (testimonialFormData.message.trim() === '')) {
            setTestimonialMessageError("Message is required!");
        }
        else if (!_.isUndefined(testimonialFormData.message) && !_.isNull(testimonialFormData.message) && (testimonialFormData.message !== '') && (testimonialFormData.message.trim() !== '') && ((testimonialFormData.message.trim().length < 50) || (testimonialFormData.message.trim().length > 300))) {
            setTestimonialMessageError("Message must be between 50 to 300 characters!");
        }
        else {
            setTestimonialMessageError("");
        }
    };

    const checkedValidateTestimonialForm = () => {
        if (_.isUndefined(testimonialFormData.author) || _.isNull(testimonialFormData.author) || (testimonialFormData.author === '') || (testimonialFormData.author.trim() === '')) {
            return false;
        }
        else if (!_.isUndefined(testimonialFormData.author) && !_.isNull(testimonialFormData.author) && (testimonialFormData.author !== '') && (testimonialFormData.author.trim() !== '') && ((testimonialFormData.author.trim().length < 4) || (testimonialFormData.author.trim().length > 20))) {
            return false;
        }
        if (_.isUndefined(testimonialFormData.designation) || _.isNull(testimonialFormData.designation) || (testimonialFormData.designation === '') || (testimonialFormData.designation.trim() === '')) {
            return false;
        }
        else if (!_.isUndefined(testimonialFormData.designation) && !_.isNull(testimonialFormData.designation) && (testimonialFormData.designation !== '') && (testimonialFormData.designation.trim() !== '') && ((testimonialFormData.designation.trim().length < 4) || (testimonialFormData.designation.trim().length > 20))) {
            return false;
        }
        if (_.isUndefined(testimonialFormData.message) || _.isNull(testimonialFormData.message) || (testimonialFormData.message === '') || (testimonialFormData.message.trim() === '')) {
            return false;
        }
        else if (!_.isUndefined(testimonialFormData.message) && !_.isNull(testimonialFormData.message) && (testimonialFormData.message !== '') && (testimonialFormData.message.trim() !== '') && ((testimonialFormData.message.trim().length < 50) || (testimonialFormData.message.trim().length > 300))) {
            return false;
        }
        return true;
    };

    const editTestimonial = (item) => {
        setTestimonialFormData({
            id: item._id,
            author: item.author,
            designation: item.designation,
            message: item.message
        })
        setShowTestimonialDialog(true)
    }

    const handleTestimonialSubmit = (e) => {
        e.preventDefault();
        validateTestimonialForm();
        if (checkedValidateTestimonialForm()) {
            let params = {
                author: testimonialFormData.author,
                designation: testimonialFormData.designation,
                message: testimonialFormData.message,
            }
            const url = testimonialFormData.id !== null ? ("api/updateTestimonial/" + testimonialFormData.id) : "api/addTestimonial";
            fetch(`${API_URL}${url}`, {
                method: testimonialFormData.id !== null ? 'PUT' : 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((response) => {
                    handleTestimonialDialogClose();
                    setIsLoading(false);
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                        getTestimonials()
                    }
                    else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                    }
                })
                .catch((error) => {
                    handleTestimonialDialogClose();
                    setIsLoading(false);
                    if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
                    }
                });
        }
    }

    const removeTestimonial = () => {
        setIsLoading(true);
        fetch(`${API_URL}api/deleteTestimonial/${deleteTestimonialId}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            }
        })
            .then((response) => response.json())
            .then((response) => {
                handleTestimonialConfimationDialogClose();
                setIsLoading(false);
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                    getTestimonials()
                }
                else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                }
            })
            .catch((error) => {
                handleTestimonialConfimationDialogClose();
                setIsLoading(false);
                if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
                }
            });
    }

    const toast = useRef(null);

    const [monthNames, setMonthNames] = useState([
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ]);
    const [currentMonth, setCurrentMonth] = useState('January');
    const [currentYear, setCurrentYear] = useState(new Date());

    const [yearList, setYearList] = useState([]);

    const getYearList = (startYear) => {
        var currentYear = new Date().getFullYear(), years = [];
        startYear = 1980;
        while (startYear <= currentYear) {
            years.push(startYear++);
        }
        setYearList(years)
    }

    const [interviewState, setInterviewState] = useState([
        {
            scheduled_interview_count: 0,
            selected_interview_count: 0,
            rejected_interview_count: 0,
            no_show_by_interviewer_count: 0,
            no_show_by_candidate_count: 0
        }
    ]);

    const { id } = useParams();

    const [profileDetail, setProfileDetail] = useState({});

    useEffect(() => {
        if (!_.isUndefined(interviewerProfile) && !_.isNull(interviewerProfile) && _.isEmpty(interviewerProfile) && !_.isUndefined(id) && !_.isNull(id) && (id !== '')) {
            getInterviewerProfile();
            getAllCity();
            getYearList();
            getSkills();
            getCountryList();
            getPositions();
            getRalaSubAdminCompanyList(id);
            getTestimonials();
        }
    }, [interviewerProfile]);


    useEffect(() => {
        if (currentMonth && currentYear) {
            getInterviewerStates();
        }
    }, [currentMonth, currentYear])

    const getPositions = () => {
        fetch(`${API_URL}api/getPositions`)
            .then((response) => response.json())
            .then((response) => {
                const transformedOptions = response.data.map(option => ({
                    name: option.name,
                    value: option.name,
                    label: option.name
                }));
                setDesignationOptions(transformedOptions);
            })
            .catch((error) => {
                setDesignationOptions([])
            });
    }

    const getInterviewerStates = () => {
        setIsLoading(true)
        const index = monthNames.indexOf(currentMonth);
        fetch(`${API_URL}api/getInterviewerStates/${id}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify({
                currentMonth: (index + 1),
                currentYear: currentYear.getFullYear()
            })
        })
            .then((response) => response.json())
            .then((response) => {
                setInterviewState([{
                    scheduled_interview_count: response.data.scheduled_interview_count,
                    selected_interview_count: response.data.scheduled_interview_count,
                    rejected_interview_count: response.data.scheduled_interview_count,
                    no_show_by_interviewer_count: response.data.scheduled_interview_count,
                    no_show_by_candidate_count: response.data.scheduled_interview_count
                }])
                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false)
            });
    }

    const getSkills = () => {
        fetch(`${API_URL}api/getSkills`)
            .then((response) => response.json())
            .then((response) => {
                var allSkills = [];
                response.data.map((item) => {
                    allSkills.push({
                        name: item.name,
                        value: item.name
                    })
                })
                setSkillsOptions(allSkills)
            })
            .catch((error) => {
                setSkillsOptions([])
            });
    }

    const getAllCity = () => {
        fetch(`${API_URL}api/cities`)
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && !_.isEmpty(response.data) && (response.data.length > 0)) {
                    let cities = [];
                    _.map(response.data, (city, index) => {
                        cities.push({ id: city.city_id, name: city.name });
                    })
                    setCities(cities);
                }
            })
            .catch((err) => {
                setCities("");
            });
    }

    const getInterviewerProfile = () => {
        setIsLoading(true)
        fetch(`${API_URL}api/profileByID/${id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && !_.isEmpty(response.data)) {
                    setInterviewerProfile(response.data);
                    if (!_.isUndefined(response.data.city_id) && !_.isNull(response.data.city_id) && (response.data.city_id !== '')) {
                        getCityName(response.data.city_id);
                    }
                    if (!_.isUndefined(response.data.state_id) && !_.isNull(response.data.state_id) && (response.data.state_id !== '')) {
                        getStateName(response.data.state_id);
                    }
                    if (!_.isUndefined(response.data.country_id) && !_.isNull(response.data.country_id) && (response.data.country_id !== '')) {
                        getCountry(response.data.country_id);
                    }

                    setProfileDetail({
                        email: response.data.email,
                        designation: response.data.designation,
                        profile_image: response.data.profile_image,
                        naukari_profile: response.data.naukari_profile,
                        linkedin: response.data.linkedin,
                        first_name: response.data.first_name,
                        last_name: response.data.last_name,
                        mobile_number: response.data.mobile_number
                    })

                    setBankDetails({
                        bank_user_name: response.data.bank_user_name,
                        bank_name: response.data.bank_name,
                        bank_branch_name: response.data.bank_branch_name,
                        bank_account_number: response.data.bank_account_number,
                        bank_ifsc_code: response.data.bank_ifsc_code,
                        bank_upi_id: response.data.bank_upi_id
                    })
                    var allSelectedPrimarySkills = [];
                    response.data.primary_skill.map((item) => {
                        allSelectedPrimarySkills.push({
                            name: item,
                            value: item
                        })
                    })
                    setSkills(allSelectedPrimarySkills)
                    var allSelectedSecondaySkills = [];
                    response.data.secondary_skill.map((item) => {
                        allSelectedSecondaySkills.push({
                            name: item,
                            value: item
                        })
                    })
                    setSecondarySkills(allSelectedSecondaySkills)
                }
                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false)
                setInterviewerProfile({});
            });
    }

    const getCityName = (cityBYID) => {
        fetch(`${API_URL}api/cities/cityBYID/${cityBYID}`)
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && !_.isEmpty(response.data) && !_.isUndefined(response.data.name) && !_.isNull(response.data.name) && (response.data.name !== '')) {
                    setCity(response.data.name);
                }
            })
            .catch((err) => {
                setCity("");
            });
    }

    const getStateName = (stateBYID) => {
        fetch(`${API_URL}api/states/stateBYID/${stateBYID}`)
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && !_.isEmpty(response.data) && !_.isUndefined(response.data.name) && !_.isNull(response.data.name) && (response.data.name !== '')) {
                    setState(response.data.name);
                }
            })
            .catch((err) => {
                setCity("");
            });
    }

    const [countryOptions, setCountryOptions] = useState([]);

    const getCountryList = () => {
        fetch(`${API_URL}api/countries`)
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && (response.data.length > 0)) {
                    const transformedOptions = response.data.map(option => ({
                        id: option.country_id,
                        name: option.name
                    }));
                    setCountryOptions(transformedOptions);
                }
            })
            .catch((err) => {
                setCountryOptions([]);
            });
    }

    const getCountry = (countryBYID) => {
        fetch(`${API_URL}api/countries/${countryBYID}`)
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && !_.isEmpty(response.data) && !_.isUndefined(response.data.name) && !_.isNull(response.data.name) && (response.data.name !== '')) {
                    setCountry(response.data.name);
                }
            })
            .catch((err) => {
                setCountry({});
            });
    }

    const handleCloseFormErrors = () => {
        setShowFormErrors(false);
        setFormErrors("");
    };

    const handleCloseFormSuccess = () => {
        setShowFormSuccess(false);
        setFormSuccess("");
    };

    const onRemoveLocationPreferences = (selectedList, removedItem) => {
        setLocationPreferences(selectedList);

    }
    const onSelectLocationPreferences = (selectedList, selectedItem) => {
        setLocationPreferences(selectedList);
    }

    const submitLocationPreferences = () => {
        if (locationPreferences.length > 0) {
            let params = {
                location_preferences: locationPreferences
            }
            fetch(`${API_URL}api/updateProfileByID/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((response) => {
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.user) && !_.isNull(response.user) && !_.isEmpty(response.user) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                    }
                    else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                    }
                    setShowLocationPreferences(false);
                    getInterviewerProfile();
                })
                .catch((error) => {
                    if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
                    }
                    setShowLocationPreferences(false)
                });
        }
    }

    const onRemoveLanguages = (selectedList, removedItem) => {
        setLanguages(selectedList);
    }
    const onSelectLanguages = (selectedList, selectedItem) => {
        setLanguages(selectedList);
    }

    const submitLanguages = () => {
        if (languages.length > 0) {
            let params = {
                languages: languages
            }
            fetch(`${API_URL}api/updateProfileByID/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((response) => {
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.user) && !_.isNull(response.user) && !_.isEmpty(response.user) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                    }
                    else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                    }
                })
                .catch((error) => {
                    if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
                    }
                });
        }
    }

    const onRemoveSkills = (selectedList, removedItem) => {
        setSkills(selectedList);
    }
    const onSelectSkills = (selectedList, selectedItem) => {
        setSkills(selectedList);
    }

    const submitSkills = () => {
        if (skills.length > 0) {
            var allPrimarySkills = [];
            skills.map((item) => {
                allPrimarySkills.push(item.value)
            })
            let params = {
                primary_skill: allPrimarySkills
            }
            fetch(`${API_URL}api/updateProfileByID/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((response) => {
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.user) && !_.isNull(response.user) && !_.isEmpty(response.user) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                        setShowSkills(false)
                        getInterviewerProfile();
                    }
                    else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                    }
                })
                .catch((error) => {
                    if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
                    }
                });
        }
    }

    const submitSecondaySkills = () => {
        if (secondarySkills.length > 0) {
            var allSecondarySkills = [];
            secondarySkills.map((item) => {
                allSecondarySkills.push(item.value)
            })
            let params = {
                secondary_skill: allSecondarySkills
            }
            fetch(`${API_URL}api/updateProfileByID/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((response) => {
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.user) && !_.isNull(response.user) && !_.isEmpty(response.user) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                        setShowSecondarySkills(false)
                        getInterviewerProfile();
                    }
                    else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                    }
                })
                .catch((error) => {
                    if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
                    }
                });
        }
    }

    const [isDetailEdit, setIsDetailEdit] = useState(false);

    const changeHandler = (e) => {
        const { name, value } = e.target;
        if (name === "mobile_number") {
            const input = e.target.value;
            const numericInput = input.replace(/\D/g, '');
            const truncatedInput = numericInput.slice(0, 10);
            setProfileDetail({
                ...profileDetail,
                [name]: truncatedInput,
            });
        } else {
            setProfileDetail({
                ...profileDetail,
                [e.target.name]: value,
            });
        }
    }

    const fileUploadRef = useRef(null);
    const [resumeFileName, setResumeFileName] = useState(null);

    const uploadFileHandler = async (event) => {
        if (allowedImageTypes.includes(event.target.files[0].type)) {
            setResumeFileName(event.target.files[0].name)
            setIsLoading(true)
            const file = event.target.files[0];
            const data = new FormData();
            data.append('file', file);
            fetch(`${API_URL}api/uploadFiles`, {
                method: 'POST',
                body: data
            })
                .then((response) => response.json())
                .then((response) => {
                    profileDetail.profile_image = response.filepath;
                    clearFile(fileUploadRef)
                    const event = new CustomEvent('profileImageUpdated', {
                        detail: { profileImage: response.filepath }
                    });
                    window.dispatchEvent(event);

                    updateProfile();
                    setIsLoading(false)
                })
                .catch((err) => {
                    console.log("error", err);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                    clearFile(fileUploadRef)
                    setIsLoading(false)
                });
        } else {
            clearFile(fileUploadRef)
            toast.current.show({ severity: 'error', summary: 'Error', detail: imageFileTypeErrorMessage, life: 3000 });
        }
    };

    const clearFile = (fileInput) => {
        if (fileInput.current) {
            fileInput.current.value = ''; // Reset file input
        }
        setResumeFileName(null)
    }

    const validateForm = () => {
        const emailregex = /^[^\s+@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const numberregex = /[0-9]/;
        if (
            _.isUndefined(profileDetail.first_name) ||
            _.isNull(profileDetail.first_name) ||
            profileDetail.first_name === "" ||
            profileDetail.first_name.trim() === ""
        ) {
            setIsFirstNameError("First Name is required!");
        } else if (
            !_.isUndefined(profileDetail.first_name) &&
            !_.isNull(profileDetail.first_name) &&
            profileDetail.first_name !== "" &&
            profileDetail.first_name.trim() !== "" &&
            hasNumber(profileDetail.first_name)
        ) {
            setIsFirstNameError("First Name only allow alphabets!");
        } else if (
            !_.isUndefined(profileDetail.first_name) &&
            !_.isNull(profileDetail.first_name) &&
            profileDetail.first_name !== "" &&
            profileDetail.first_name.trim() !== "" &&
            (profileDetail.first_name.trim().length < 4 || profileDetail.first_name.trim().length > 50)
        ) {
            setIsFirstNameError("First Name must be between 4 to 11 characters!");
        } else {
            setIsFirstNameError("");
        }
        if (
            _.isUndefined(profileDetail.last_name) ||
            _.isNull(profileDetail.last_name) ||
            profileDetail.last_name === "" ||
            profileDetail.last_name.trim() === ""
        ) {
            setIsLastNameError("Last Name is required!");
        } else if (
            !_.isUndefined(profileDetail.last_name) &&
            !_.isNull(profileDetail.last_name) &&
            profileDetail.last_name !== "" &&
            profileDetail.last_name.trim() !== "" &&
            hasNumber(profileDetail.last_name)
        ) {
            setIsLastNameError("Last Name only allow alphabets!");
        } else if (
            !_.isUndefined(profileDetail.last_name) &&
            !_.isNull(profileDetail.last_name) &&
            profileDetail.last_name !== "" &&
            profileDetail.last_name.trim() !== "" &&
            (profileDetail.last_name.trim().length < 4 || profileDetail.last_name.trim().length > 50)
        ) {
            setIsLastNameError("Last Name must be between 4 to 11 characters!");
        } else {
            setIsLastNameError("");
        }
        if (
            _.isUndefined(profileDetail.email) ||
            _.isNull(profileDetail.email) ||
            profileDetail.email === "" ||
            profileDetail.email.trim() === ""
        ) {
            setIsEmailError("Email Address is required!");
        } else if (
            !_.isUndefined(profileDetail.email) &&
            !_.isNull(profileDetail.email) &&
            profileDetail.email !== "" &&
            profileDetail.email.trim() !== "" &&
            !emailregex.test(profileDetail.email)
        ) {
            setIsEmailError("Please enter a valid email address!");
        } else if (
            !_.isUndefined(profileDetail.email) &&
            !_.isNull(profileDetail.email) &&
            profileDetail.email !== "" &&
            profileDetail.email.trim() !== "" &&
            (profileDetail.email.trim().length < 15 || profileDetail.email.trim().length > 150)
        ) {
            setIsEmailError("Email address must be between 15 to 150 characters!");
        } else {
            setIsEmailError("");
        }
        if (
            _.isUndefined(profileDetail.mobile_number) ||
            _.isNull(profileDetail.mobile_number) ||
            profileDetail.mobile_number === ""
        ) {
            setIsMobileNumberError("Mobile Number is required!");
        } else if (
            !_.isUndefined(profileDetail.mobile_number) &&
            !_.isNull(profileDetail.mobile_number) &&
            profileDetail.mobile_number !== "" &&
            !numberregex.test(profileDetail.mobile_number)
        ) {
            setIsMobileNumberError("Mobile Number is only allow digit!");
        } else if (
            !_.isUndefined(profileDetail.mobile_number) &&
            !_.isNull(profileDetail.mobile_number) &&
            profileDetail.mobile_number !== "" &&
            (profileDetail.mobile_number.length < 10 ||
                profileDetail.mobile_number.length > 10)
        ) {
            setIsMobileNumberError("Mobile Number must be 10 digit!");
        } else {
            setIsMobileNumberError("");
        }
        if (
            _.isUndefined(profileDetail.designation) ||
            _.isNull(profileDetail.designation) ||
            profileDetail.designation === "" ||
            profileDetail.designation.trim() === ""
        ) {
            setIsDesignationError("Designation is required!");
        } else {
            setIsDesignationError("");
        }
    }

    const checkedValidateForm = () => {
        const emailregex = /^[^\s+@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const numberregex = /[0-9]/;
        if (
            _.isUndefined(profileDetail.first_name) ||
            _.isNull(profileDetail.first_name) ||
            profileDetail.first_name === "" ||
            profileDetail.first_name.trim() === ""
        ) {
            return false;
        } else if (
            !_.isUndefined(profileDetail.first_name) &&
            !_.isNull(profileDetail.first_name) &&
            profileDetail.first_name !== "" &&
            profileDetail.first_name.trim() !== "" &&
            hasNumber(profileDetail.first_name)
        ) {
            return false;
        } else if (
            !_.isUndefined(profileDetail.first_name) &&
            !_.isNull(profileDetail.first_name) &&
            profileDetail.first_name !== "" &&
            profileDetail.first_name.trim() !== "" &&
            (profileDetail.first_name.trim().length < 4 || profileDetail.first_name.trim().length > 50)
        ) {
            return false;
        }
        if (
            _.isUndefined(profileDetail.last_name) ||
            _.isNull(profileDetail.last_name) ||
            profileDetail.last_name === "" ||
            profileDetail.last_name.trim() === ""
        ) {
            return false;
        } else if (
            !_.isUndefined(profileDetail.last_name) &&
            !_.isNull(profileDetail.last_name) &&
            profileDetail.last_name !== "" &&
            profileDetail.last_name.trim() !== "" &&
            hasNumber(profileDetail.last_name)
        ) {
            return false;
        } else if (
            !_.isUndefined(profileDetail.last_name) &&
            !_.isNull(profileDetail.last_name) &&
            profileDetail.last_name !== "" &&
            profileDetail.last_name.trim() !== "" &&
            (profileDetail.last_name.trim().length < 4 || profileDetail.last_name.trim().length > 50)
        ) {
            return false;
        }
        if (
            _.isUndefined(profileDetail.email) ||
            _.isNull(profileDetail.email) ||
            profileDetail.email === "" ||
            profileDetail.email.trim() === ""
        ) {
            return false;
        } else if (
            !_.isUndefined(profileDetail.email) &&
            !_.isNull(profileDetail.email) &&
            profileDetail.email !== "" &&
            profileDetail.email.trim() !== "" &&
            !emailregex.test(profileDetail.email)
        ) {
            return false;
        } else if (
            !_.isUndefined(profileDetail.email) &&
            !_.isNull(profileDetail.email) &&
            profileDetail.email !== "" &&
            profileDetail.email.trim() !== "" &&
            (profileDetail.email.trim().length < 15 || profileDetail.email.trim().length > 150)
        ) {
            return false;
        }
        if (
            _.isUndefined(profileDetail.mobile_number) ||
            _.isNull(profileDetail.mobile_number) ||
            profileDetail.mobile_number === ""
        ) {
            return false;
        } else if (
            !_.isUndefined(profileDetail.mobile_number) &&
            !_.isNull(profileDetail.mobile_number) &&
            profileDetail.mobile_number !== "" &&
            !numberregex.test(profileDetail.mobile_number)
        ) {
            return false;
        } else if (
            !_.isUndefined(profileDetail.mobile_number) &&
            !_.isNull(profileDetail.mobile_number) &&
            profileDetail.mobile_number !== "" &&
            (profileDetail.mobile_number.length < 10 ||
                profileDetail.mobile_number.length > 10)
        ) {
            return false;
        }
        if (
            _.isUndefined(profileDetail.designation) ||
            _.isNull(profileDetail.designation) ||
            profileDetail.designation === "" ||
            profileDetail.designation.trim() === ""
        ) {
            return false;
        }
        return true;
    }

    const updateProfile = () => {
        validateForm();
        if (checkedValidateForm()) {
            setIsLoading(true)
            fetch(`${API_URL}api/updateProfileByID/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(profileDetail)
            })
                .then((response) => response.json())
                .then((response) => {
                    setIsLoading(false)
                    setIsDetailEdit(false);
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        const event = new CustomEvent('profileUserUpdated', {
                            detail: { user: response.user }
                        });
                        window.dispatchEvent(event);
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                    }
                    else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                    }

                    getInterviewerProfile();
                })
                .catch((error) => {
                    setIsLoading(false)
                    setIsDetailEdit(false);
                });
        }

    }

    const selectChangeHandler = (e, name) => {
        const { value } = e;
        setProfileDetail({
            ...profileDetail,
            [name]: value,
        });
    }

    const getRalaSubAdminCompanyList = (ralaSubAdminId) => {
        fetch(`${API_URL}api/getSubAdminUsers/${ralaSubAdminId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userDetails.token}`,
            }
        })
            .then((response) => response.json())
            .then((response) => {
                console.log("response", response)
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && !_.isEmpty(response.data) && (response.data.length > 0)) {
                    setCompanyList(response.data)
                } else {
                    setCompanyList([])
                }
            })
            .catch((err) => {
                setCompanyList([]);
            });
    }

    return (
        <Fragment>
            <Toast ref={toast} />
            {
                isLoading &&
                <div className="spinner-loader">
                    <Loader type="spinner-circle" bgColor="#2F2F74" color="#2F2F74" size={100} />
                </div>
            }
            <div className="container-fluid employer-dashboard-container fixed-padding">
                <div className="row">
                    <div className="col-12 px-0">
                        <HeaderScreen />
                    </div>
                </div>
                <div className="row mx-5">
                    <div className="col-12 employer-dashboard-title ">
                        <div className="row mb-3">
                            <div className="col-12 pageTitle_fixed">
                                <p className="employer-dashboard-jobs-posted">Settings / Interviewer / <span className='text-primary'>{interviewerProfile.first_name + " " + interviewerProfile.last_name}</span></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-12 mb-2 interviewer-profile-details-left">
                                <Card>
                                    <Card.Body>
                                        <div className="row mb-3">
                                            <div className="col-12 text-center">
                                                <img className="profile-image" src={!_.isUndefined(profileDetail.profile_image) && !_.isNull(profileDetail.profile_image) && (profileDetail.profile_image !== '') ? profileDetail.profile_image : default_profile_picture} alt="Profile" />
                                                {
                                                    userDetails && userDetails.id === interviewerProfile.id && (

                                                        <>
                                                            <input style={{ display: 'none' }} type="file" id="upload_profile" name="upload_profile" onChange={uploadFileHandler} placeholder="Profile Photo" multiple ref={fileUploadRef} accept="image/*" />
                                                            <div className="file-upload-container mt-2" onClick={() => fileUploadRef.current.click()}>
                                                                <div className="custom-file-upload">
                                                                    Choose file
                                                                </div>
                                                                <div className="file-name">{resumeFileName ?? "No file chosen"}</div>
                                                            </div>
                                                        </>

                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="row my-6">
                                            <div className="col-12">
                                                <b>PROFILE</b>
                                            </div>
                                        </div>
                                        <div className="row">

                                            <div className="col-12">
                                                {
                                                    userDetails && userDetails.account_type === SUPER_ADMIN && (
                                                        isDetailEdit ? (
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>First name*</Form.Label>
                                                                <Form.Control
                                                                    value={profileDetail.first_name}
                                                                    type="text"
                                                                    id="first_name"
                                                                    name="first_name"
                                                                    placeholder="First name"
                                                                    onChange={changeHandler}
                                                                    maxLength={150}
                                                                    autoComplete="off"
                                                                />
                                                                {!_.isUndefined(firstNameError) &&
                                                                    !_.isNull(firstNameError) &&
                                                                    firstNameError !== "" && (
                                                                        <Alert variant="danger" className="mt-2">
                                                                            {firstNameError}
                                                                        </Alert>
                                                                    )}
                                                            </Form.Group>
                                                        ) : (
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>First name*</Form.Label>
                                                                <p className="interviewer-profile-details-value">{profileDetail.first_name}</p>
                                                            </Form.Group>
                                                        )
                                                    )
                                                }
                                                {
                                                    userDetails && userDetails.account_type === SUPER_ADMIN && (
                                                        isDetailEdit ? (
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Last name*</Form.Label>
                                                                <Form.Control
                                                                    value={profileDetail.last_name}
                                                                    type="text"
                                                                    id="last_name"
                                                                    name="last_name"
                                                                    placeholder="Last name"
                                                                    onChange={changeHandler}
                                                                    maxLength={150}
                                                                    autoComplete="off"
                                                                />
                                                                {!_.isUndefined(lastNameError) &&
                                                                    !_.isNull(lastNameError) &&
                                                                    lastNameError !== "" && (
                                                                        <Alert variant="danger" className="mt-2">
                                                                            {lastNameError}
                                                                        </Alert>
                                                                    )}
                                                            </Form.Group>
                                                        ) : (
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Last name*</Form.Label>
                                                                <p className="interviewer-profile-details-value">{profileDetail.last_name}</p>
                                                            </Form.Group>
                                                        )
                                                    )
                                                }
                                            </div>

                                            <div className="col-12">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Email*</Form.Label>
                                                    {
                                                        isDetailEdit && userDetails.account_type === SUPER_ADMIN ?
                                                            (
                                                                <>
                                                                    <Form.Control
                                                                        value={profileDetail.email}
                                                                        type="text"
                                                                        id="email"
                                                                        name="email"
                                                                        placeholder="Email"
                                                                        onChange={changeHandler}
                                                                        maxLength={150}
                                                                        autoComplete="off"
                                                                    />
                                                                    {!_.isUndefined(emailError) &&
                                                                        !_.isNull(emailError) &&
                                                                        emailError !== "" && (
                                                                            <Alert variant="danger" className="mt-2">
                                                                                {emailError}
                                                                            </Alert>
                                                                        )}
                                                                </>
                                                            ) : (
                                                                <p className="interviewer-profile-details-value">{profileDetail.email}</p>
                                                            )
                                                    }

                                                </Form.Group>
                                            </div>
                                            <div className="col-12">
                                                <label className="interviewer-profile-details-label">Registration ID</label>
                                                <p className="interviewer-profile-details-value">{interviewerProfile.id}</p>
                                            </div>
                                            <div className="col-12">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Mobie Number*</Form.Label>
                                                    <p className="interviewer-profile-details-value">
                                                        {
                                                            (isDetailEdit && userDetails.account_type === SUPER_ADMIN) ?
                                                                (
                                                                    <>
                                                                        <Form.Control
                                                                            value={profileDetail.mobile_number}
                                                                            type="text"
                                                                            id="mobile_number"
                                                                            name="mobile_number"
                                                                            placeholder="Mobile Number"
                                                                            onChange={changeHandler}
                                                                            maxLength={10}
                                                                            autoComplete="off"
                                                                        />
                                                                        {!_.isUndefined(mobileNumberError) &&
                                                                            !_.isNull(mobileNumberError) &&
                                                                            mobileNumberError !== "" && (
                                                                                <Alert variant="danger" className="mt-2">
                                                                                    {mobileNumberError}
                                                                                </Alert>
                                                                            )}
                                                                    </>
                                                                ) : (
                                                                    <p className="interviewer-profile-details-value">{profileDetail.mobile_number}</p>
                                                                )
                                                        }
                                                    </p>
                                                </Form.Group>
                                            </div>
                                            <div className="col-12">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Designation*</Form.Label>
                                                    {
                                                        isDetailEdit && userDetails.id === interviewerProfile.id ?
                                                            (
                                                                userDetails.account_type === SUPER_ADMIN ? (
                                                                    <>
                                                                        <Form.Control
                                                                            value={profileDetail.designation}
                                                                            type="text"
                                                                            id="designation"
                                                                            name="designation"
                                                                            placeholder="Enter Designation"
                                                                            onChange={changeHandler}
                                                                            autoComplete="off"
                                                                        />
                                                                        {!_.isUndefined(designationError) &&
                                                                            !_.isNull(designationError) &&
                                                                            designationError !== "" && (
                                                                                <Alert variant="danger" className="mt-2">
                                                                                    {designationError}
                                                                                </Alert>
                                                                            )}
                                                                    </>
                                                                ) : (
                                                                    <ReactTags
                                                                        labelText="Select designation"
                                                                        selected={profileDetail.designation === "" || profileDetail.designation === null ? [] : [{ name: profileDetail.designation, value: profileDetail.designation, label: profileDetail.designation }]}
                                                                        suggestions={designationOptions}
                                                                        allowNew={true}
                                                                        onAdd={(selectedList) => {
                                                                            setProfileDetail({
                                                                                ...profileDetail,
                                                                                designation: selectedList.value
                                                                            });
                                                                        }}
                                                                        onDelete={(selectedList) => {
                                                                            setProfileDetail({
                                                                                ...profileDetail,
                                                                                designation: ""
                                                                            });
                                                                        }}
                                                                        placeholderText="Designation"
                                                                        noOptionsText="No Matching designation"
                                                                    />
                                                                )
                                                            ) : (
                                                                <p className="interviewer-profile-details-value">{profileDetail.designation}</p>
                                                            )
                                                    }
                                                </Form.Group>
                                            </div>
                                            <div className="col-12">
                                                <label className="interviewer-profile-details-label">Sign Up Date</label>
                                                <p className="interviewer-profile-details-value">{commonDateFormat(interviewerProfile.createdAt)}</p>
                                            </div>

                                            <div className="col-12">
                                                {
                                                    userDetails && (userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN) && (
                                                        isDetailEdit ? (
                                                            <a href="javascript:void(0);" onClick={() => updateProfile()} className="btn btn-primary">Save</a>
                                                        ) : (
                                                            <a href="javascript:void(0);" onClick={() => setIsDetailEdit(true)} className="btn btn-outline-primary">Edit</a>
                                                        )
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="col-md-9 col-12 interviewer-profile-details-right">
                                <Card>
                                    <Card.Body>
                                        <div className="row px-5">
                                            <div className="col-12">
                                                <b className="interviewer-profile-details-right-name">{interviewerProfile.first_name + " " + interviewerProfile.last_name} <span className="interviewer-profile-details-right-location"><IoLocationOutline size={16} />{city + ", " + interviewerState + ", " + interviewerCountry}</span></b>
                                            </div>
                                            <div className="col-12">
                                                <p className="interviewer-profile-details-right-designation">{interviewerProfile.designation}</p>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12 px-5">
                                                <hr />
                                            </div>
                                        </div>

                                        {
                                            interviewerProfile && interviewerProfile.account_type === SUB_ADMIN && (
                                                <div className="row">
                                                    <div className="col-12 px-5">
                                                        <Table bordered striped hover size="lg" responsive className="request-interviewer-users-feednback-completed">
                                                            <thead className='sticky-top'>
                                                                <tr>
                                                                    <th className="text-start">Name</th>
                                                                    <th className="text-start">Email</th>
                                                                    <th className="text-start">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    !_.isUndefined(companyList) && !_.isNull(companyList) &&
                                                                    _.map(companyList, (company, index) => {
                                                                        return <tr key={"feednback-completed-company-" + index}>
                                                                            <td className="text-start">{company.company_name}</td>
                                                                            <td className="text-start">{company.email}</td>
                                                                            <td className="text-start"></td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            interviewerProfile && interviewerProfile.account_type === SUPER_ADMIN && (
                                                <div className="row">
                                                    <div className="px-5" style={{ textAlign: 'right', marginBottom: '10px' }}>
                                                        <a style={{ float: 'right' }} href="javascript:void(0);" className="btn btn-primary" onClick={() => setShowTestimonialDialog(true)} > Add Testimonial </a>
                                                    </div>
                                                    <div className="col-12 px-5">
                                                        <Table bordered striped hover size="lg" responsive className="request-interviewer-users-feednback-completed">
                                                            <thead className='sticky-top'>
                                                                <tr>
                                                                    <th className="text-start">Author</th>
                                                                    <th className="text-start">Designation</th>
                                                                    <th className="text-start">Message</th>
                                                                    <th className={"w-25"}> Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    !_.isUndefined(testimonials) && !_.isNull(testimonials) &&
                                                                    _.map(testimonials, (testimonial, index) => {
                                                                        return <tr key={"feednback-completed-company-" + index}>
                                                                            <td className="text-start">{testimonial.author}</td>
                                                                            <td className="text-start">{testimonial.designation}</td>
                                                                            <td className="text-start">{testimonial.message}</td>
                                                                            <td className="text-center">
                                                                                <Button variant="outline-primary" onClick={() => {
                                                                                    editTestimonial(testimonial);
                                                                                }}>
                                                                                    Edit
                                                                                </Button> &nbsp;
                                                                                <Button variant="outline-primary" onClick={() => {
                                                                                    handleTestimonialConfimationDialogOpen(testimonial._id);
                                                                                }}>
                                                                                    Remove
                                                                                </Button>
                                                                            </td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {
                formErrors && showFormErrors &&
                <Modal show={showFormErrors} onHide={handleCloseFormErrors} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{formErrors}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleCloseFormErrors}>
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
            {
                formSuccess && showFormSuccess &&
                <Modal show={showFormSuccess} onHide={handleCloseFormSuccess} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Success</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{formSuccess}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleCloseFormSuccess}>
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>
            }

            <Modal size="lg" show={showAddTestimonialDialog} onHide={handleTestimonialDialogClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Testimonial</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleTestimonialSubmit}>
                        <Form.Group controlId="formAuthor">
                            <Form.Label>Author</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter author name"
                                name="author"
                                value={testimonialFormData.author}
                                onChange={handleTestimonialChange}
                            />
                            {
                                !_.isUndefined(authorNameError) && !_.isNull(authorNameError) && (authorNameError !== '') &&
                                <Alert variant="danger" className="mt-2">{authorNameError}</Alert>
                            }
                        </Form.Group>
                        <Form.Group controlId="formDesignation" className='mt-2'>
                            <Form.Label>Designation</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter author's designation"
                                name="designation"
                                value={testimonialFormData.designation}
                                onChange={handleTestimonialChange}
                            />
                            {
                                !_.isUndefined(testimonialDesignationError) && !_.isNull(testimonialDesignationError) && (testimonialDesignationError !== '') &&
                                <Alert variant="danger" className="mt-2">{testimonialDesignationError}</Alert>
                            }
                        </Form.Group>
                        <Form.Group controlId="formMessage" className='mt-2'>
                            <Form.Label>Message</Form.Label>
                            <Form.Control
                                as="textarea"
                                type="textarea"
                                rows="4"
                                placeholder="Enter testimonial message"
                                name="message"
                                value={testimonialFormData.message}
                                onChange={handleTestimonialChange}
                            />
                            {
                                !_.isUndefined(testimonialMessageError) && !_.isNull(testimonialMessageError) && (testimonialMessageError !== '') &&
                                <Alert variant="danger" className="mt-2">{testimonialMessageError}</Alert>
                            }
                        </Form.Group>
                        <Button variant="primary" type="submit" className="mt-3">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={removeTestimonialConfirmDialogShow} onHide={handleTestimonialConfimationDialogClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Remove this testimonial?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to remove this testimonial? This action cannot be undone.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => removeTestimonial()}>Yes</Button>
                    <Button variant="outline-primary" onClick={() => handleTestimonialConfimationDialogClose()}>Cancel</Button>
                </Modal.Footer>
            </Modal>

        </Fragment >
    )
}
export default AdminProfileScreen;